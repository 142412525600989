import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import { fileToUrl } from '../utils';
import { MOBILE_WIDTH } from '../config';

const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoadingContainer = styled.div`
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const betboardLogo = fileToUrl('betboard_logo.png');

const LogoContainer = styled.div``;

const Logo = styled.img`
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
`;

const TextContainer = styled.div`

    text-align: center;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 400px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-left: 10px;
        margin-right: 10px;
    }
`

const AppLoading = () => {
    return (
        <PageContainer>
            <LoadingContainer>
                <LogoContainer>
                    <Logo src={betboardLogo} alt={'betboard logo'}/>
                </LogoContainer>
                <h1>BetBoard</h1>
                <TextContainer>Our BetBoard pilot has concluded. We cannot say Thank You enough for your invaluable support so far. Stay tuned for the official launch of the BetBoard app on iOS and Android coming this summer!</TextContainer>
            </LoadingContainer>
        </PageContainer>
    );
}

export default AppLoading;