import React from "react";
import styled from 'styled-components';
import BookLink from "../BookLink";
import { secondaryColor } from '../../styles/Styles';
import { roundOdds, golfScore } from '../../utils';

const BetButton = styled.button`
    border: none;
    padding: 10px;
    padding-right: 12px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const GolfPlayerThenNow = ({golfPlayerThenNow,event_id,event_name,event_status}) => {

    const {player_last_name,then_price,then_score,then_round_score,then_thru,then_current_position} = golfPlayerThenNow.then_odds;
    const {price,score,round_score,thru,current_position} = golfPlayerThenNow.now_odds;

    const [showBookLink,setShowBookLink] = React.useState(false);
    const [betSelection,setBetSelection] = React.useState(null);

    const handleBetSelection = (event,choice) => {
        event.preventDefault();
        event.stopPropagation();
        setBetSelection(choice);
        openModal(event);
    }

    function openModal(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowBookLink(true);
    }

    function closeModal(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowBookLink(false);
        setBetSelection(null);
    }

    return(
        <div style={{display:'flex'}}>
            <div style={{flex:'1'}}>
                <div style={{width:'100%',fontSize:'1.1em',fontWeight:'bold'}}>
                    {event_name}
                </div>
                <div style={{display:'flex',paddingTop:'5px',paddingBottom:'5px',fontSize:'0.9em'}}>
                    <div style={{flex:'1'}}></div>
                    <div style={{flex:'1',textAlign:'center'}}>
                        THEN
                    </div>
                    <div style={{flex:'1',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        NOW
                    </div>
                </div>
                <div style={{display:'flex',fontSize:'0.9em'}}>
                    <div style={{flex:'1',display:'flex',alignItems:'center'}}>
                        {player_last_name}
                    </div>
                    <div style={{flex:'1',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{width:'20px',textAlign:'center'}}>{(!then_current_position || then_current_position === 'WAITING') ? '-' : then_current_position}</div>
                        <div style={{width:'20px',textAlign:'center'}}>{(!then_current_position || then_current_position === 'WAITING') ? '-' : golfScore(then_score)}</div>
                    </div>
                    <div style={{flex:'1',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{width:'20px',textAlign:'center'}}>{(!current_position || current_position === 'WAITING') ? '-' : current_position}</div>
                        <div style={{width:'20px',textAlign:'center'}}>{(!current_position || current_position === 'WAITING') ? '-' : golfScore(score)}</div>
                    </div>
                </div>
            </div>
            <div style={{width:'50px',display: 'flex',alignItems:'center',justifyContent:'center'}}>
                {event_status !== 'complete' && (
                    <BetButton onClick={(event) => openModal(event)}>
                        BET
                    </BetButton>
                )}
                {event_status == 'complete' && (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'16px',color:secondaryColor,fontWeight:'bold',fontStyle:'italic'}}>
                        Final
                    </div>
                )}
                {showBookLink && event_status !== 'complete' && (
                    <BookLink closeModal={closeModal} betSelection={betSelection} setBetSelection={setBetSelection} handleBetSelection={handleBetSelection} eventId={event_id}/>  
                )}
            </div>
        </div>
    );
}

export default GolfPlayerThenNow;