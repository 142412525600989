import React from 'react';
import styled from 'styled-components';
import { borderColor, secondaryColor } from '../../styles/Styles';

const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
`

const OptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 40px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    color: ${props => props.$IsSelected ? secondaryColor : 'black'};
    font-weight: ${props => props.$IsSelected ? 'bold' : 'normal'};
`

const DropShadow = styled.div`
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:6px;
    border-radius:3px;
    background-color:${secondaryColor};
    display: ${props => props.$IsSelected ? 'block' : 'none'};
`


const FilterOption = ({optionAbbreviation,setOption,isSelected}) => {

    function handleClick(event) {
        event.preventDefault();
        event.stopPropagation();
        setOption(optionAbbreviation);
    }

    return(
        <OptionContainer onClick={handleClick} $IsSelected={isSelected}>
            {optionAbbreviation}
            <DropShadow $IsSelected={isSelected}></DropShadow>
        </OptionContainer>
    )
}

const LeagueFilter = ({league,setLeague,leagues}) => {

    return(
        <FilterContainer>
            <FilterOption optionAbbreviation={leagues.MLB} setOption={setLeague} isSelected={league === leagues.MLB} />
            <FilterOption optionAbbreviation={leagues.NBA} setOption={setLeague} isSelected={league === leagues.NBA} />
            <FilterOption optionAbbreviation={leagues.PGA} setOption={setLeague} isSelected={league === leagues.PGA} />
            <FilterOption optionAbbreviation={leagues.ALL} setOption={setLeague} isSelected={league === null || league === leagues.ALL} />
        </FilterContainer>
    )
}

const DateFilterContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
`

const DateFilterOption = styled.div`
    margin-right: 6px;
    margin-left: 6px;
    padding: 7px 12px 7px 12px;
    border: 1px solid ${props => props.$IsChosen ? secondaryColor : borderColor};
    border-radius: 999px;
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.$IsChosen ? 'white' : 'black'};
    background-color: ${props => props.$IsChosen ? secondaryColor : 'white'};
    // width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
`

const Select = styled.select`
    margin-right: 6px;
    margin-left: 6px;
    padding: 7px 12px 7px 12px;
    border: 1px solid ${props => props.$IsChosen ? secondaryColor : borderColor};
    border-radius: 999px;
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.$IsChosen ? 'white' : 'black'};
    background-color: ${props => props.$IsChosen ? secondaryColor : 'white'};
`

const DateFilter = ({filterChoice,setFilterChoice,filterOptions}) => {

    // function handleClick(event,choice) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setFilterChoice(choice);
    // }

    return(

        <FilterContainer>
            <FilterOption optionAbbreviation={"Week"} setOption={setFilterChoice} isSelected={filterChoice === filterOptions.WEEK} />
            <FilterOption optionAbbreviation={"Month"} setOption={setFilterChoice} isSelected={filterChoice === filterOptions.MONTH} />
            <FilterOption optionAbbreviation={"All"} setOption={setFilterChoice} isSelected={filterChoice === filterOptions.ALL} />
        </FilterContainer>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`

const Filters = ({league,setLeague,filterChoice,setFilterChoice,filterOptions,leagues}) => {
    return(
        <Container>
            <>
            <LeagueFilter  league={league} setLeague={setLeague} leagues={leagues} />
            <div style={{width:'75%',height:'1px',borderBottom:`1px solid ${borderColor}`,marginTop:'5px',marginBottom:'5px'}}></div>
            <DateFilter 
                filterOptions={filterOptions}
                filterChoice={filterChoice}
                setFilterChoice={setFilterChoice}
                />
            </>
        </Container>
    );
}

export default Filters;