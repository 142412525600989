import styled from 'styled-components';
import { borderColor, primaryColor, secondaryColor } from '../styles/Styles';
import { BiArrowBack } from "react-icons/bi";
import { GrClose } from "react-icons/gr"

const Button = styled.button`
    width: 200px;
    height: 50px;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1em;
    font-family: 'IBM Plex Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        background-color: ${secondaryColor};
    }
        
`

const WhiteButton = styled(Button)`
    background-color: white;
`

const PrimaryButton = styled(Button)`
    background-color: ${secondaryColor};
    color: white;
`

const SmallButton = styled.button`
    width: 90px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 9999px;
    background-color: ${primaryColor};
    color: white;
    font-weight: bold;
`

const BackButtonContainer = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
    border-radius: 25px;
    font-size: 1.5em;
    background-color: ${props => props.backgroundColor || ''};
    color: ${props => props.$arrowColor || 'black'};

    &:hover {
        background-color: ${borderColor};
    }


`

const BackButton = ({onClick,backgroundColor,arrowColor}) => {
    return(
        <BackButtonContainer 
            onClick={onClick}
            backgroundColor={backgroundColor}
            $arrowColor={arrowColor}
            >
            <BiArrowBack />
        </BackButtonContainer>
    );
}

const CloseButton = ({onClick,color}) => {
    return(
        <BackButtonContainer onClick={onClick} style={{color:color ? color : 'white'}}><GrClose /></BackButtonContainer>
    );
}



export {Button, PrimaryButton, WhiteButton, SmallButton, BackButton,CloseButton};