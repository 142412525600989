import React from "react";
import styled from "styled-components";
import { UserService } from "../../api";
import { Input } from "../Inputs";
import { PrimaryButton, WhiteButton } from "../Buttons";

const USERNAME_LENGH_LIMIT = 25;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 0.9em;
`

const Signup = ({display, setDisplayState, authState,setAuthenticationState, setUsername}) => {

    const [postData,setPostData] = React.useState({
        fullName:'',
        username:'',
        phone:'',
        email:'',
        password:'',
    });

    const [errorMessage,setErrorMessage] = React.useState();

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "username") {
            if (value.length <= USERNAME_LENGH_LIMIT && !/\s/.test(value)) {
                setPostData({
                    ...postData
                    ,[name]: value
                });
            }
        } else {
            setPostData({
                ...postData
                ,[name]: value
            });
        }
        
    }

    const handleBack = () => {
        setDisplayState(display.CHOICE);
    }

    

    const handleSubmit = React.useCallback( async (event) => {
        
        event.preventDefault();
        
        try {
            
            const {data,errorMsg} = await UserService.signupUser(postData,setAuthenticationState,authState);
            
            if (errorMsg) {
                setErrorMessage(errorMsg);
            } else {
                setUsername(data.username);
                setAuthenticationState(authState.AUTHENTICATED);
                
            }

        } catch (error) {
            console.log("Unexpected error",error);
        }
    },[postData,setAuthenticationState,authState, setUsername]);

    return(
        <Form onSubmit={handleSubmit}> 
            <Input 
                data-testid = "input-fullname"
                type = "text"
                name = "fullName"
                value = {postData.fullName}
                onChange = {handleInputChange}
                placeholder="Name"
                required
            />
            <Input 
                data-testid = "input-username"
                type = "text"
                name = "username"
                value = {postData.username}
                onChange = {handleInputChange}
                placeholder="username"
                required
            />
            <Input 
                data-testid = "input-email"
                type = "email"
                name = "email"
                value = {postData.email}
                onChange = {handleInputChange}
                placeholder="email"
                // onBlur={}
            />
            <Input
                data-testid = "input-password"
                type="password"
                name = "password"
                value = {postData.password}
                onChange={handleInputChange}
                placeholder='password'
            />
            <ButtonContainer>
                <PrimaryButton data-testid = 'login-signup-submit' type='submit'>Submit</PrimaryButton>
                <WhiteButton onClick={handleBack}>Back</WhiteButton>
            </ButtonContainer>
            {errorMessage && (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}
        </Form>
    );
}

export default Signup;