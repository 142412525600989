import React from 'react';

import { NewPost, Feed, Layout, AuthenticationContext } from '../components';



const ForYouFollowing = () => {
    return(
        <div style={{display:'flex',paddingLeft:'10px'}}>
            <div style={{marginRight:'10px'}}>For You</div>
            <div>Following</div>
        </div>
    )
}


const Home = () => {

    const [feed,setFeed] = React.useState([]);

    return(
        <Layout hideBack={true}>
            {/* <ForYouFollowing /> */}
            <NewPost setFeed={setFeed} />
            <Feed feed={feed} setFeed={setFeed} />
        </Layout>
    );
}

export default Home;
