import React from 'react';
import styled from 'styled-components';
import {primaryColor,borderColor,lightGray} from '../styles/Styles';
import { fileToUrl } from '../utils';
import { CloseButton } from './Buttons';

const AvatarContainer = styled.div`
    width: ${props => props.$FullScreen ? '100%' : props.$imageSize || "50px"};
    height: ${props => props.$FullScreen ? '100%' : props.$imageSize || "50px"};
    border-radius: ${props => props.$FullScreen ? '0' : '999px'};
    overflow: hidden;
    background-size: ${props => props.$IsTeamLogo ? 'white' : primaryColor};
    margin: ${props => props.$margin || "0px 0px 0px 0px"};
    cursor: pointer;
    border: ${props => props.$FullScreen ? 'none' : props.$IsTeamLogo ? `1px solid ${borderColor}` : 'none'};
    box-sizing: border-box;
    background-color: ${props => props.$FullScreen ? 'black' : lightGray};
    position: ${props => props.$FullScreen ? 'fixed' : 'relative'};
    top: ${props => props.$FullScreen ? '0' : 'auto'};
    left: ${props => props.$FullScreen ? '0' : 'auto'};
    z-index: ${props => props.$FullScreen ? '100' : '0'};
`

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: ${props => props.$FitChoice};
    object-position: center center;
`

const betboard_logo = fileToUrl('betboard_logo.png');



const Avatar = ({onClick,imageSource,imageSize,margin,isTeamLogo,fullScreen,closeFullScreen}) => {
    
    const [imageUrl,setImageUrl] = React.useState(imageSource ? imageSource : betboard_logo);
    const fitChoice = (fullScreen || isTeamLogo) ? 'contain' : 'cover';

    React.useEffect(() => {
        if (imageSource === undefined) {
            setImageUrl(betboard_logo);
        } else {
            setImageUrl(imageSource);
        }
    },[imageSource]);

    const handleError = (event) => {
        setImageUrl(betboard_logo);
    }

    
    
    return(
        <>
        <AvatarContainer $FullScreen={fullScreen} $imageSize={imageSize} $margin={margin} onClick={onClick} $IsTeamLogo={isTeamLogo}>
            <Img 
                src = {imageUrl} 
                onError={handleError}
                alt="user-avatar"
                $FitChoice={fitChoice}
                />
        </AvatarContainer>
        {fullScreen && (
            <div style={{position:'fixed',top:'0px',left:'0px',width: '50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',zIndex:'101'}}>
                <CloseButton onClick={closeFullScreen}/>
            </div>
        )}
        </>
    );
}

export default Avatar;