import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Avatar, EventItem, AuthenticationContext, NewPost, Feed, BookLink } from '../components';
import styled from 'styled-components';
import { EventService } from '../api';
import { fileToUrl, checkDate, roundScore, roundOdds } from '../utils';
import { Loader, borderColor, lightGray, secondaryColor } from '../styles/Styles';
import { MOBILE_WIDTH } from '../config';
import SlateSwipe from '../components/SlateSwipe';
import { SlateSwipeIcon } from '../assets';


const EventHeaderContainer = styled.div`
    // padding-bottom: 10px;
    border-bottom: 1px solid ${borderColor};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const EventSummaryContainer = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 10px;
    width: 100%;
`

const TeamContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
`

const EventCenterContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
    flex-direction: column;
    text-align: center;
`

const ScoreContainer = styled.div`
    margin-top: 50px;
    font-size: 1.5em;
    font-weight: bold;
`

const TeamLogoContainer = styled.div`
    flex: flex-grow;
`

const TeamName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
`

const BetButton = styled.button`
    margin-top: 20px;
    border: none;
    height: 30px;
    width: 75px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const SlateSwipeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    background-color: ${lightGray};
    padding: 10px;
    border-radius: 10px;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 50%;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 60%;
    }
`

const SlateSwipeButton  = styled.button`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    border: none;
    cursor: pointer;
`

const EventTeamHeader = ({eventRecord,openModal,handleBetSelection}) => {
    
    const [showSwipe,setShowSwipe] = React.useState(false);
    
    let navigate = useNavigate();
    const record = eventRecord[0];
    const { 
        event_id
        , event_datetime
        , event_status
        , event_details
        , league_abbreviation
    } = record;

    const {
        away_team_id
        , home_team_id
        , away_team_location
        , home_team_location
        , away_team_name
        , home_team_name
        , away_team_photo_filename
        , home_team_photo_filename
        , away_score
        , home_score
        , away_spread_point
        , home_spread_point
        , away_spread_swipe_pct
        , home_spread_swipe_pct
        , over_swipe_percentage
        , under_swipe_percentage
        , away_total_point
        , home_total_point
    } = event_details[0];

    const handleClick = (event,teamId) => {
        event.preventDefault();
        navigate(`/team/${teamId}`);
    }

    function handleOpenSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSwipe(true);
    }

    function handleCloseSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSwipe(false);
    }
    
    return(
        <EventHeaderContainer>
            <EventSummaryContainer>
                <TeamContainer onClick={(event) => handleClick(event,away_team_id)}>
                    <TeamLogoContainer>
                        <Avatar imageSource={fileToUrl(away_team_photo_filename)} imageSize={'75px'} isTeamLogo={true}/>
                    </TeamLogoContainer>
                    <TeamName>{league_abbreviation === 'MLB' ? away_team_name : away_team_location}</TeamName>
                </TeamContainer>
                <ScoreContainer>
                    {roundScore(away_score)}
                </ScoreContainer>
                <EventCenterContainer>
                    <div>{checkDate(event_datetime,event_status)}</div>

                    {event_status != 'complete' && (
                    <BetButton onClick={openModal}>
                        BET
                    </BetButton>)}
                </EventCenterContainer>
                <ScoreContainer>
                    {roundScore(home_score)}
                </ScoreContainer>
                <TeamContainer onClick={(event) => handleClick(event,home_team_id)}>
                    <TeamLogoContainer>
                        <Avatar imageSource={fileToUrl(home_team_photo_filename)} imageSize={'75px'}  isTeamLogo={true}/>
                    </TeamLogoContainer>
                    <TeamName>{league_abbreviation === 'MLB' ? home_team_name : home_team_location}</TeamName>
                </TeamContainer>
            </EventSummaryContainer>
            <SlateSwipeContainer >
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginBottom:'10px'}}>
                    <div style={{flex:'1',display:'flex',alignItems:'center'}}><span style={{fontSize:'1em',fontWeight:'bold'}}>{!Number(away_spread_swipe_pct) && !Number(home_spread_swipe_pct) ? ' - ' : Math.round(away_spread_swipe_pct * 100,1)}%</span></div>
                    <div style={{flex:'1',fontSize:'0.8em',textAlign:'center'}}>{roundOdds(away_spread_point,1)}/{roundOdds(home_spread_point,1)}</div>
                    <div style={{flex:'1',textAlign:'right',display:'flex',justifyContent:'end'}}><span style={{fontSize:'1em',fontWeight:'bold'}}>{!Number(away_spread_swipe_pct) && !Number(home_spread_swipe_pct) ? ' - ' : Math.round(home_spread_swipe_pct * 100,1)}%</span></div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginBottom:'10px'}}>
                    <div style={{flex:'1',display:'flex',alignItems:'center'}}><span style={{fontSize:'1em',fontWeight:'bold'}}>{!under_swipe_percentage && !over_swipe_percentage ? ' - ' : Math.round(over_swipe_percentage * 100,1)}%</span></div>
                    <div style={{flex:'1',fontSize:'0.8em',textAlign:'center'}}>{roundOdds(away_total_point,1,'over')}/{roundOdds(home_total_point,1,'under')}</div>
                    <div style={{flex:'1',textAlign:'right',display:'flex',justifyContent:'end'}}><span style={{fontSize:'1em',fontWeight:'bold'}}>{!under_swipe_percentage && !over_swipe_percentage ? ' - ' : Math.round(under_swipe_percentage * 100,1)}%</span></div>
                </div>
                <div><span style={{fontStyle:'italic',fontWeight:'bold',color:secondaryColor}}>SLATESWIPE</span> Sentiment</div>
            </SlateSwipeContainer>
            {checkDate(event_datetime,event_status) !== 'Live Now' && checkDate(event_datetime,event_status) !== 'Final' && (
                <div style={{marginBottom:'12px'}}>
                    <SlateSwipeButton onClick={handleOpenSwipe}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{marginRight:'7px'}}>
                            <SlateSwipeIcon 
                                size={16}
                                color={'white'}
                                />
                            </div>
                            <div style={{color:'white'}}>Open<span style={{marginLeft:'5px',fontWeight:'bold',fontStyle:'italic'}}>SLATESWIPE</span></div>
                        </div>
                    </SlateSwipeButton>
                </div>
            )}
            <EventItem record={record} handleBetSelection={event_status != 'complete' ? handleBetSelection : null}/>
            {showSwipe && (
                <SlateSwipe 
                    closeModal={handleCloseSwipe}
                    leagueAbbreviation={league_abbreviation}
                    eventId={event_id}
                />
            )}
        </EventHeaderContainer>
    );
}

const EventIndividualHeader = ({record}) => {
    return(
        <>
            <EventItem record={record} eventType={record.event_type} />
        </>
    );
}

const Event = () => {
    let {eventId} = useParams();

    const [eventRecord,setEventRecord] = React.useState({});
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    const [feed,setFeed] = React.useState([]);
    const [showBookLink,setShowBookLink] = React.useState(false);
    const [betSelection,setBetSelection] = React.useState(null);

    React.useEffect(() => {
        const LoadEvent = async () => {
            try {
                const preferredBook = loggedInProfileData.preferredBook;
                const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,null,eventId,preferredBook);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    console.log('data.events:',data.events);
                    setEventRecord(data.events);
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (loggedInProfileData.preferredBook) {
            LoadEvent();
        }
        
    },[eventId,setEventRecord,loggedInProfileData]);

    React.useEffect(() => {
        if (!showBookLink) {
            document.body.style.overflow = '';
        } else {
            document.body.style.overflow = 'hidden';
        }
    },[showBookLink]);

    function openModal(event) {
        event.preventDefault();
        setShowBookLink(true);
    }

    function closeModal(event) {
        event.preventDefault();
        setShowBookLink(false);
        setBetSelection(null);
    }

    const handleBetSelection = (event,choice) => {
        event.preventDefault();
        setBetSelection(choice);
        openModal(event);
    }


    return(
        <Layout >
            {!eventRecord && (
                <div style={{width:'100%',height:'100px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {eventRecord && eventRecord.length === 0 && (
                <div style={{width:'100%',height:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    There was an error loading data for this event.
                </div>
            )}
            {eventRecord && eventRecord.length > 0 && eventRecord[0].league_abbreviation === 'NFL' && (
                <div style={{width:'100%',textAlign:'center'}}>
                    <h1>{eventRecord[0].event_name}</h1>
                </div>
            )}
            {eventRecord.length > 0 && (!eventRecord[0].event_type || eventRecord[0].event_type === 'team') && eventRecord[0].league_abbreviation !== 'NFL' && (
                <EventTeamHeader 
                    eventRecord={eventRecord} 
                    openModal={openModal}
                    handleBetSelection={handleBetSelection}
                    />
            )}
            {eventRecord.length > 0 && eventRecord[0].event_type === 'individual' && (
                <EventIndividualHeader record={eventRecord[0]} />
            )}
            <NewPost setFeed={setFeed} eventId={eventId}/>
            <Feed feed={feed} setFeed={setFeed} eventId={eventId}/>
            <div style={{height:'100px',width:'100%'}}></div>
            {showBookLink && (
                <BookLink 
                    closeModal={closeModal} 
                    record={eventRecord[0]} 
                    betSelection={betSelection} 
                    handleBetSelection={handleBetSelection}/>
            )}
        </Layout>
    );
}

export default Event; 