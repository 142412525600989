import React from 'react';
import styled from 'styled-components';
import NotificationService from '../api/notificationService';
import BeatLoader from 'react-spinners/BeatLoader';
import { useNavigate } from 'react-router-dom';
import { borderColor, secondaryColor } from '../styles/Styles';
import { formatDate } from '../utils';

const NotificationContainer = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    text-align: left;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;
    background-color: ${props => props.$IsViewed === 0 ? secondaryColor : 'white'};
`

const Notification = ({content}) => {
    
    const {notifierFullname,postId,isViewed,notificationDatetime,notificationType} = content;
    
    const navigate = useNavigate();

    const handleNotificationClick = (postId) => {
        navigate(`/status/${postId}`);
    }

    const notificationString = () => {
        switch (notificationType) {
            case 'mention':
                return "mentioned you in a post";
            case 'reply':
                return "replied to your post";
            case 'like':
                return "liked your post";
            case 'reaction':
                return "reacted to your post";
            default:
                return "mentioned you"
        }
        
    }

    return(
        <NotificationContainer onClick={() => handleNotificationClick(postId)} $IsViewed={isViewed}>
            <div><span style={{fontWeight: 'bold', paddingRight: '3px'}}>{notifierFullname}</span>{notificationString(notificationType)}</div>
            <div style={{color:'gray'}}>{formatDate(notificationDatetime)}</div>

        </NotificationContainer>
    )
}

const FeedContainer = styled.div`
    margin-top: 1px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
`
const LoadingSpinnerContainer = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NotificationsFeed = ({notificationsFeed,setNotificationsFeed,setAuthenticationState,authState,page,setNewNotifications}) => {

    const [loading,setLoading] = React.useState(false);
    const [isEnd,setIsEnd] = React.useState(false);

    const LoadNotifications = React.useCallback(async () => {

        if (loading || isEnd) return;

        try {
            const loadedNotificationIds = notificationsFeed.map(notification => notification.notificationId);
            
            setLoading(true);
            const { data, errorMsg } = await NotificationService.viewNotifications(setAuthenticationState,authState,loadedNotificationIds);
            if (errorMsg) {
                console.log("Api error:",errorMsg);
            } else if (data.notifications.length === 0) {
                setIsEnd(true);
            } else if (data.notifications) {
                setNotificationsFeed(prevFeed => [...prevFeed,...data.notifications]);
            }
            setLoading(false);
        } catch (error) {
            console.log("Unexpected error",error);
            setLoading(false);
        }
    },[notificationsFeed,setNotificationsFeed,setAuthenticationState,authState,loading,setLoading,isEnd,setIsEnd]);

    React.useEffect(() => {
        LoadNotifications();
        setNewNotifications(false);
        // eslint-disable-next-line
    },[page]);

    return(
        <FeedContainer>
            {notificationsFeed.map((content,index) => (
                <Notification content={content} key = {index}/>
                // <Post content={content} key={content.postId} setAuthenticationState={setAuthenticationState} authState={authState}/>
            ))}
            {loading && (
                <LoadingSpinnerContainer>
                    <BeatLoader />
                </LoadingSpinnerContainer>
            )}
        </FeedContainer>
    );    
}

export default NotificationsFeed;

