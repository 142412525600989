import React from "react";
import styled from "styled-components";
import { UserService } from "../../api";
import { PrimaryButton, } from "../Buttons";
import { Input } from "../Inputs";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 0.9em;
`

const USERNAME_LENGH_LIMIT = 25;

const OauthDetails = ({setAuthenticationState,authState,setUsername}) => {

    const [postData,setPostData] = React.useState({
        fullName:'',
        username:'',
    });

    const [errorMessage,setErrorMessage] = React.useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "username") {
            if (value.length <= USERNAME_LENGH_LIMIT && !/\s/.test(value)) {
                setPostData({
                    ...postData
                    ,[name]: value
                });
            }
        } else {
            setPostData({
                ...postData
                ,[name]: value
            });
        }
        
    }

    const handleSubmit = React.useCallback( async (event) => {
        
        event.preventDefault();
        
        try {

            const {data,errorMsg} = await UserService.setOauthUsernameFullName(setAuthenticationState,authState,postData);
            
            if (errorMsg) {
                setErrorMessage(errorMsg);
            } else {
                setUsername(data.username);
                setAuthenticationState(authState.AUTHENTICATED);
            }
        } catch (error) {
            console.log("Unexpected error",error);
        }
    },[postData,setAuthenticationState,authState, setUsername]);

    return(
        <>
            <h1 style={{marginBottom: '10px'}}>Welcome to BetBoard!</h1>
            <p style={{marginBottom: '10px'}}>Please fill out your name and username to complete your profile.</p>
            <Form onSubmit={handleSubmit}>
                <Input 
                    data-testid = "input-fullname"
                    type = "text"
                    name = "fullName"
                    value = {postData.fullName}
                    onChange = {handleInputChange}
                    placeholder="Name"
                    required
                />
                <Input 
                    data-testid = "input-username"
                    type = "text"
                    name = "username"
                    value = {postData.username}
                    onChange = {handleInputChange}
                    placeholder="username"
                    required
                />
                <ButtonContainer>
                    <PrimaryButton type='submit'>Submit</PrimaryButton>
                </ButtonContainer>
                {errorMessage && (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}
            </Form>
        </>
    );
}

export default OauthDetails;