import React from 'react';
import styled from 'styled-components';

const BookContainer = styled.div`
    padding-top: 1px;
    display: flex;
    justify-content: end;
    box-sizing: border-box;    
    font-size: 12px;
    padding-right: 10px;
    color: ${props => props.$Color ? props.$Color : 'black'};
`

const OddsByDisplay = ({bookName,color}) =>{
    return(
        <BookContainer $Color={color} >
            Odds data from {bookName} 
        </BookContainer>
    )
}

export default OddsByDisplay;