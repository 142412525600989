import React from 'react';
import { useParams } from 'react-router-dom';
import PostService from '../api/postService';
import { navbarHeight } from '../styles/Styles';


import { Layout, Post, NewPost, Feed, AuthenticationContext, Comment } from '../components';

const PostWithParents = ({postId,loading,setLoading}) => {

    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    const containerRef = React.useRef(null);
    const [parentFeed,setParentFeed] = React.useState([]);

    
    const LoadPosts = React.useCallback(async () => {
        
        try {
            setLoading(true);
            const { data, errorMsg } = await PostService.loadPostWithParents(setAuthenticationState,authState,postId);
            
            if (!data.posts || data.posts.length === 0) {
                console.log("API Error:",errorMsg);
            } else {
                setParentFeed(data.posts);
            }
            setLoading(false);
        } catch (error) {
            console.log("Unexpected error",error);
            setLoading(false);
        }
    },[setAuthenticationState,authState,postId,setLoading]);

    React.useEffect(() => {
        setParentFeed([]);
        LoadPosts();
        // eslint-disable-next-line
    },[postId]);

    React.useEffect(() => {
        if (!loading) {
            if (containerRef.current && containerRef.current.lastChild) {
                const lastChild = containerRef.current.lastChild;
                const scrollPosition = lastChild.getBoundingClientRect().top + window.scrollY - 55;            
                window.scrollTo({ top: scrollPosition, behavior: 'smooth'});
            }
        }
        // eslint-disable-next-line
      }, [loading,parentFeed]);


    return(
        <div ref={containerRef} style={{display:'flex',flexDirection:'column',width:'100%'}}>
            {parentFeed.map((content,index) => (
                <Post content={content} key={content.postId} setAuthenticationState={setAuthenticationState} authState={authState}/>
            ))}
        </div>
    );
}


const ViewPost = () => {
    let { postId } = useParams();
    const [loading,setLoading] = React.useState(false);
    const [feed,setFeed] = React.useState([]);
    

    React.useEffect(() => {
        // LoadPosts();
        setFeed([]); //if we navigate to another page, re-run this
        // eslint-disable-next-line
    },[postId])

    return(
        <Layout >
            {/* <Post content={content} fontSize={"1.1em"} /> */}
            <PostWithParents  postId={postId} loading={loading} setLoading={setLoading}/>
            <Comment setFeed={setFeed}  replyToPostId={postId} />
            <Feed feed={feed} setFeed={setFeed}  replyToPostId={postId} isViewPost={true}/>
        </Layout>
    );
}

export default ViewPost;