import React from 'react';
import styled from 'styled-components';
import { borderColor, navbarHeight, primaryColor, secondaryColor, Loader } from '../styles/Styles';
import { Navbar, HomeMenu, Event, AuthenticationContext, EventList } from '../components';
import NotificationService from '../api/notificationService';
import { MOBILE_WIDTH } from '../config';
import SlateSwipe from './SlateSwipe';
import { EventService } from '../api';
import { CloseButton } from './Buttons';
import {LeagueIconScroll} from './LeagueLists';
import { BackButton } from './Buttons';


const PageContent = styled.div`
    padding-top: ${navbarHeight};
    position: relative;
    z-index: 2;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
    }
`

const Left = styled.div`
    
    left: 0;
    background-color: white;
    position: -webkit-fixed;
    position: fixed;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        top: ${navbarHeight};
        width: 28.5%;
    }    

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        z-index: 3;
        
      }
`

const Center = styled.div`
    
    overflow-y: auto;
    overflow-x: hidden;

    width: 43%;
    margin-left: 28.5%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    @media screen and (max-width: ${MOBILE_WIDTH}px) {

        flex: 1;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        margin-left: 0px;
        
      }
`

const Right = styled.div`

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 28.5%;
        position: ${props => props.$IsSticky ? '-webkit-fixed' : 'absolute'};
        position: ${props => props.$IsSticky ? 'fixed' : 'absolute'};
        top: ${props => props.$IsSticky ? '' : navbarHeight};
        right: 0;
        bottom: ${props => props.$IsSticky ? 0 : ''};
        box-sizing: border-box;
        padding-right: 10px;
        min-height: calc(100vh - ${navbarHeight});
    }
    
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {

        position: relative;
        height: 109px;
        padding-top: 10px;
        // padding-bottom: 10px;
        width: 100%;
        
        display: flex;
        flex-direction: row;
        
        // min-height: 112px;
        overflow-x: auto;
        flex-wrap: nowrap;
    
      }
`

const Select = styled.select`
      height: 40px;
      border: 1px solid ${borderColor};
      border-radius: 5px;
      font-weight: bold;
      width: 100px;
      padding-left: 5px;
      cursor: pointer;

      @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        margin-left: 10px;
    }
`

const DropdownContainer = styled.div`
    display: flex;
    padding-top: 10px;
    justify-content: start;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        padding-top: 0px;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-left: 5px;
    }
`

const Dropdown = styled.div`
    // margin-left: 5px;
    // margin-right: 10px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-top: 10px;
        // margin-bottom: 3px;
    }
`

function getEventTypeFromLeague(leagueAbbreviation) {
    switch (leagueAbbreviation) {
        case 'NCAAB':
            return 'team';
        case 'PGA':
            return 'individual';
        default:
            return 'team';
    }
}

const LeagueDropdown = ({selectedLeague,setSelectedLeague}) => {
 
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [options,setOptions] = React.useState([]);
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        const LoadLeagues = async () => {
            try {
                setLoading(true);
                const {data,errorMsg} = await EventService.loadLeagues(setAuthenticationState,authState);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setOptions(data.leagues.map(league => league.league_abbreviation));
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        LoadLeagues();
    },[]);

    const handleChange = (event) => {
        setSelectedLeague(event.target.value);
    }

    return(
        <Dropdown>
            {loading && (
                <div style={{width:'100px',height:'100px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {!loading && (
                <Select value={selectedLeague} onChange={handleChange}>
                    {options.map((option,index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </Select>
            )}
        </Dropdown>
    );
    
}

const OddsDropdown = ({selectedOdds,setSelectedOdds}) => {

    const handleChange = (event) => {
        setSelectedOdds(event.target.value);
    }

    return (
        <Dropdown>
            <Select value={selectedOdds} onChange={handleChange}>
                <option value='moneyline'>Moneyline</option>
                <option value='spread'>Spread</option>
                <option value='total'>Total</option>
            </Select>
        </Dropdown>
    );

}

const InfiniteLoader = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: orange;
`

const SlateSwipeButton = styled.div`
    box-shadow: 0px 0px 5px 5px ${borderColor};
    position:-webkit-fixed;
    position: fixed;
    border: none;
    z-index: 4;
    bottom: 80px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    right: 10px;
    height: 60px;
    width: 60px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${secondaryColor};

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        display: none;
    }

`

const NewVersionNotification = styled.div`
    position: fixed;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${secondaryColor};
    color: white;
    padding-left: 5px;
    font-size: 0.8em;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        bottom: 0px;
        left: 0px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        bottom: 70px;
        left: 0px;
    }
`

const BackButtonContainer = styled.div`
    display: flex;
    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        margin-top: 10px;
        margin-botom: 10px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height:100%;
        margin-left:10px;
        margin-right:10px;
        display:flex;
        align-items:center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`

{/* <div style={{position:'fixed',bottom:'70px',left:'0px',width:'100%',height:'50px',backgroundColor:'red',color:'white',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        New version available. Please refresh the app.
                    </div */}

const testUsers = ['paulstuart24','paulstuart22','TheGodfather','cuzbuzz'];

const Layout = ({children,hideBack,isHighlight}) => {
    
    const {setAuthenticationState,authState,loggedInProfileData,setNewNotifications,appVersion,newVersion,setNewVersion} = React.useContext(AuthenticationContext);
    const [selectedLeague,setSelectedLeague] = React.useState(localStorage.getItem('selectedLeague') || null);
    // const [selectedLeague,setSelectedLeague] = React.useState(null);
    const [selectedOdds,setSelectedOdds] = React.useState(localStorage.getItem('selectedOdds') || 'moneyline');
    const [page,setPage] = React.useState(1);
    const [isRefreshing,setIsRefreshing] = React.useState(false);
    const [startY, setStartY] = React.useState(null);
    const [isRightSticky,setIsRightSticky] = React.useState(false);
    const loader = React.useRef(null);
    const centerRef = React.useRef(null);
    const rightRef = React.useRef(null);
    const [showSlateSwipe,setShowSlateSwipe] = React.useState(false);


    React.useEffect(() => {
        const observer = new IntersectionObserver(handleObserver,{
            root: null,
            threshold: 0,
        })

        const currentLoader = loader.current;

        if (currentLoader) {
            observer.observe(currentLoader);
        }

        return () => {
            if (currentLoader) {
                observer.unobserve(currentLoader);
            }
        };
    // eslint-disable-next-line
    },[]);

    const handleObserver = (entities) => {
        const target = entities[0];

        const currentScrollPosition = window.scrollY;

        if (target.isIntersecting && currentScrollPosition > 10) {
            setPage((prevPage) => prevPage + 1);
        }
    }

    
    React.useEffect(() => {
        function handleScroll() {
          const container = rightRef.current;
          if (container) {
            
            const viewportHeight = window.innerHeight;
            if (window.scrollY > container.clientHeight - viewportHeight + parseInt(navbarHeight)) {
                setIsRightSticky(true);
            } else {
                setIsRightSticky(false);
            }
          }
        }
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      function handleTouchStart(e) {
        setStartY(e.touches[0].clientY);
      }

      React.useEffect(() => {
        function handleTouchMove(e) {
          if (e.touches.length !== 1 || startY === null) return;
          const deltaY = e.touches[0].clientY - startY;
        //   if (deltaY > 0 && window.scrollY === 0) {
        //     e.preventDefault(); // Prevent scrolling the page
        //   }
        }
    
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
    
        return () => {
          document.removeEventListener('touchmove', handleTouchMove);
        };
      }, [startY]);

    function handleTouchEnd(e) {
        if (window.scrollY > 0) return;
        if (startY !== null) {
          const deltaY = e.changedTouches[0].clientY - startY;
          if (deltaY > 200) {
            setIsRefreshing(true);
          }
          setStartY(null);
        }
      }

    const enhancedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { centerRef, page, isRefreshing, setIsRefreshing });
        }
        return child;
    });

    React.useEffect(() => {
        const checkNewNotifications = async () => {
          try {
            const {data,errorMsg} = await NotificationService.checkNotifications(setAuthenticationState,authState);
    
            if (!data) {
              console.log("API error:",errorMsg);
            } else {
              if (data.new_notifications === 1) {
                setNewNotifications(true);
              } else {
                setNewNotifications(false);
              }

              if (data.app_version !== appVersion) {
                setNewVersion(true);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
        
        checkNewNotifications();
      },[]);
    
      function openSlateSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSlateSwipe(true);
        document.body.style.overflow = 'hidden';
    }

    function closeSlateSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSlateSwipe(false);
        document.body.style.overflow = 'auto';
    }

    React.useEffect(() => {
        if (selectedLeague) {
            localStorage.setItem('selectedLeague', selectedLeague);
        } else {
            localStorage.removeItem('selectedLeague');
        }
            
      }, [selectedLeague]);
    
    React.useEffect(() => {
        localStorage.setItem('selectedOdds', selectedOdds);
      }, [selectedOdds]);

    return(
        
        <>
            <PageContent
                onTouchStart={e => handleTouchStart(e)}
                // onTouchMove={e => handleTouchMove(e)}
                onTouchEnd={e => handleTouchEnd(e)}
            >
                <Navbar imageUrl={loggedInProfileData.profilePhotoFilename} hideBack={hideBack}/>      
                
                <Left>
                    <HomeMenu username={loggedInProfileData.username} openSlateSwipe={openSlateSwipe} />
                </Left>
                <Center ref={centerRef}>
                    {enhancedChildren}
                    {!isHighlight && (
                        <InfiniteLoader ref={loader}>
                        </InfiniteLoader>
                    )}
                </Center>
                <Right ref={rightRef} $IsSticky={isRightSticky}>
                    {!selectedLeague && (<LeagueIconScroll setSelectedLeague={setSelectedLeague}/>)}
                    {selectedLeague && (
                        <>  
                            <BackButtonContainer >
                                <BackButton 
                                    onClick={() => setSelectedLeague(null)} 
                                    backgroundColor={primaryColor}
                                    arrowColor={'white'}
                                    />
                                {/* {!['PGA','NFL','LIV'].includes(selectedLeague) && (<OddsDropdown selectedOdds={selectedOdds} setSelectedOdds={setSelectedOdds} />)} */}
                            </BackButtonContainer>
                            <EventList leagueAbbreviation={selectedLeague} selectedOdds={selectedOdds} eventType={getEventTypeFromLeague(selectedLeague)} isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing }/>
                        </>
                    )}
                    
                    {/* <DropdownContainer>
                        <LeagueDropdown selectedLeague={selectedLeague} setSelectedLeague={setSelectedLeague} />
                        {!['PGA','NFL','LIV'].includes(selectedLeague) && (<OddsDropdown selectedOdds={selectedOdds} setSelectedOdds={setSelectedOdds} />)}
                    </DropdownContainer>
                    <EventList leagueAbbreviation={selectedLeague} selectedOdds={selectedOdds} eventType={getEventTypeFromLeague(selectedLeague)} isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing }/> */}
                </Right>
                {newVersion && (
                    <NewVersionNotification>
                        <div style={{flex:'1'}}>A new app version is available. Please refresh the page or quit & re-open the test app.</div>
                        <div style={{width:'50px',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CloseButton onClick={() => setNewVersion(false)} />
                        </div>
                    </NewVersionNotification>
                )}
                {showSlateSwipe && (<SlateSwipe closeModal={closeSlateSwipe} display={showSlateSwipe ? 'flex' : 'none'}/>)}
                {/* {testUsers.includes(loggedInProfileData.username) && (
                <SlateSwipeButton onClick={openSlateSwipe} >
                +/-
                </SlateSwipeButton>)} */}
                {isRefreshing && (
                    <div style={{width:'100%',height:'100px',position:'relative'}}>
                        <Loader />
                    </div>
                )} 
            </PageContent>
        </>
    );
}


export default Layout;
