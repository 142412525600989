import React from "react";
import styled from "styled-components";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { borderColor, lightGray, secondaryColor,mediumGray } from "../../styles/Styles";
import { FaPlay } from "react-icons/fa";
import { LuCalendarDays } from "react-icons/lu";
import { FaRegCommentAlt } from "react-icons/fa";
import { RxTextAlignJustify } from "react-icons/rx";
import { RiCalendarEventLine } from "react-icons/ri";
import { HighlightService } from "../../api";
import { AuthenticationContext } from "../AuthenticationContext";
import { Loader } from "../../styles/Styles";

const HighlightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    max-height: calc(100vh - 60px);
`

const HighlightButton = styled.button`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: none;
`

const NewVideoContainer = styled.div`
    width: ${props => props.$IsShowAddDescription ? '' : '100%'};
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    flex: 1;
    max-height: 660px;
    position:relative;
`

const NewVideo = styled.video`
    height: 100%;
    width: 100%;
    border-radius: 15px;
    object-fit: ${props => props.$IsShowAddDescription ? 'contain' : 'cover'};
    object-position: center;
`

const ActionContainer = styled.div`
    display:flex;
    justify-content:end;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
`

const ActionButtons = styled.button`
    height: 40px;
    border-radius:999px;
    border: none;
    background-color: ${props => props.$IsSubmit ? secondaryColor : borderColor};
    color: ${props => props.$IsSubmit ? 'white' : 'black'};
    font-size: 0.9em;
    font-weight: bold;
    margin-left: 10px;
`

const DescriptionInput = styled.textarea`
    width: 100%;
    border: none;
    border-bottom: 2px solid ${borderColor};
    font-size: 1.05em;
    font-family: 'IBM Plex Sans', sans-serif;
    wrap: soft;
    resize: none; /* Disable manual resizing */
    overflow: hidden;
`

const maxDescriptionLength = 60;

const Highlight = ({eventId,teamId,playerId,setNewPostVisible}) => {

    const [highlightData,setHighlightData] = React.useState({
        postText:'',
        postTextRich:'',
        mentions:null,
        hashtags: null,
        eventId: eventId ? eventId : null,
        teamId: teamId ? teamId : null,
        playerId: playerId ? playerId : null,
        videoUrl:null,
        videoFile:null,
    });
    const videoRef = React.useRef(null);
    const [isPlaying,setIsPlaying] = React.useState(false);
    const [showAddDescription,setShowAddDescription] = React.useState(false);
    const [tempDescription,setTempDescription] = React.useState('');
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [error,setError] = React.useState(false);
    const [loading,setLoading] = React.useState(false);

    const handleMediaChange = React.useCallback((event) => {
        
        const file = event.target.files[0];
        
        if (!file) {
            console.log("No file selected");
            return;
        }

        try {
            if (file.type.startsWith('video/')) {
                setHighlightData(prevState => ({
                    ...prevState,
                    videoFile:file,
                    videoUrl:URL.createObjectURL(file),
                }));
            } else {
                throw new Error("Invalid file type selected");
            }
        } catch (error) {
            console.log(error);
        } finally {
            event.target.value = null;
        }
    },[setHighlightData]);

    const togglePlay = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (videoRef.current) {
          if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play();
            setIsPlaying(true);
          } else {
            videoRef.current.pause();
            setIsPlaying(false);
          }
        }
      };

    function handleLoadedMetadata() {
        if (videoRef.current) {
            const duration = videoRef.current.duration;
            if (duration > 45) {
                setError(true);
            }
        }
    }

    const discardVideo = () => {
        setHighlightData(prevState => ({
            ...prevState,
            videoUrl: null,
            videoFile: null
        }));
        setError(false);
    }

    function showAddDescriptionTrue(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowAddDescription(true);
    }

    function handleDescriptionChange(event) {
        event.preventDefault();
        event.stopPropagation();

        if (event.target.value.length > maxDescriptionLength) {
            return;
        }

        setTempDescription(event.target.value);
    }

    function cancleDescriptionChange(event) {
        event.preventDefault();
        event.stopPropagation();
        setTempDescription(highlightData.postText);
        setShowAddDescription(false);
    }

    function saveDescriptionChange(event) {
        event.preventDefault();
        event.stopPropagation();
        setHighlightData(prevState => ({
            ...prevState,
            postText:tempDescription,
        }));
        setShowAddDescription(false);
    }

    async function handleHighlightSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!highlightData.videoFile) {
            
            return;
        } else {
            
            try {
                setLoading(true);
                const formData = new FormData();
                if (highlightData.postText) formData.append('postText',highlightData.postText);
                if (highlightData.eventId) formData.append('eventId',highlightData.eventId);
                if (highlightData.teamId) formData.append('teamId',highlightData.teamId);
                if (highlightData.playerId) formData.append('playerId',highlightData.playerId);
                formData.append('videoFile', highlightData.videoFile);
                
                if (!formData.get('videoFile') || !highlightData.videoFile) {
                    console.log("===video files===");
                    console.log("formData.videoFile===>",formData.get('videoFile'));
                    console.log("highlightData.videoFile===>",highlightData.videoFile);
                    throw new Error("no video file");
                }

                const response = await HighlightService.createHighlight(formData,setAuthenticationState,authState);
                if (!response.data) {
                    console.log("API Error: ",response.errorMsg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
                setNewPostVisible(false);
            }
        }
    }

    return(
        <HighlightContainer>
            {!highlightData.videoUrl && (
                <HighlightButton type = 'button' onClick={() => document.getElementById('mediaUpload').click()}>
                    <MdOutlineAddPhotoAlternate size={'30px'} color={secondaryColor} />
                    <input
                        type="file"
                        accept="video/*"
                        id="mediaUpload"
                        name="mediaUpload"
                        onChange={handleMediaChange}
                        style={{ display: 'none' }}
                    />
                </HighlightButton>
            )}
            {highlightData.videoUrl && !error && (
                <>
                    <ActionContainer style={{height:'55px',paddingBottom:'5px'}}>
                        <ActionButtons style={{width:'50px'}} onClick={showAddDescriptionTrue}>
                            <div style={{translate:'0px 3px',fontSize:'25px'}}><RxTextAlignJustify /></div>
                        </ActionButtons>
                        {/* <ActionButtons style={{width:'50px'}} onClick={showAddDescriptionTrue}> */}
                            {/* <div style={{translate:'0px 3px',fontSize:'25px'}}><RiCalendarEventLine /></div> */}
                        {/* </ActionButtons> */}
                    </ActionContainer>
                    <NewVideoContainer onClick={togglePlay} $IsShowAddDescription={showAddDescription}>
                        <NewVideo controls={false} ref={videoRef} onLoadedMetadata={handleLoadedMetadata} $IsShowAddDescription={showAddDescription} >
                            <source src={highlightData.videoUrl} type="video/mp4"/>
                        </NewVideo>
                        {!isPlaying && (
                            <div onClick={togglePlay} style={{top:'50%',left:'50%',position:'absolute',width:'50px',height:'50px'
                                ,border:'2px solid white',borderRadius:'999px',transform:'translate(-50%,-50%)',color:'white',fontSize:'2em'
                                ,fontWeight:'bold'
                                ,display:'flex'
                                ,alignItems:'center'
                                ,justifyContent:'center'}}>
                                <div style={{width:'32px',height:'32px',paddingLeft:'7px'}}>
                                    <FaPlay />
                                </div>
                            </div>
                        )}
                        {loading && (
                            <div style={{position:'absolute',top:'0px',left:'0px',width:'100%',height:'100%',backgroundColor:'rgba(255,255,255,0.5)',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <Loader />
                            </div>
                        )}
                    </NewVideoContainer>
                    {!showAddDescription && (
                        <ActionContainer style={{height:'60px',paddingTop:'5px',paddingBottom:'5px'}}>
                            <ActionButtons type='submit' onClick={handleHighlightSubmit} $IsSubmit={true} style={{width:'125px'}}>Post</ActionButtons>
                        </ActionContainer>
                    )}
                    {showAddDescription && (
                        <div style={{flex:'0.5',width:'100%',boxSizing:'border-box',paddingLeft:'10px',paddingRight:'10px',marginTop:'30px',display:'flex',flexDirection:'column',alignItems:'center',marginBottom:'5px'}} >
                            <DescriptionInput 
                                type='text' 
                                placeholder="Add a description"
                                onChange={handleDescriptionChange}
                                value={tempDescription}
                                />
                            <ActionContainer style={{display:'flex',width:'100%',justifyContent:'end',marginTop:'10px'}}>
                                <div style={{color:mediumGray,fontSize:'0.9em'}}>
                                    {tempDescription.length}/{maxDescriptionLength}
                                </div>
                                <ActionButtons style={{width:'100px'}} onClick={cancleDescriptionChange}>Cancel</ActionButtons>
                                <ActionButtons $IsSubmit={true} style={{width:'100px'}} onClick={saveDescriptionChange}>Save</ActionButtons>
                            </ActionContainer>
                        </div>
                    )}
                </>
            )}
            {highlightData.videoUrl && error && (
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'250px',borderRadius:'10px',border:`1px solid ${borderColor}`}}>
                    <div style={{fontSize:'1.1em',fontWeight:'bold',paddingTop:'10px'}}>Cannot Upload Video</div>
                    <div style={{textAlign:'center',paddingTop:'5px',paddingBottom:'10px'}}>Video cannot be longer than 15 seconds</div>
                    <button style={{border:'none',backgroundColor:'white',borderTop:`1px solid ${borderColor}`,width:'100%',paddingTop:'15px',paddingBottom:'15px',fontSize:'1.05em',fontWeight:'bold'}} onClick={discardVideo}>OK</button>
                </div>
            )}
        </HighlightContainer>
    )
}

export default Highlight;

// {postData.videoUrl && (
//     <NewMediaContainer>
        
//     </NewMediaContainer>
// )}