import React from 'react';
import styled from 'styled-components';
import { Post } from './Posts';
import PostService from '../api/postService';
import BeatLoader from 'react-spinners/BeatLoader';
import { AuthenticationContext } from './AuthenticationContext';

const FeedContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: ${props => props.$IsViewPost ? '100vh' : 'none'};
`

const Loader = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoadingSpinnerContainer = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Feed = ({feed,setFeed,page,viewedUsername,replyToPostId,isViewPost,eventId,teamId,hashtagText,isRefreshing,setIsRefreshing,playerId,highlightId}) => {
    // console.log("replyToPostId",replyToPostId);
    // console.log("isViewPost",isViewPost);
    // console.log("viewedUsername",viewedUsername);
    // const loader = React.useRef(null);
    // const [page,setPage] = React.useState(1);
    const [loading,setLoading] = React.useState(false);
    const [isEnd,setIsEnd] = React.useState(false);
    const [resetFlag, setResetFlag] = React.useState(false);
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    function updateFeedWithReplyChain(existingFeed,newPosts) {
        const updatedFeed = [...existingFeed,...newPosts];

        let parent_post_ids = [];
        let child_post_ids = [];

        updatedFeed.forEach(post => {
            if (post.reply_to_post_id) {
                if (!parent_post_ids.includes(post.reply_to_post_id)) {
                    parent_post_ids.push(post.reply_to_post_id);
                }
                if (!child_post_ids.includes(post.postId)) {
                    child_post_ids.push(post.postId);
                }
            }
        });

        updatedFeed.forEach(post => {
            post.is_parent = parent_post_ids.includes(post.postId) ? true : false;
            post.is_child = child_post_ids.includes(post.postId) ? true : false;
        });

        for (let i = 0; i < updatedFeed.length; i++) {

            if (updatedFeed[i].is_child) {
                if (updatedFeed[i + 1]) {
                    if(updatedFeed[i + 1].is_child) {
                        updatedFeed[i].is_last_child = false;
                    } else {
                        updatedFeed[i].is_last_child = true;
                    }
                } else {
                    updatedFeed[i].is_last_child = true;
                }
            } else {
                updatedFeed[i].is_last_child = null;
            }
        }
        setFeed(updatedFeed);
    }

    const LoadPosts = React.useCallback(async () => {
        if (loading || isEnd || resetFlag) return;

        try {
            setLoading(true);
            const loadedPostIds = feed.map(post => post.postId);
            const { data, errorMsg } = await PostService.loadPosts(setAuthenticationState, authState, loadedPostIds, viewedUsername, null, replyToPostId, eventId, teamId, hashtagText, playerId,highlightId);
            
            if (errorMsg) {
                console.log("Api error:", errorMsg);
            } else if (data.posts.length === 0) {
                setIsEnd(true);
            } else if (data.posts) {
                if (!replyToPostId && !viewedUsername) {
                    updateFeedWithReplyChain(feed,data.posts);
                } else {
                    setFeed(prevFeed => [...prevFeed, ...data.posts]);
                }
                
            }
            
        } catch (error) {
            console.log("Unexpected error", error);
        } finally {
            setLoading(false);
            if (isRefreshing) setIsRefreshing(false);
        }
    }, [resetFlag, loading, isEnd, feed, setFeed, setAuthenticationState, authState, viewedUsername, replyToPostId, eventId, teamId, hashtagText,isRefreshing,setIsRefreshing, playerId,highlightId]);

    React.useEffect(() => {
        setResetFlag(true);
        setIsEnd(false);
        setFeed([]);
        
    }, [viewedUsername, eventId, teamId, replyToPostId, hashtagText, playerId,highlightId]);

    React.useEffect(() => {
        if (isRefreshing) {
            setResetFlag(true);
            setIsEnd(false);
            setFeed([]);
        }
    },[isRefreshing])

    React.useEffect(() => {
        if (resetFlag === true && feed.length == 0) {
            setResetFlag(false);
        }
    },[resetFlag,setResetFlag,feed]);

    // Separate useEffect for handling page changes for lazy loading
    React.useEffect(() => {
        if (!resetFlag) {
            LoadPosts();
        }
        // This effect specifically targets page changes for lazy loading
    }, [page,resetFlag]);

    return(
        <FeedContainer $IsViewPost={isViewPost}>
            {feed.map((content,index) => (
                <Post content={content} key={content.postId} setAuthenticationState={setAuthenticationState} authState={authState}/>
            ))}
            {loading && (
                <LoadingSpinnerContainer>
                    <BeatLoader />
                </LoadingSpinnerContainer>
            )}
        </FeedContainer>
    );
}

export default Feed;