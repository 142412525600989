import React from 'react';

const authState = {
    UNKNOWN:0,
    AUTHENTICATED:1,
    UNAUTHENTICATED:2,
    OAUTH_USERNAME_NEEDED:3,
  }; 

const AuthenticationContext = React.createContext(authState.UNKNOWN);

const AuthenticationProvider = ({children}) => {

    const [authenticationState,setAuthenticationState] = React.useState(authState.UNKNOWN);
    const [loggedInProfileData,setLoggedInProfileData] = React.useState({
        fullName:'',
        username:'',
        profilePhotoFilename:null,
        coverPhotoFilename:'',
        preferredBook:null,
        preferredBookName: null,
        numFollowers: 0,
        numFollowing: 0,
        profileBio: '',
      });
    const [newNotifications,setNewNotifications] = React.useState(false);
    const [newVersion,setNewVersion] = React.useState(false);
    const appVersion = "2.20";

    return(
        <AuthenticationContext.Provider 
          value={{authenticationState
            ,setAuthenticationState
            ,authState
            ,loggedInProfileData
            ,setLoggedInProfileData
            ,newNotifications
            ,setNewNotifications
            ,newVersion
            ,setNewVersion
            ,appVersion
            }}>
            {children}
        </AuthenticationContext.Provider>
    );
}

export default AuthenticationProvider;
export {AuthenticationContext};