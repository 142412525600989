import styled from 'styled-components'
import { borderColor } from '../styles/Styles';

const Input = styled.input`
    border: 1px solid ${borderColor};
    height: ${props => props.$InputHeight || "50px"};
    width: ${props => props.$InputWidth || "250px"};
    padding-left: 10px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.05em;
    font-family: 'IBM Plex Sans', sans-serif;
`

const Textarea = styled.textarea`
    border: 1px solid ${borderColor};
    height: ${props => props.$InputHeight || "75px"};
    width: ${props => props.$InputWidth || "250px"};
    padding-left: 10px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.05em;
    font-family: 'IBM Plex Sans', sans-serif;
`

export {Input, Textarea};