import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { borderColor, lightGray } from '../styles/Styles';

import { Layout, AuthenticationContext } from '../components';

function calculateResultSymbol(result) {
    if (result.type === 'users') {
        return '@';
    } else {
        return '';
    }
}

const TypeContainer = styled.div`
    text-align: left;
`

const SearchItems = styled.div`
    width: 100%;
    height: 35px;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    
    &:hover {
        background-color: ${lightGray};
    }
`

const Search = () => {

    const location = useLocation();
    const { searchResults } = location.state || {};
    const navigate = useNavigate();

    const handleClick = (event,type,value) => {
        event.preventDefault();
        if (type === 'user') {
            navigate(`/${value}`);
        } else if (type === 'team') {
            navigate(`/team/${value}`);
        } else {
            console.log("unknown type:",type);
            return;
        }
    }

    return(
        <Layout >
            <div style={{width:'100%'}}>
                {searchResults.users.length > 0 && (
                    <TypeContainer>
                        <h1>Users</h1>
                        <div style={{borderTop:`1px solid ${borderColor}`}}>
                        {searchResults.users.map((result,index) => (
                            <SearchItems key={index} onClick={(event) => handleClick(event,'user',result.result_value_1)}>
                                <div style={{paddingLeft:'10px'}}>
                                    <span style={{paddingRight:'10px',fontWeight: 'bold'}}>{result.result_value_2}</span>
                                    <span>@{result.result_value_1}</span>
                                </div>
                            </SearchItems>
                        ))}
                        </div>
                    </TypeContainer>
                )}
                {searchResults.teams.length > 0 && (
                    <TypeContainer>
                        <h1>Teams</h1>
                        <div style={{borderTop:`1px solid ${borderColor}`}}>
                        {searchResults.teams.map((result,index) => (
                            <SearchItems key={index} onClick={(event) => handleClick(event,'team',result.id)}>
                                <div style={{paddingLeft:'10px'}}>
                                    <span style={{paddingRight:'10px',fontWeight: 'bold'}}>{result.result_value_2}</span>
                                    <span>{result.result_value_1}</span>
                                </div>
                            </SearchItems>
                        ))}
                        </div>
                    </TypeContainer>
                )}
                
            </div>
        </Layout>
    );
}

export default Search;
