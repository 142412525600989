import React from "react";
import styled from 'styled-components';
import { FaRegMessage } from "react-icons/fa6";
import { AiOutlineLike } from "react-icons/ai";
// import { AiOutlineRetweet } from "react-icons/ai";
import { FaRegChartBar } from "react-icons/fa";
import PostService from "../api/postService";
import { borderColor } from "../styles/Styles";
import Reactions,{LikeReaction,DislikeReaction,LaughReaction,MoneybagReaction,IceReaction} from "./Reactions";



const PostActionWrapper = styled.div`
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    flex: 2.5;
    flex-wrap: wrap;
`

const PostActionItem = styled.div`
    // height: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-right: 5px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: gray;
    font-size: 0.9em;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    border-radius: 5px;
    // padding-left: 5px;

    background-color: ${props => props.currentUserReaction ? 'rgba(0, 202, 148,0.5)' : 'white'};
    
    &:hover {
        background-color: ${props => props.currentUserReaction ? 'rgba(0, 202, 148,0.5)' : borderColor};
    }

`

// const ReactionChoice = styled.div`
//     width: ${props => props.$Size ? `${props.$Size}px` : '40px'};
//     height: ${props => props.$Size ? `${props.$Size}px` : '40px'};
//     border-radius: 999px;
//     margin-right: ${props => props.$IsSelection ? '10px' : '0px'};
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     background-color: ${props => props.$IsPositive ? 'rgba(0, 202, 148,0.5)' : 'transparent'};

//     &:hover {
//         background-color: ${props => props.$IsSelection ? '10px' : '0px'};
//     }
// `

// const MoneybagReaction = ({size = 40,positive,handleReaction}) => {
//     return(
//         <ReactionChoice $IsPositive ={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'moneybag')}>
//             <div style={{fontSize:`${Math.ceil(size / 1.5)}px`,paddingBottom:'1px'}}>{'\u{1F4B0}'}</div>
//         </ReactionChoice>
//     );
// }

// const LikeReaction = ({size = 40,positive,handleReaction}) => {
//     return(
//         <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'like')}>
//             <div style={{color:positive ?'white' : 'blue',fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F44D}'}</div>
//         </ReactionChoice>
//     )
// }

// const LaughReaction = ({size=40,positive,handleReaction}) => {
//     return(
//         <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'laugh')}>
//             <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F923}'}</div>
//         </ReactionChoice>
//     );
// }

// const IceReaction = ({size=40,positive,handleReaction}) => {
//     return(
//         <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'ice')}>
//             <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F976}'}</div>
//         </ReactionChoice>
//     );
// }

// const DislikeReaction = ({size = 40,positive,handleReaction}) => {
//     return(
//         <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'dislike')}>
//             <div style={{color:positive ? 'white' : 'red',fontSize:`${Math.ceil(size / 2)}px`,paddingTop:`${Math.floor(size / 10)}px`,paddingBottom:'1px'}}>{'\u{1F44E}'}</div>
//         </ReactionChoice>
//     )
// }



// const Reactions = ({closeModal,reactions,handleReaction}) => {

//     return(
//         <>
//             <div style={{position:'absolute',bottom:'35px',left:'0px',paddingTop:'5px',paddingBottom:'5px',minWidth:'150px',display:'flex',paddingLeft:'10px',alignItems:'center',backgroundColor:'white',border:`1px solid ${borderColor}`,borderRadius:'10px',zIndex:'2'}} >
//                 <LikeReaction positive={reactions.like} handleReaction={handleReaction} />
//                 <DislikeReaction positive={reactions.dislike} handleReaction={handleReaction} />
//                 <LaughReaction positive={reactions.laugh} handleReaction={handleReaction} />
//                 <MoneybagReaction positive={reactions.moneybag} handleReaction={handleReaction} />
//                 <IceReaction positive = {reactions.ice} handleReaction={handleReaction} />
//             </div>
//             <div style={{position:'fixed',top:'0px',left:'0px',width:'100%',height:'100%',zIndex:'1'}} onClick={closeModal}>
//             </div>
//         </>
//     )
// }

function calculateReactionCount (oldReactionValue,newReactionValue,oldReactionCountValue) {
    if (oldReactionValue && !newReactionValue) {
        return Math.max(oldReactionCountValue - 1,0);
    } else if (!oldReactionValue && newReactionValue) {
        return oldReactionCountValue + 1;
    } else if (!oldReactionValue && !newReactionValue) {
        return oldReactionCountValue;
    }
}



const PostActions = ({postId,setAuthenticationState,authState,totalLikes,moneybagReactions,iceReactions,likeReactions,dislikeReactions,laughReactions,userReaction,replyCount,viewCount}) => {

    const [currentUserReaction,setCurrentUserReaction] = React.useState(userReaction ? true : false);
    const [reactions,setReactions] = React.useState({
        moneybag:false,
        ice:false,
        like:false,
        dislike:false,
        laugh:false,
    });

    const [reactionCount,setReactionCount] = React.useState({
        moneybag:moneybagReactions || 0,
        ice: iceReactions || 0,
        like:likeReactions || 0,
        dislike:dislikeReactions || 0,
        laugh:laughReactions || 0,
    });
    
    React.useEffect(() => {
        const initialReactions = Object.keys(reactions).reduce((attribute, key) => {
            attribute[key] = key === userReaction 
            return attribute;
        }, {});

        setReactions(initialReactions);
    },[]);

    async function handleReaction(event,reaction) {
        event.preventDefault();
        event.stopPropagation();
        closeReactions(event);
        
        const updatedReactions = Object.keys(reactions).reduce((attribute, key) => {
            attribute[key] = key === reaction ? !reactions[key] : false;
            return attribute;   
        }, {});

        

        const updatedReactionCount = Object.keys(reactionCount).reduce((attribute, key) => {
            attribute[key] = calculateReactionCount(reactions[key],updatedReactions[key],reactionCount[key]);
            return attribute;
        }, {});

        setReactions(updatedReactions);
        setReactionCount(updatedReactionCount);

        if (Object.values(updatedReactionCount).reduce((acc, curr) => acc + curr, 0) > 0) {
            try {
                const postData = {postId:postId,reaction:reaction};
                const {data,errorMsg} = await PostService.likePost(setAuthenticationState,authState,postData);
                if (!data) {
                    console.log("API error:", errorMsg);
                }
            } catch (error) {
                console.log("error");
            }
        } else {
            try {
                const {data,errorMsg} = await PostService.unlikePost(setAuthenticationState,authState,postId);
                if (!data) {
                    console.log("API error:", errorMsg);
                }
            } catch (error) {
                console.log("error");
            }
        }
    }

    React.useEffect(() => {
        
        function checkTrue() {
            for (const key in reactions) {
                if (reactions[key] === true) {
                    setCurrentUserReaction(true);
                    return;
                }
            }
            setCurrentUserReaction(false);
        }

        checkTrue();
        
    },[reactions]);

    const [showReactions,setShowReactions] = React.useState(false);

    function openReactions(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowReactions(true);
    }

    function closeReactions(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowReactions(false);
    }

    
    return(
        <PostActionWrapper>
            <PostActionItem $CurrentUserReaction={currentUserReaction} style={{position:'relative'}} onClick={openReactions}>
                {Object.values(reactionCount).reduce((acc, curr) => acc + curr, 0) === 0 && (
                    <div style={{fontSize:'20px',paddingTop:'2px'}}><AiOutlineLike /></div>
                )}
                {reactionCount.like > 0 && (
                    <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                        <div style={{paddingRight:'2px'}}><LikeReaction positive={reactions.like} size={30}/></div>
                        <div style={{fontSize:'12px'}}>{reactionCount.like}</div>
                    </div>
                )}
                {reactionCount.dislike > 0 && (
                    <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                        <div style={{paddingRight:reactions.dislike ? '2px' : '0px'}}><DislikeReaction positive={reactions.dislike} size={30}/></div>
                        <div style={{fontSize:'12px'}}>{reactionCount.dislike}</div>
                    </div>
                )}
                {reactionCount.laugh > 0 && (
                    <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                        <div style={{paddingRight:reactions.laugh ? '2px' : '0px'}}><LaughReaction positive={reactions.laugh} size={30}/></div>
                        <div style={{fontSize:'12px'}}>{reactionCount.laugh}</div>
                    </div>
                )}
                {reactionCount.moneybag > 0 && (
                    <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                        <div style={{paddingRight:reactions.moneybag ? '2px' : '0px'}}><MoneybagReaction positive={reactions.moneybag} size={30}/></div>
                        <div style={{fontSize:'12px'}}>{reactionCount.moneybag}</div>
                    </div>
                )}
                {reactionCount.ice > 0 && (
                    <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                        <div style={{paddingRight:reactions.moneymouth ? '2px' : '0px'}}><IceReaction positive={reactions.ice} size={30}/></div>
                        <div style={{fontSize:'12px'}}>{reactionCount.ice}</div>
                    </div>
                )}
                {showReactions && (<Reactions closeModal={closeReactions} reactions={reactions} handleReaction={handleReaction} />)}
            </PostActionItem>
            <div style={{display:'flex'}}>
                <PostActionItem style={{paddingLeft:'5px'}}>
                    <span style={{paddingRight:replyCount > 0 ? 3 : 0, paddingTop: 6, fontSize:16}}><FaRegMessage /></span>{replyCount}
                </PostActionItem>
                {/* <PostActionItem>
                    <span style={{paddingRight:3, paddingTop: 4, fontSize:18}}><AiOutlineRetweet /></span>200
                </PostActionItem> */}
                <PostActionItem style={{paddingLeft:'5px'}}>
                    <span style={{paddingRight: viewCount > 0 ? 3 : 0, paddingTop: 4, fontSize: 18}}><FaRegChartBar /></span>{viewCount}
                </PostActionItem>
            </div>
        </PostActionWrapper>
    );
}

export default PostActions;