import React from 'react';
import styled from 'styled-components';
import { AuthenticationContext } from './AuthenticationContext';
import { EventItem } from '.';
import { EventService } from '../api';
import { Loader } from '../styles/Styles';
import { MOBILE_WIDTH } from '../config';

const EventListContainer = styled.div`
    // margin-top: 1px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;
    flex: 1;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: ${props => props.$SelectedOdds ? 'row' : 'column'};   
        padding-top: 0px;
        width: ${props => props.$SelectedOdds ? null : '100%'};
        margin-bottom: ${props => props.$SelectedOdds ? '0px' : '10px'};
    }

`

const EventList = ({leagueAbbreviation,selectedOdds,eventType,isSummaryOnly,isRefreshing,setIsRefreshing}) => {
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    const [events,setEvents] = React.useState([]);
    const [loading,setLoading] = React.useState(false);
    
    const LoadEvents = async () => {
        if (!loggedInProfileData.preferredBook) return;
        try {
            setLoading(true);
            const preferredBook = loggedInProfileData.preferredBook;
            const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,leagueAbbreviation,null,preferredBook);
            if (!data) {
                console.log("API error:",errorMsg);
            } else {
                setEvents(data.events);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            if (setIsRefreshing) {
                setIsRefreshing(false);
            }
        }
    }

    React.useEffect(() => {
        setEvents([]);
        LoadEvents();

    },[leagueAbbreviation,setEvents,loggedInProfileData.preferredBook]);

    React.useEffect(() => {
        if (!isRefreshing) return;
        setEvents([]);
        LoadEvents();
    },[isRefreshing]);


    return(
        <>
            {loading && (
                <div style={{width:'100%', height:'115px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            <EventListContainer $SelectedOdds={selectedOdds}>
                {!loading && events.map((record,index) => (
                    <EventItem 
                        record={record} 
                        key={index} 
                        selectedOdds={selectedOdds} 
                        eventType={eventType} 
                        summaryOnly={isSummaryOnly} />
                ))}
            </EventListContainer>
        </>
    );   
}

export default EventList;