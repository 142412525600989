import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthenticationContext, AppLoading, Authentication, ResetPassword } from './components';

import {Home
        ,Profile
        ,ViewPost
        , Hashtag
        , Notifications
        , League
        , Leagues
        , Event
        , Team
        , Search
        , Delete
        , VipPage
        , SlateSwipe
        , Player
        , Highlights
        } from './pages';

const AppRoutes = ({setUsername,setResetCount}) => {

    const {authState,authenticationState} = React.useContext(AuthenticationContext);

    return(
        <BrowserRouter>
            <Routes>
            {authenticationState === authState.AUTHENTICATED && (
                <>
                    <Route path = "/" element={<Home />} />
                    <Route path = ":viewedUsername" element={<Profile />} />
                    <Route path = "/status/:postId" element={<ViewPost />} />
                    <Route path = "/hashtag/:hashtag" element={<Hashtag />} />
                    <Route path = "/notifications" element={<Notifications />} />
                    {/* <Route path = "/hashtag/:hashtag" element={<Home setAuthenticationState={setAuthenticationState} authState={authState} loggedInProfileData={loggedInProfileData}/>} /> */}
                    <Route path = "/leagues" element={<Leagues  /> } />
                    <Route path = "/league/:leagueAbbreviation" element={<League  /> } />
                    <Route path = "/event/:eventId" element={<Event  /> } />
                    <Route path = "/team/:teamId" element={<Team  /> } />
                    <Route path = "/search" element={<Search />} />
                    <Route path = "/delete" element={<Delete />} />
                    <Route path = "/vip" element={<VipPage />} />
                    <Route path = "/slate-swipe" element={<SlateSwipe />} />
                    <Route path = "/slate-swipe/users/:viewedUsername" element={<SlateSwipe />} />
                    <Route path = "/player/:playerId" element={<Player />} />
                    <Route path = "/highlights" element={<Highlights />} />
                    <Route path = "/highlights/:highlightId" element={<Highlights />} />
                    <Route path = "*" element={<Home />} />
                </>
            )}
            {authenticationState === authState.UNKNOWN && (
                <Route path = "*" element={<AppLoading />} />
            )}
            {(authenticationState === authState.UNAUTHENTICATED || authenticationState === authState.OAUTH_USERNAME_NEEDED) && (
                <>
                    <Route path = '/reset-password/:resetToken' element={<ResetPassword setResetCount={setResetCount}/>} />
                    <Route path = "*" element={<Authentication setUsername={setUsername} />} />
                </>
            )}
                
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;

