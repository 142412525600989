import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';
import { FaChevronCircleDown } from 'react-icons/fa';

function calcPrefix(url) {
    return url.includes('?') ? '&' : '?';
}

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}

function errorHandler(error,setAuthenticationState,authState) {
    const errorCode = error.response ? error.response.status : 500;
    let errorMessage = "";

    switch (errorCode) {
        case 400:
            errorMessage='Required field is missing from request or has invalid characters.'
            break
        case 401:
            setAuthenticationState(authState.UNAUTHENTICATED);
            errorMessage = "User not logged in or token expired";
            break
        case 403:
            errorMessage = "Unable to make change to specified account";
            break
        case 404:
            errorMessage = 'No user record found.';
            break;
        case 409:
            errorMessage = "A profile with that username already exists";
            break;
        default:
            errorMessage = 'An unexpected database error occurred.';
            break;
    }
    return {data: null, errorMsg: errorMessage};
}

const SwipeService = {
    saveSwipe: (setAuthenticationState,authState,choiceData) => {
        const SAVE_SWIPE_ENDPOINT = '/save-swipe';
        let url = `${API_BASE_URL}${SAVE_SWIPE_ENDPOINT}`;
        const headers = setAuthHeaders();
        axios.post(url,choiceData,{
            withCredentials:true,
            headers:headers,
        }).then(response => {
            // Handle successful response if needed
        })
        .catch(error => {
            // Handle error here
            const {errorMsg} = errorHandler(error, setAuthenticationState, authState);
            console.log(errorMsg);
        });
        
    },loadSwipeResults: async (setAuthenticationState,authState,username,loadedSwipeIds) => {
        const LOAD_SWIPE_RESULTS_ENDPOINT = '/load-swipe-results';
        let url = `${API_BASE_URL}${LOAD_SWIPE_RESULTS_ENDPOINT}`;
        
        if (username) {
            url += `${calcPrefix(url)}username=${username}`;
        }

        if (loadedSwipeIds) {
            url += `${calcPrefix(url)}loadedSwipeIds=${loadedSwipeIds}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadSwipeLeaderboard: async (setAuthenticationState,authState,startDate) => {
        const LOAD_SWIPE_LEADERBOARD = '/load-swipe-leaderboard';
        let url = `${API_BASE_URL}${LOAD_SWIPE_LEADERBOARD}`;

        if (startDate) {
            url += `${calcPrefix(url)}startDate=${startDate}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadUserSwipeRecord: async (setAuthenticationState,authState,viewedUsername) => {
        const LOAD_USER_SWIPE_RECORD = '/load-user-swipe-record';
        let url = `${API_BASE_URL}${LOAD_USER_SWIPE_RECORD}`;

        if (viewedUsername) {
            url += `${calcPrefix(url)}username=${viewedUsername}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadSwipeResultsV2: async (setAuthenticationState,authState,username,loadedSwipeIds,startDate) => {
        const LOAD_SWIPE_RESULTS_ENDPOINT = '/v2/load-swipe-results';
        let url = `${API_BASE_URL}${LOAD_SWIPE_RESULTS_ENDPOINT}`;
        
        if (username) {
            url += `${calcPrefix(url)}username=${username}`;
        }

        if (loadedSwipeIds) {
            url += `${calcPrefix(url)}loadedSwipeIds=${loadedSwipeIds}`;
        }

        if (startDate) {
            url += `${calcPrefix(url)}startDate=${startDate}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadSwipeLeaderboardV2: async (setAuthenticationState,authState,startDate,league) => {
        const LOAD_SWIPE_LEADERBOARD = '/v2/load-swipe-leaderboard';
        let url = `${API_BASE_URL}${LOAD_SWIPE_LEADERBOARD}`;

        if (startDate) {
            url += `${calcPrefix(url)}startDate=${startDate}`;
        }

        if (league) {
            url += `${calcPrefix(url)}leagueAbbreviation=${league}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadUserSwipeRecordV2: async (setAuthenticationState,authState,viewedUsername,startDate) => {
        const LOAD_USER_SWIPE_RECORD = '/v2/load-user-swipe-record';
        let url = `${API_BASE_URL}${LOAD_USER_SWIPE_RECORD}`;

        if (viewedUsername) {
            url += `${calcPrefix(url)}username=${viewedUsername}`;
        }

        if (startDate) {
            url += `${calcPrefix(url)}startDate=${startDate}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    }
    ,loadGolfMatchups: async (setAuthenticationState,authState) => {
        const LOAD_GOLF_MATCHUPS = '/load-golf-matchups';
        let url = `${API_BASE_URL}${LOAD_GOLF_MATCHUPS}`;

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },saveGolfMatchupSwipe: (setAuthenticationState,authState,choiceData) => {
        const SAVE_GOLF_MATCHUP_SWIPE_ENDPOINT = '/save-golf-matchup-swipe';
        let url = `${API_BASE_URL}${SAVE_GOLF_MATCHUP_SWIPE_ENDPOINT}`;
        const headers = setAuthHeaders();
        axios.post(url,choiceData,{
            withCredentials:true,
            headers:headers,
        }).then(response => {
            // Handle successful response if needed
        })
        .catch(error => {
            // Handle error here
            const {errorMsg} = errorHandler(error, setAuthenticationState, authState);
            console.log(errorMsg);
        });
    }
}

export default SwipeService;