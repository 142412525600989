import React from 'react';

import { NewPost, Feed, Layout, AuthenticationContext } from '../components';
import { UserService } from '../api';

const Delete = () => {

    const [feed,setFeed] = React.useState([]);
    const {setAuthenticationState,authState,loggedInProfileData,setLoggedInProfileData} = React.useContext(AuthenticationContext);

    const handleDelete = async () => {
        try {
            const {data,errorMsg} = await UserService.hello(setAuthenticationState,authState);
            if (!data || !errorMsg) {
                throw new Error("error!");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return(
        <Layout hideBack={true}>
            <h1>Delete Data</h1>
            <p>At BetBoard, we believe it is important that you have control over your data.</p>
            <p>By clicking the button below, we will delete your data.</p>
            <button onClick={handleDelete}>Delete Data</button>
        </Layout>
    );
}

export default Delete;
