import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}


const NotificationService = {
    viewNotifications: async (setAuthenticationState,authState,loadedNotificationIds) => {
        const VIEW_NOTIFICATIONS_ENDPOINT = "/view-notifications";

        const postData = {
            loadedNotificationIds:loadedNotificationIds.length > 0 ? loadedNotificationIds : null
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}${VIEW_NOTIFICATIONS_ENDPOINT}`,postData,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
            // return response;
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },checkNotifications: async (setAuthenticationState,authState) => {
        const CHECK_NOTIFICATIONS_ENDPOINT = "/check-notifications";

        const url = `${API_BASE_URL}${CHECK_NOTIFICATIONS_ENDPOINT}`;

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            });
            return {data:response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    }
}

export default NotificationService;