import React from "react";
import styled from 'styled-components';
import { borderColor, primaryColor, MediaContainer, Image, Video, Loader, secondaryColor, NewMediaContainer, NewImage, NewVideo } from "../../styles/Styles";
import Avatar from "../Avatar";
import PostService from '../../api/postService';
import {useDebounce} from 'use-debounce';
import UserService from "../../api/userService";
import TextEditor from '../Editor';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
// import { fileToUrl } from "../utils";
import { AuthenticationContext } from "../AuthenticationContext";
import { MOBILE_WIDTH } from "../../config";



const NewPostContainer = styled.div`
    flex: none;
    width: 100%;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    min-height: 125px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    box-sizing: border-box;
`

const AvatarWrapper = styled.div`
    padding: 15px;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`

const InputWrapper = styled.div`
    width: 100%;
    min-height: 60px;
    padding-top: 20px;
    text-align: left;
    font-size: 1.1em;

    @media (max-width: ${MOBILE_WIDTH}px) {
        min-height: auto;
    }

`

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    height: 40px;
    align-items: center;
`

const Button = styled.button`
    margin-left: 5px;
    width: 90px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 9999px;
    background-color: ${primaryColor};
    color: white;
    font-weight: bold;
`

const SearchBox = styled.div`
    min-height: 50px;
    width: 100%;
    background-color: white;
    border: 1px solid ${borderColor};
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
`

const SearchResult = styled.div`
    height: 40px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;

    &:hover {
        background-color: ${borderColor};
    }
`



const SearchContent = ({insertTag,currentMention,debouncedCurrentMention,setAuthenticationState,authState}) => {

    const [users,setUsers] = React.useState([]);

    React.useEffect(() => {
        
        const searchUsers = async () => {
            if (debouncedCurrentMention.startsWith('@')) {
                const viewedUsername = debouncedCurrentMention.substring(1);
                try {
                    const {data,errorMsg} = await UserService.searchUsers(setAuthenticationState,authState,viewedUsername);
                    if (data) {
                        setUsers(data.users);
                    } else {
                        console.log("API Error:",errorMsg);
                    }
                    
                } catch (error) {
                    console.log(error);
                }
            }
        }
        
        searchUsers();
        
    },[debouncedCurrentMention,setUsers,setAuthenticationState,authState]);

    return(
        <div style={{borderTop: `1px solid ${borderColor}`}}>
            
            
            <SearchResult onClick={(event) => insertTag(event,currentMention.substring(1))} >@{currentMention.substring(1)}</SearchResult>
            {users.map((record,index) => (
                <SearchResult onClick={(event) => insertTag(event,record.username)} >@{record.username}</SearchResult>
            ))}
            
            {/* <SearchResult onClick={(event) => insertTag(event,debouncedCurrentMention.substring(1))} >@{debouncedCurrentMention.substring(1)}</SearchResult>
            <SearchResult onClick={(event) => insertTag(event,"paul")} >@paul</SearchResult>
            <SearchResult onClick={(event) => insertTag(event,"john")} >@john</SearchResult> */}
        </div>
    );
}

const MediaButton = styled.button`
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: ${borderColor};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
`


const Comment = ({setFeed,replyToPostId,eventId,teamId,playerId,highlightId}) => {

    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    
    const [loading,setLoading] = React.useState(false);
    const [searchVisible,setSearchVisible] = React.useState(false);
    const [currentMention,setCurrentMention] = React.useState('');
    const [debouncedCurrentMention] = useDebounce(currentMention,250);
    const [postData,setPostData] = React.useState({
        postText:'',
        postTextRich:'',
        replyToPostId:replyToPostId ? replyToPostId : null,
        mentions:null,
        hashtags: null,
        eventId: eventId ? eventId : null,
        teamId: teamId ? teamId : null,
        playerId: playerId ? playerId : null,
        imageUrl:null,
        imageFile:null,
        videoUrl:null,
        videoFile:null,
        highlightId: highlightId ? highlightId : null,
    });
    const [resetText,setResetText] = React.useState(false);
    const [aspectRatio,setAspectRatio] = React.useState(null);
    
    React.useEffect(() => {
        setPostData(prevState => ({
            ...prevState,
            replyToPostId:replyToPostId,
            eventId:eventId,
            teamId:teamId,
            playerId:playerId,
            highlightId:highlightId
        }));
    },[replyToPostId,eventId,teamId,playerId,highlightId]);
    
    const handleOpen = () => {
        setSearchVisible(true);
    }

    const handleClose = () => {
        setCurrentMention('');
        setSearchVisible(false);
    }

    const handleMediaChange = (event) => {
        
        const file = event.target.files[0];
        
        if (!file) {
            console.log("No file selected");
        }

        try {
            if (file.type.startsWith('image/')) {
                
                setAspectRatio()
                setPostData(prevState => ({
                    ...prevState,
                    videoFile:null,
                    videoUrl:null,
                    imageFile:file,
                    imageUrl:URL.createObjectURL(file)
                }));
            } else if (file.type.startsWith('video/')) {
                setPostData(prevState => ({
                    ...prevState,
                    videoFile:file,
                    videoUrl:URL.createObjectURL(file),
                    imageFile:null,
                    imageUrl:null,
                }));
            } else {
                throw new Error("Invalid file type selected");
            }
        } catch (error) {
            console.log(error);
        } finally {
            event.target.value = null;
        }
    }

    const handlePost = React.useCallback(async (event) => {
        console.log('posting');
        if (loading) return;
        event.preventDefault();
        const formData = new FormData();

        formData.append('postText',postData.postText);
        formData.append('postTextRich',postData.postTextRich);
        
        // Conditionally append replies, mentions
        if (postData.replyToPostId) formData.append('replyToPostId', postData.replyToPostId);
        if (postData.eventId) formData.append('eventId', postData.eventId);
        if (postData.teamId) formData.append('teamId', postData.teamId);
        if (postData.playerId) formData.append('playerId', postData.playerId);
        if (postData.mentions) formData.append('mentions', JSON.stringify(postData.mentions)); // Assuming mentions is an array or object
        if (postData.hashtags) formData.append('hashtags', JSON.stringify(postData.hashtags));
        if (postData.highlightId) formData.append('highlightId', postData.highlightId);
        //TODO: conditionally append hashtags

        // Conditionally append files
        if (postData.imageFile) formData.append('imageFile', postData.imageFile);
        if (postData.videoFile) formData.append('videoFile', postData.videoFile);
        
        try {
            setLoading(true);
            const {data,errorMsg} = await PostService.createPost(formData,setAuthenticationState,authState);
            // TODO: when an image or video is uploaded, get the image URL back and add it to content
            if (!errorMsg) {

                const { imageFilename, videoFilename } = data;

                const newContent = {
                    postId:data.post_id,
                    username:loggedInProfileData.username,
                    postText:postData.postText,
                    postTextRich:postData.postTextRich,
                    profilePhotoFilename:loggedInProfileData.profilePhotoFilename,
                    fullName:loggedInProfileData.fullName,
                    imageFilename:imageFilename,
                    videoFilename:videoFilename,
                }

                setFeed(previousFeed => [newContent, ...previousFeed]);
                setResetText(true);
                setLoading(false)
                
            } else {
                setLoading(false);
                console.log(errorMsg);
            }
        } catch (error) {
            console.log("Unexpected error:",error);
        }
        
    },[setFeed,setAuthenticationState,authState,loggedInProfileData,postData,setResetText,loading,setLoading]);

    return(
        <NewPostContainer onClick = {handleClose}>
            <AvatarWrapper>
                <Avatar imageSource={loggedInProfileData.profilePhotoFilename}/>
            </AvatarWrapper>
            <div style={{flex:'1'}}>
                <Form onSubmit={handlePost} id='new-post' name='new-post'>
                    <InputWrapper >
                        <TextEditor setAuthenticationState={setAuthenticationState}
                                authState={authState}
                                setPostData={setPostData}
                                resetText={resetText}
                                setResetText={setResetText}
                                />
                        {/* <Editor editorState={editorState} onChange={onChange} placeholder="What's your bet?" ref={editorRef} /> */}
                    </InputWrapper>
                    {postData.imageUrl && (
                        <NewMediaContainer>
                            <NewImage src={postData.imageUrl}  />
                        </NewMediaContainer>
                    )}
                    {postData.videoUrl && (
                        <NewMediaContainer>
                            <NewVideo controls >
                                <source src={postData.videoUrl} type="video/mp4"/>
                            </NewVideo>
                        </NewMediaContainer>
                    )}
                    <ActionWrapper>
                        <MediaButton type = 'button' onClick={() => document.getElementById('mediaUpload').click()}>
                            <MdOutlineAddPhotoAlternate size={'1.3em'} color={secondaryColor} />
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/gif, video/*"
                                id="mediaUpload"
                                name="mediaUpload"
                                onChange={handleMediaChange}
                                style={{ display: 'none' }}
                            />
                        </MediaButton>
                        <Button
                            data-testid = "button-post"
                            type='submit'
                        >Board It</Button>
                    </ActionWrapper>
                    {loading && (<Loader />)}
                </Form>
            </div>
        </NewPostContainer>
    );
}

export default Comment;