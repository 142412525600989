import React from 'react';
import { AuthenticationContext } from '../../AuthenticationContext';
import { SwipeService } from '../../../api';
import { Loader } from '../../../styles/Styles';
import GolfSwipeColumn from './GolfSwipeColumn';

const GolfSwipe = ({closeModal}) => {
    
    const [loading,setLoading] = React.useState(false);
    const [matchups,setMatchups] = React.useState([]);
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);

    React.useEffect(() => {
        const LoadEvents = async () => {
            if (!loggedInProfileData.preferredBook) return;
            try {
                setLoading(true);
                const {data,errorMsg} = await SwipeService.loadGolfMatchups(setAuthenticationState,authState);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setMatchups(data.matchups);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        setMatchups([]);
        LoadEvents();

    },[setMatchups,loggedInProfileData.preferredBook]);

    return (
        <>
            {loading && (
                <div style={{width:'100%',height:'200px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {!loading && (
                <GolfSwipeColumn matchups={matchups} closeModal={closeModal}/>
                // <div>PGA Stuff</div>
            )}
        </>
    );
}

export default GolfSwipe;