import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_URL } from '../config';

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}

const FollowService = {
    followUser: async (postData,setAuthenticationState,authState) => {
        const FOLLOW_USER_ENDPOINT = '/follow-user';
        const url = `${API_BASE_URL}${FOLLOW_USER_ENDPOINT}`;
        try{
            const headers = setAuthHeaders();
            const response = await axios.post(url,postData,{
                withCredentials:true,
                headers:{
                    'X-CSRF-TOKEN':Cookies.get("csrf_access_token")
                }
            });
            
            return {data:response.data,errorMsg:null};
        } catch (error) {
            
            const errorCode = error.response ? error.response.status : 500;
            let errorMsg = "";

            switch (errorCode) {
                case 400:
                    errorMsg='Required field is missing from request or has invalid characters.';
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMsg = "User not logged in or token expired";
                    break;
                case 404:
                    errorMsg = 'No user record found.';
                    break
                default:
                    errorMsg = 'An unexpected database error occurred.';
                    break;
                }
            return {data:null,errorMsg:errorMsg};
        }
    }
}

export default FollowService;