import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}

const UserService = {
    loginUser: async (postData,setAuthenticationState,authState) => {

        const LOGIN_USER_ENDPOINT = "/login-user";

        try {
            const url = `${API_BASE_URL}${LOGIN_USER_ENDPOINT}`;
            const response = await axios.post(url,postData,{
                withCredentials:true
            });
            setAuthenticationState(authState.AUTHENTICATED);

            if (process.env.REACT_APP_IS_TEST === '1') {
                // Extract JWT token from the response headers (example assuming it is in 'Authorization' header)
                const authHeader = response.headers['authorization'];
                console.log('here with authHeader',authHeader);
                console.log('response headers',response.headers);
                console.log('response ',response);
                if (authHeader) {
                    const token = authHeader.split(' ')[1];
                    localStorage.setItem('authToken', token);
                }
            }

            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'Invalid login credentials.';
                    break;
                case 404:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'No user record found.';
                    break;
                default:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },
    signupUser: async (postData,setAuthenticationState,authState) => {

        const CREATE_USER_ENDPOINT = "/create-user";

        try {
            const response = await axios.post(`${API_BASE_URL}${CREATE_USER_ENDPOINT}`,postData,{
                withCredentials:true
            });
            
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 404:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'Unable to find to user table.';
                    break;
                case 409:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'An account with that email or username already exists.';
                    break;
                default:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },
    hello: async (setAuthenticationState,authState) => {
        const HELLO_ENDPOINT = "/hello";

        const url = `${API_BASE_URL}${HELLO_ENDPOINT}`;
        const headers = setAuthHeaders();

        try {
            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            });
            return {data:response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;

            switch (errorCode) {
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    break;
                default:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    break;
                }
            return {data:null,errorMsg:"User not logged in or token expired"};
        }
    },
    getUsers: async (setAuthenticationState,authState,viewedUsername,isCheckFollowing) => {
        const GET_USERS_ENDPOINT = "/get-users";

        try {
            let url = `${API_BASE_URL}${GET_USERS_ENDPOINT}`;

            if (viewedUsername !== null && viewedUsername !== undefined) {
                url += `?username=${viewedUsername}`
            }

            if (isCheckFollowing) {
                const prefix = url.includes('?') ? '&' : '?';
                url += `${prefix}isCheckFollowing=1`;
            }
            
            const headers = setAuthHeaders();
            const response = await axios.get(url,{
                withCredentials: true,
                headers: headers,
            });

            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.';
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },searchUsers: async (setAuthenticationState,authState,viewedUsername) => {
        const SEARCH_USERS_ENDPOINT = "/search-users";

        try {
            let url = `${API_BASE_URL}${SEARCH_USERS_ENDPOINT}`;
            
            if (viewedUsername !== null && viewedUsername !== undefined) {
                url += `?username=${viewedUsername}`
            } else {
                throw new Error("viewedUsername must not be null or undefined");
            }
            const headers = setAuthHeaders();
            const response = await axios.get(url,{
                withCredentials: true,
                headers: headers,
            });

            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.';
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },updateProfile: async (setAuthenticationState,authState,username,formData) => {
        const UPDATE_PROFILE_ENDPOINT = "/update-profile/";
        try {
            let url=`${API_BASE_URL}${UPDATE_PROFILE_ENDPOINT}${username}`;
            const headers = setAuthHeaders();
            const response = await axios.put(url,formData,{
                withCredentials: true,
                headers:headers,
            });
            return {data:response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },logout: async (setAuthenticationState,authState) => {
        const LOGOUT_ENDPOINT = "/logout"
        try{
            const url = `${API_BASE_URL}${LOGOUT_ENDPOINT}`
            const headers = setAuthHeaders();
            const response = await axios.post(url,null,{
                withCredentials: true,
                headers:headers});
            setAuthenticationState(authState.UNAUTHENTICATED);

        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },verifyGoogleToken: async (setAuthenticationState,authState,postData) => {
        const VERIFY_GOOGLE_TOKEN = "/verify-google-token";
        try {
            const url = `${API_BASE_URL}${VERIFY_GOOGLE_TOKEN}`;
            const response = await axios.post(url,postData,{
                withCredentials:true
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },setOauthUsernameFullName: async (setAuthenticationState,authState,postData) => {
        const SET_OUATH_USER = '/set-oauth-user-details';
        try {
            const url = `${API_BASE_URL}${SET_OUATH_USER}`;
            const headers = setAuthHeaders();
            const response = await axios.post(url,postData,{
                withCredentials: true,
                headers:headers});
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                case 409:
                    errorMessage = "A profile with that username already exists";
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },requestPasswordReset: async (setAuthenticationState,authState,postData) => {
        const REQUEST_PASSWORD_RESET = "/request-password-reset";
        if (!postData.email) {
            return {data: null, errorMsg: "Email address is required."};
        }
        try {
            const url = `${API_BASE_URL}${REQUEST_PASSWORD_RESET}`;
            const response = await axios.post(url,postData);
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "User not logged in or token expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                case 409:
                    errorMessage = "Please use Google Single Sign On to login.";
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    },resetPassword: async (setAuthenticationState,authState,postData,resetToken) => {
        const RESET_PASSWORD = "/reset-password";
        try {
            const url = `${API_BASE_URL}${RESET_PASSWORD}`;
            const response = await axios.post(url,postData,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${resetToken}`
                }
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = "";

            switch (errorCode) {
                case 400:
                    errorMessage='Required field is missing from request or has invalid characters.'
                    break
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = "Token is invalid or has expired";
                    break
                case 403:
                    errorMessage = "Unable to make change to specified account";
                    break
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
            }
            return {data: null, errorMsg: errorMessage};
        }
    }

}

export default UserService;