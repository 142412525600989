import React, {useMemo} from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { borderColor, primaryColor, mediumGray, MediaContainer, Image, Video, secondaryColor, lightGray, fontString } from "../styles/Styles";
import Avatar from "./Avatar";
import { Slate, Editable, withReact, DefaultElement } from 'slate-react';
import { useNavigate, useLocation } from "react-router-dom";
import { fileToUrl } from "../utils";
import PostActions from "./PostActions";
import { formatDate, scoreProfile, roundScore, roundOdds } from "../utils";
import { MOBILE_WIDTH } from "../config";
import { createEditor } from 'slate';
import { ThenNow,GolfEventThenNow,GolfPlayerThenNow } from "./ThenNow";
import { CloseButton } from "./Buttons";
import VideoPlayer from "./VideoPlayer";
import DeletePost from "./DeletePost";
import { AuthenticationContext } from "./AuthenticationContext";

import { PiFilmReel } from "react-icons/pi";


const AvatarWrapper = styled.div`
    padding-top: 15px;
    margin-right: 15px;
    height: 100%;
`

function renderLeaf({ attributes, children, leaf }) {
    let el = <>{children}</>;
  
    if (leaf.bold) {
      el = <strong>{el}</strong>;
    }
  
    if (leaf.color) {
      el = <span style={{ color: leaf.color }}>{el}</span>;
    }
  
    if (leaf.code) {
      el = <code>{el}</code>;
    }
  
    if (leaf.italic) {
      el = <em>{el}</em>;
    }
  
    if (leaf.underline) {
      el = <u>{el}</u>;
    }

    if (leaf.link) { // Check if the leaf has a link
        el = (
          <Link to={leaf.link} style={{textDecoration:'none'}} >
            {el}
          </Link>
        );
      }

    if (leaf.type === 'link') {
        
        if (leaf.link_type === 'internal') {
            el = (
                <Link {...attributes} to={leaf.url} style={{textDecoration:'none'}}>
                    {el}
                </Link>
            )
        }
        else {
            el = (
                <a {...attributes} href={leaf.url} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>
                    {el}
                </a>
            );
        }
        
    }
  
    return <span {...attributes}>{el}</span>;
  }
  
  
  function renderElement(props) {
    const { element, children, attributes } = props;
    switch (element.type) {
      case "paragraph":
        return <p {...attributes}>{children}</p>;
      case "h1":
        return <h1 {...attributes}>{children}</h1>;
      case "h2":
        return <h2 {...attributes}>{children}</h2>;
      case "h3":
        return <h3 {...attributes}>{children}</h3>;
      case "h4":
        return <h4 {...attributes}>{children}</h4>;
      case "a":
            return <a {...attributes}>{children}</a>;
      default:
        // For the default case, we delegate to Slate's default rendering. 
        return <DefaultElement {...props} />;
    }
  }

const PostContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;   
    border-bottom: 1px solid ${borderColor};
`

const ContentContainter = styled.div`
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: start;
`

const ReplyingToContainer = styled.div`
    font-size: 0.9em;
    width: 100%;
    text-align: left;
    color: ${mediumGray};
    padding-bottom: 5px;
`

const UsernameContainer = styled.div`
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const ProfileScoreBadge = styled.span`
    height: 14px;
    width: 14px;
    display: inline-flex;
    border-radius: 7px;
    background-color: ${props => props.$BackgroundColor};
    margin-top: 2px;
    margin-right: 5px;
`

const NameSpan = styled.span`
    font-weight: bold;
    margin-right: 5px;
`

const UsernameSpan = styled.span`
    margin-right: 5px;
`

const PostTextContainer = styled.span`
    padding-top: 5px;
    // padding-bottom: 5px;
    width: calc(100% - 10px);
    text-align: left;
    line-height: 1.25em;
    padding-right: 10px;
    font-size: ${props => props.$FontSize || "1em"};
`

const VideoContainer = styled.div`
    
    width: 100%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    
    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        max-height: 500px;
    }
`

const ImageContainer = styled.div`
    // display: flex;
    max-height: 300px;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
`

const ActionContainer = styled.div`
    // height: 50px;
    // border-top: 1px solid ${borderColor};
    width: 100%;
    display: flex;
    align-items: center;
`

const BetItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`

const ThenNowContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    font-size: 0.9em;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding-right: 10px;
    padding-left: 10px;
`

const Row = styled.div`
    display: flex;
    padding-bottom: 2px;
`

const ColumnOne = styled.div`
    flex: 2;
    text-align: left;
`

const ColumnTwo = styled.div`
    flex: 1;
    text-align: center;
`

const NowSpan = styled.span`
    font-size: 1.1em;
    font-weight: bold;
    font-style: italic;
    color: ${secondaryColor};
`

const BetButton = styled.button`
    border: none;
    padding: 10px;
    padding-right: 12px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const StyleDecorators = ({ text, handleClick }) => {

    const processText = (inputText,handleClick) => {
      
      const words = inputText.split(/(\s+)/); 
    
      return words.map((word, index) => {
        if (word.startsWith('@') || word.startsWith('#')) {
          return (
            <span 
                key={index} 
                style={{ fontWeight: 'bold', color: `${primaryColor}` }}
                onClick={(event) => handleClick(event,word)}
            >
            {word}
            </span>);
        } else {
          // Return the word as is for others
          return word;
        }
      });
    };
  
    return (
      <div>
        {processText(text,handleClick)}
      </div>
    );
  };

function calculateUrl(text) {
    const firstCharacter = text.substring(0,1);
    if (firstCharacter === '@') {
        return `/${text.substring(1)}`;
    } else if (firstCharacter === '#') {
        return `/hashtag/${text.substring(1)}`;
    }
}

function loopThroughChildren(children) {
    children.forEach(child => {
        if (child.color && child.color.toLowerCase() === 'blue') {
            child.type = 'link';
            child.url = child.text;
        }

        else if (child.color && child.color === primaryColor) {
            
            child.type = 'link';
            child.url = calculateUrl(child.text);
            child.link_type = 'internal';
        }

        // Check if child has children and if so, recursively call the function
        if (child.children && child.children.length > 0) {
            loopThroughChildren(child.children);
        }
    });
}

const Username = ({fullName,profileScoreLikes,username,postDatetime}) => {
    return(
    <>
        <div>
            <NameSpan>{fullName}</NameSpan>
        </div>
        <div style={{paddingTop:'2px'}}>
            <ProfileScoreBadge $BackgroundColor={scoreProfile(profileScoreLikes)}></ProfileScoreBadge>
        </div>
        <div>
            <UsernameSpan>@{username} &middot;</UsernameSpan>
        </div>
        <div>
            {formatDate(postDatetime)}
        </div>
    </>);
}

const PostText = ({postText,postTextRich,editor,postTextRichJSON,handleClick}) => {

    return(
    <> 
        {postTextRich && (
            <Slate editor={editor} initialValue={postTextRichJSON.richText}>
                <Editable 
                    readOnly 
                    renderElement={renderElement} 
                    renderLeaf={renderLeaf} 
                    style={{overflowWrap:'anywhere'}}
                    />
            </Slate>
        )}
        {!postTextRich && (
            <StyleDecorators text={postText} handleClick={handleClick}/>
        )}
    </>);
}



const Post = ({content,fontSize,setAuthenticationState,authState}) => {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [showDelete,setShowDelete] = React.useState(false);
    const [isDeleted,setIsDeleted] = React.useState(false);
    const [timeDifference,setTimeDifference] = React.useState(0);
    const {loggedInProfileData} = React.useContext(AuthenticationContext);
    const editor = useMemo(() => withReact(createEditor()), []);
    const videoRef = React.useRef(null);
    let navigate = useNavigate();
    let location = useLocation();  

    const handleUsernameClick = (event) => {
        event.stopPropagation();
        navigate(`/${content.username}`)
    }

    const handlePostClick = (event) => {
        let element = event.target;
        while (element !== null) {
            if (element.tagName === 'A') {
                // The click occurred on an <a> tag, let the default behavior proceed
                return;
            }
            element = element.parentElement;
        }
        const destination = `/status/${content.postId}`;
        navigate(destination, { replace: destination === location.pathname });
        
    }

    const handleEventClick = (event,eventId) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/event/${eventId}`);
    }

    const handlePlayerClick = (event,playerId) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/player/${playerId}`);
    }

    const handleClick = (event,word) => {

        event.stopPropagation();

        try {
            if (word.startsWith('@')) {
                navigate(`/${word.substring(1)}`);
            } else if (word.startsWith('#')) {
                navigate(`/hashtag/${word.substring(1)}`);
            } else {
                throw new Error("Unknown first character");
            }

        } catch (error) {
            console.log(error);
        }
    }

    const {
        postId
        , username
        , postDatetime
        , replyToFullName
        , videoFilename
        , profileScoreLikes
        , event_id
        , league_abbreviation
        , away_team_name
        , home_team_name
        , away_team_location
        , home_team_location
        , then_away_score
        , away_score
        , then_home_score
        , home_score
        , reply_count
        , view_count
        , event_type
        , event_name
        , event_status
        , event_datetime
        , postText
        , postTextRich
        , is_parent
        , is_child
        , is_last_child
        , moneybagReactions
        , iceReactions
        , likeReactions
        , dislikeReactions
        , laughReactions
        , golfEventThenNow
        , golfPlayerThenNow
        , parent_post_id
        , highlight_id
        , thumbnail_filename
        // , player_1_last_name
        // , player_2_last_name
        // , player_3_last_name
        // , player_4_last_name
        // , player_5_last_name
        // , player_1_price
        // , player_2_price
        // , player_3_price
        // , player_4_price
        // , player_5_price
        // , player_last_name
        // , then_price
        // , player_event_id
        // , player_event_name
    } = content;
    
    let postTextRichJSON;
    if (postTextRich) {
        postTextRichJSON = JSON.parse(postTextRich);
        postTextRichJSON.richText.forEach(item => {
            loopThroughChildren(item.children);
        });;
    }

    function handleSetFullScreen(event) {
        event.preventDefault();
        event.stopPropagation();
        setFullScreen(true);
    }

    function handleCloseFullScreen(event) {
        event.preventDefault();
        event.stopPropagation();
        setFullScreen(false);
    }

    const togglePlay = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (videoRef.current) {
          if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play();
            setIsPlaying(true);
          } else {
            videoRef.current.pause();
            setIsPlaying(false);
          }
        }
      };

    const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
    
          // Request full screen when video starts playing
          if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen().catch(err => {
              alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
          }
        }
      };
    
    function handleOpenDelete(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowDelete(true);
    }

    function handleCloseDelete(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowDelete(false);
    }

    function navigateToHighlight(event,highlightId) {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/highlights`,{state:{highlightId: highlightId,previousLocation: location.pathname}});
    }
    
    React.useState(() => {
        if (postDatetime) {
            const postDate = new Date(postDatetime);
            const currentDatetime = new Date();
            setTimeDifference(currentDatetime.getTime() - postDate.getTime());
        }
    },[postDatetime]);

    const timeThreshold = 120000; // 2 minutes

    return(
        <>
            {!isDeleted && (
                <PostContainer onClick={handlePostClick}>
                    <div style={{width: '100%',display:'flex'}}>
                        <div style={{display:'flex',marginRight:'15px',marginTop:is_child ? '0px' : '15px',flexDirection:'column',alignItems:'center'}}>
                            {is_child && (<div style={{backgroundColor:borderColor,width:'3px',height:'15px',borderRadius:'10px',marginTop:'1px',marginBottom:'1px'}}></div>)}
                            <Avatar onClick={handleUsernameClick} imageSource={fileToUrl(content.profilePhotoFilename)}/>
                            {(is_parent || is_child) && !is_last_child && (<div style={{backgroundColor:borderColor,width:'3px',borderRadius:'10px',flex:'1',marginTop:'1px',marginBottom:'1px'}}></div>)}
                        </div>
                        <ContentContainter>
                            {replyToFullName && (
                                <ReplyingToContainer>
                                    Replying to <span style={{fontWeight:'bold'}}>{replyToFullName}</span>
                                </ReplyingToContainer>
                            )}
                            <UsernameContainer onClick={handleUsernameClick}>
                                <Username handleUsernameClick={handleUsernameClick} fullName={content.fullName} profileScoreLikes={profileScoreLikes} username={username} postDatetime={postDatetime} />
                            </UsernameContainer>
                            <PostTextContainer $FontSize={fontSize} style={{overflowWrap:'anywhere'}} >
                                <PostText fontSize={fontSize} postText={postText} postTextRich={postTextRich} editor={editor} postTextRichJSON={postTextRichJSON} handleClick={handleClick} />
                            </PostTextContainer>
                            {highlight_id && location.pathname != `/highlights/${highlight_id}` && ((parent_post_id && postId === parent_post_id) || (!parent_post_id)) && (
                                // <div 
                                //     style={{marginTop:'10px',marginBottom:'10px',height:'100px',width:'100%',backgroundColor:primaryColor,fontSize:'1.2em',boxSizing:'border-box',padding:'10px',color:'white',fontWeight:'bold',display:'flex',alignItems:'center',borderRadius:'10px',cursor:'pointer'}}
                                //     onClick={(event) => {navigateToHighlight(event,highlight_id)}}
                                //     >
                                    <MediaContainer onClick={(event) => {navigateToHighlight(event,highlight_id)}}>
                                        <Image src={fileToUrl(thumbnail_filename)} />
                                        <div style={{top:'50%',left:'50%',position:'absolute',width:'50px',height:'50px'
                                            ,border:'2px solid white',borderRadius:'999px',transform:'translate(-50%,-50%)',color:'white',fontSize:'2em'
                                            ,fontWeight:'bold'
                                            ,display:'flex'
                                            ,alignItems:'center'
                                            ,justifyContent:'center'}}>
                                            <div style={{width:'32px',height:'32px'}}>
                                                <PiFilmReel />
                                            </div>
                                            
                                        </div>
                                    </MediaContainer>
                                // </div>
                            )}
                            {content.imageFilename && (
                                <>
                                <MediaContainer $FullScreen={fullScreen} onClick={handleSetFullScreen}>
                                    <Image $FullScreen={fullScreen} src={fileToUrl(content.imageFilename)} />
                                </MediaContainer>
                                {fullScreen && (
                                    <div style={{position:'fixed',top:'0px',left:'0px',width: '50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',zIndex:'101'}}>
                                        <CloseButton onClick={handleCloseFullScreen}/>
                                    </div>
                                )}
                                </>
                            )}
                            {content.videoFilename && (
                                <VideoPlayer videoFilename={videoFilename} />
                                // <MediaContainer onClick={togglePlay}>
                                //     <Video controls={true} autoPlay={false} ref={videoRef} onPlay={handlePlay}>
                                //         <source src={fileToUrl(videoFilename)} type="video/mp4"/>
                                //     </Video>
                                // </MediaContainer>
                            )}
                            <ActionContainer>
                                <PostActions postId={content.postId} setAuthenticationState={setAuthenticationState} authState={authState} totalLikes={content.totalLikes} userReaction = {content.userReaction} replyCount={reply_count} viewCount={view_count} moneybagReactions={moneybagReactions} iceReactions={iceReactions} likeReactions={likeReactions} dislikeReactions={dislikeReactions} laughReactions={laughReactions} />
                            </ActionContainer>
                            {event_id && league_abbreviation == 'NFL' && (
                                <ThenNowContainer onClick={(event) => handleEventClick(event,event_id)}>
                                    <div style={{paddingTop:'5px',paddingBottom:'5px',fontWeight:'bold'}}>
                                        {event_name}
                                    </div>
                                </ThenNowContainer>
                            )}
                            {event_id && away_team_name && (
                                <ThenNowContainer onClick={(event) => handleEventClick(event,event_id)}>
                                    <ThenNow 
                                        event_id={event_id} 
                                        event_type={event_type} 
                                        event_status={event_status} 
                                        league_abbreviation={league_abbreviation} 
                                        away_team_name={away_team_name} 
                                        home_team_name={home_team_name} 
                                        away_team_location={away_team_location} 
                                        then_away_score={then_away_score} 
                                        away_score={away_score} 
                                        home_team_location={home_team_location} 
                                        then_home_score={then_home_score} 
                                        home_score={home_score} 
                                        event_name={event_name}
                                        event_datetime={event_datetime}
                                        />
                                </ThenNowContainer> 
                            )}
                            {event_id && golfEventThenNow.then_odds.length > 0 && golfEventThenNow.now_odds.length > 0 && (
                                <ThenNowContainer onClick={(event) => handleEventClick(event,event_id)}>
                                    <GolfEventThenNow 
                                        golfEventThenNow={golfEventThenNow} 
                                        event_id={event_id}
                                        event_status={event_status}
                                        event_name={event_name}
                                        />
                                </ThenNowContainer>
                            )}
                            {event_id && Object.keys(golfPlayerThenNow.then_odds).length > 0 && Object.keys(golfPlayerThenNow.now_odds).length > 0 && (
                                <ThenNowContainer onClick={(event) => handlePlayerClick(event,golfPlayerThenNow.then_odds.player_id)}>
                                    <GolfPlayerThenNow 
                                        golfPlayerThenNow={golfPlayerThenNow}
                                        event_id={event_id}
                                        event_status={event_status}
                                        event_name={event_name}
                                        />
                                </ThenNowContainer>
                            )}
                            {timeDifference < timeThreshold && username === loggedInProfileData.username && (
                                <ThenNowContainer>
                                    <div style={{display:'flex',justifyContent:'end'}}>
                                        <div onClick={handleOpenDelete} 
                                            style={{
                                            paddingTop:'6px'
                                            ,paddingBottom:'6px'
                                            ,textDecoration:'underline'
                                            ,fontSize:'0.9em'
                                            }}>Delete</div>
                                    </div>
                                </ThenNowContainer>
                            )}
                        </ContentContainter>
                        {showDelete && (
                            <DeletePost 
                                postId={postId}
                                closeModal={handleCloseDelete}
                                setIsDeleted={setIsDeleted}
                                setShowDelete={setShowDelete}
                                />
                        )}  
                    </div>
                </PostContainer>
            )}
        </>
    );
}

export { Post};