import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { EventService } from '../api';
import styled from 'styled-components';
import { Loader, borderColor,secondaryColor, H1 } from '../styles/Styles';
import { PiBasketball } from "react-icons/pi";
import { NcaabIcon, ProGolfIcon, MlbIcon, NbaIcon, NhlIcon, NflIcon } from '../assets';
import { Layout, EventList, AuthenticationContext, LeagueList } from '../components';


const IconSelector = (league_abbreviation) => {
    switch (league_abbreviation) {
        case 'NCAAB':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NcaabIcon size={50}/>
                </div>
            );
        case 'PGA':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <ProGolfIcon size={50}/>
                </div>
            );
        case 'MLB':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <MlbIcon size={50}/>
                </div>
            );
        case 'NBA':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NbaIcon size={50}/>
                </div>
            );
        case 'NHL':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NhlIcon size={50}/>
                </div>
            );
        case 'NFL':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NflIcon size={50}/>
                </div>
            );
        case 'LIV':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <ProGolfIcon size={50}/>
                </div>
            );
        default:
            return(
                <span style={{paddingRight:'5px',fontSize:'30px',paddingTop:'5px'}}><PiBasketball /></span>
            );
    }
}

const League = ({newNotifications,setNewNotifications}) => {
    let { leagueAbbreviation } = useParams();

    const {setAuthenticationState,authState } = React.useContext(AuthenticationContext);

    let location = useLocation();
    const [record,setRecord] = React.useState(location.state ? location.state.record : {});
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {

        const LoadLeagues = async () => {
            try {
                setLoading(true);
                const {data,errorMsg} = await EventService.loadLeagues(setAuthenticationState,authState,leagueAbbreviation);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setRecord(data.leagues[0]);
                }
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        
        if (!record || Object.keys(record).length === 0) {
            LoadLeagues();
        }
    },[record,leagueAbbreviation]);

    return(
        <Layout 
            newNotifications={newNotifications}
            setNewNotifications={setNewNotifications}>      
            {(loading || Object.keys(record).length === 0) && (
                <div style={{width:'100%',height: '200px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {!loading && Object.keys(record).length > 0 && (
                <>
                    <div style={{width:'100%',textAlign:'center'}}>
                        <H1>{record.league_name}</H1>
                    </div>
                    <EventList 
                        leagueAbbreviation={leagueAbbreviation} 
                        eventType={record.event_type}
                        isSummaryOnly={true}
                        />
                </>
            )}
        </Layout>
    );
    
}

const LeagueItemContainer = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;   
    border-bottom: 1px solid ${borderColor};
    &:hover {
        background-color: ${secondaryColor};
    }
    padding-left: 10px;
`

const LeagueItem = ({record}) => {
    
    let navigate = useNavigate();
    const {league_abbreviation, league_name} = record;

    const handleClick = () => {
        navigate(`/league/${league_abbreviation}`,{state: { record }});
    }

    return(
        <LeagueItemContainer onClick={handleClick}>
            {IconSelector(league_abbreviation)}{league_name}
        </LeagueItemContainer>
    );
} 

const Leagues = () => {
    const {setAuthenticationState,authState } = React.useContext(AuthenticationContext);
    const [leagues,setLeagues] = React.useState([]);
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        const LoadLeagues = async () => {
            try {
                setLoading(true);
                const {data,errorMsg} = await EventService.loadLeagues(setAuthenticationState,authState);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setLeagues(data.leagues);
                }
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        setLeagues([]);
        LoadLeagues();

    },[setLeagues]);
    
    return(
        <Layout >
            <LeagueList />
            {/* {loading && (
                <div style={{width:'100%',height: '200px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {leagues.map((record,index) => (
                <LeagueItem key={index} record={record} />
            ))} */}
        </Layout>
    );

}

export default League;
export {Leagues};