import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MOBILE_WIDTH } from '../../config';
import { secondaryColor } from '../../styles/Styles';
import { BackButton, PrimaryButton, WhiteButton } from '../Buttons';
import { useNavigate,useLocation } from 'react-router-dom';
import MLBSwipe from './MLBSwipe';
import NBASwipe from './NBASwipe';
import NHLSwipe from './NHLSwipe';
import { GolfSwipe } from './Golf';

const SlateSwipeContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    z-index: 5;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        align-items: center;
        justify-content: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        align-items: end;
    }
`

const slideUp = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const SlateSwipeContentWrapper = styled.div`
    
    background-color: white;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 95%;
        width: 60%;
        border-radius: 20px;
        align-items: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 85%;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        animation: ${slideUp} 0.3s ease-out forwards;
    }
`

const SlateSwipeHeader = styled.div`
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
`

const IntroContainer = styled.div`
    display: grid;
    gap: 10px;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        grid-template-columns: auto auto auto auto;
        width: 60%;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        grid-template-columns: auto auto;
        padding-left: 10px;
        padding-right: 10px;
    }
    
`

const IntroGridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    aspect-ratio: 1/1;
    background-color: ${secondaryColor};
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
`

const IntroLeagueAbbreviation = styled.div`
    font-size: 36px;
`

const Intro = ({setIntro,closeModal,setPicks}) => {

    let navigate = useNavigate();
    let location = useLocation();
    

    function handleClick(event,pick) {
        event.preventDefault();
        setIntro(false);
        setPicks(pick);
    }

    function goToLeaderboard(event) {
        event.preventDefault();
        if (location.pathname === '/slate-swipe') {
            closeModal(event);
            return;
        };
        navigate('/slate-swipe');
        closeModal(event);
    }

    return(
        <IntroContainer>
            <IntroGridItem onClick={(event) => handleClick(event,'MLB')}>
                <IntroLeagueAbbreviation>MLB</IntroLeagueAbbreviation>
                <div>10 New Picks</div>
            </IntroGridItem>
            <IntroGridItem onClick={(event) => handleClick(event,'NBA')}>
                <IntroLeagueAbbreviation>NBA</IntroLeagueAbbreviation>
                <div>10 New Picks</div>
            </IntroGridItem>
            <IntroGridItem onClick={(event) => handleClick(event,'NHL')}>
                <IntroLeagueAbbreviation>NHL</IntroLeagueAbbreviation>
                <div>10 New Picks</div>
            </IntroGridItem>
            <IntroGridItem onClick={(event) => handleClick(event,'PGA')}>
                <IntroLeagueAbbreviation>PGA</IntroLeagueAbbreviation>
                <div>10 New Picks</div>
            </IntroGridItem>
        </IntroContainer>
        // <div style={{display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:'20px',paddingRight:'20px',boxSizing:'border-box',fontSize:'15px'}}>
        //     <PrimaryButton onClick={(event) => handleClick(event,'MLB')}>MLB</PrimaryButton>
        //     <PrimaryButton onClick={(event) => handleClick(event,'NBA')}>NBA</PrimaryButton>
        //     <PrimaryButton onClick={(event) => handleClick(event,'NHL')}>NHL</PrimaryButton>
        //     <PrimaryButton onClick={(event) => handleClick(event,'PGA')}>PGA</PrimaryButton>
            
        //     <WhiteButton onClick={goToLeaderboard}>View Leaderboard</WhiteButton>
        //     <div style={{width:'100%',textAlign:'left'}}>
        //         <p style={{marginBottom:'15px',marginTop:'15px'}}>Welcome to <span style={{fontWeight:'bold',color:secondaryColor,fontStyle:'italic'}}>SLATESWIPE</span> by BetBoard.</p>
        //         <p style={{marginBottom:'15px'}}>Make your picks on the upcoming slate. See the BetBoard community's consensus. Earn credibility to build your following.</p>
        //         <p style={{marginBottom:'15px'}}>Just make your picks and <span style={{fontWeight:'bold'}}>SWIPE</span> left.</p>
        //         <p style={{marginBottom:'15px'}}>You may <span style={{fontWeight:'bold'}}>SKIP</span> a pick and come back to it later. However, once you make a pick, it is <span style={{fontWeight:'bold'}}>FINAL</span>.</p>
        //         <p style={{marginBottom:'30px'}}>Your picks will contribute to <span style={{fontWeight:'bold',color:secondaryColor,fontStyle:'italic'}}>SLATESWIPE</span> Sentiment on each event page. And if you pick well, you may find your self on the <span style={{fontWeight:'bold',color:secondaryColor,fontStyle:'italic'}}>SLATESWIPE</span> leaderboard.</p>
        //     </div>
        // </div>
    )
}

const SlateSwipe = ({closeModal,leagueAbbreviation,eventId}) => {

    const [loading,setLoading] = React.useState(false);
    const [picks,setPicks] = React.useState(leagueAbbreviation ? leagueAbbreviation : null);
    const [intro,setIntro] = React.useState(leagueAbbreviation ? false : true);
    let navigate = useNavigate();
    

    return(
        <SlateSwipeContainer>
            <SlateSwipeContentWrapper>
                <div style={{paddingTop:'20px',paddingLeft:'20px',paddingRight:'20px',height:'60px',width:'100%',boxSizing:'border-box'}}>
                    <BackButton onClick={closeModal} />
                </div>
                <SlateSwipeHeader>
                    <div>BetBoard <span style={{color:secondaryColor,fontStyle:'italic',fontSize:'36px'}}>SLATESWIPE</span></div>
                    <div 
                        onClick={() => navigate('/slate-swipe')}
                        style={{
                            fontSize:16,
                            color:'blue',
                            cursor:'pointer',
                            marginTop:10,
                            marginBottom:5,
                    }}>View Leaderboard</div>
                </SlateSwipeHeader>
                {intro && (<Intro setIntro={setIntro} closeModal={closeModal} setPicks={setPicks}/>)}
                {!intro && picks && picks === 'MLB' && (<MLBSwipe closeModal={closeModal} eventId={eventId}/>)}
                {!intro && picks && picks === 'NBA' && (<NBASwipe closeModal={closeModal} eventId={eventId}/>)}
                {!intro && picks && picks === 'NHL' && (<NHLSwipe closeModal={closeModal} eventId={eventId}/>)}
                {!intro && picks && picks === 'PGA' && (<GolfSwipe closeModal={closeModal}/>)}
            </SlateSwipeContentWrapper>
            
            
        </SlateSwipeContainer> 
    );
}

export default SlateSwipe;