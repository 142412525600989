import React from "react";
import styled from 'styled-components';
import { secondaryColor } from "../../styles/Styles";
import BookLink from "../BookLink";
import { roundScore, roundOdds } from "../../utils";
import { checkDate } from "../../utils";

const Row = styled.div`
    display: flex;
    padding-bottom: 2px;
`

const ColumnOne = styled.div`
    flex: 2;
    text-align: left;
`

const ColumnTwo = styled.div`
    width: 50px;
    text-align: center;
`

const NowSpan = styled.span`
    font-weight: bold;
    color: ${secondaryColor};
`

const BetButton = styled.button`
    border: none;
    padding: 10px;
    padding-right: 12px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const ThenNow = ({
    event_id
    ,event_type
    ,event_status
    ,league_abbreviation
    ,away_team_name
    ,home_team_name
    ,away_team_location
    ,then_away_score
    ,away_score
    ,home_team_location
    ,then_home_score
    ,home_score
    ,event_name
    ,event_datetime
    // , player_1_last_name
    // , player_2_last_name
    // , player_3_last_name
    // , player_4_last_name
    // , player_5_last_name
    // , player_1_price
    // , player_2_price
    // , player_3_price
    // , player_4_price
    // , player_5_price
    , player_last_name
    , then_price
    , player_event_id
    , player_event_name
}) => {

const [showBookLink,setShowBookLink] = React.useState(false);
const [betSelection,setBetSelection] = React.useState(null);

const handleBetSelection = (event,choice) => {
    event.preventDefault();
    event.stopPropagation();
    setBetSelection(choice);
    openModal(event);
}

function openModal(event) {
    event.preventDefault();
    event.stopPropagation();
    setShowBookLink(true);
}

function closeModal(event) {
    event.preventDefault();
    event.stopPropagation();
    setShowBookLink(false);
    setBetSelection(null);
}

return(
    <>
        {event_id && event_type === 'team' && (
            <div style={{display:'flex'}}>
                <div style={{flex:'5',display:'flex',flexDirection:'column'}}>
                    <Row>
                        <ColumnOne style={{fontWeight:'bold',color:secondaryColor}}>{checkDate(event_datetime)}</ColumnOne>
                        <ColumnTwo>Then</ColumnTwo>
                        <ColumnTwo><NowSpan>Now</NowSpan></ColumnTwo>
                    </Row>
                    <Row style={{marginBottom:'5px'}}>
                        <ColumnOne>{league_abbreviation === 'MLB' ? away_team_name : away_team_location}</ColumnOne>
                        <ColumnTwo>{roundScore(then_away_score)}</ColumnTwo>
                        <ColumnTwo><NowSpan>{roundScore(away_score ? away_score : 0)}</NowSpan></ColumnTwo>
                    </Row>
                    <Row>
                        <ColumnOne>{league_abbreviation === 'MLB' ? home_team_name : home_team_location}</ColumnOne>
                        <ColumnTwo>{roundScore(then_home_score)}</ColumnTwo>
                        <ColumnTwo><NowSpan>{roundScore(home_score ? home_score : 0)}</NowSpan></ColumnTwo>
                    </Row>
                </div>
                <div style={{width:'50px',display: 'flex',alignItems:'center',justifyContent:'center'}}>
                    {event_status !== 'complete' && (
                        <BetButton onClick={(event) => openModal(event)}>
                            BET
                        </BetButton>
                    )}
                    {event_status == 'complete' && (
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'16px',color:secondaryColor,fontWeight:'bold',fontStyle:'italic'}}>
                            Final
                        </div>
                    )}
                    {showBookLink && event_status !== 'complete' && (
                        <BookLink closeModal={closeModal} betSelection={betSelection} setBetSelection={setBetSelection} handleBetSelection={handleBetSelection} eventId={event_id}/>  
                    )}
                </div>
            </div>
        )}
    </>
);
}

export default ThenNow;