import React from 'react';
import styled from 'styled-components';
import { lightGray,secondaryColor } from '../styles/Styles';
// import { AiOutlineHome } from "react-icons/ai";
// import { IoMdNotificationsOutline } from "react-icons/io";
// import { LuTrophy } from "react-icons/lu";
// import { TiDocument } from "react-icons/ti";
// import { MdOutlinePersonOutline } from "react-icons/md";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticationContext } from './AuthenticationContext';
import { MOBILE_WIDTH } from '../config';
import { HomeIcon, BellIcon, CrownIcon, TrophyIcon, SlateSwipeIcon } from '../assets';
import { PiFilmReel } from "react-icons/pi";

const HomeMenuContainer = styled.div`
    // height: 100%;
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 20px;
    
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        
        flex-direction: row;
        margin-left: 25px;
        margin-right: 25px;
        justify-content: space-between;
        padding-top: 0px;
        padding-bottom: 10px;
        box-sizing: border-box;

      }
`

const HomeMenuItemContainer = styled.div`
    
    position: relative;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 30px;
    font-size: 20px;
    font-weight: bold;
    &:hover {
        background-color: ${lightGray};
    }

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 50px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px;
        margin-bottom: 5px;
        height: 50px
      }
`

const HomeMenuIcon = styled.div`
    
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    position: relative;
`

const NotificationDot = styled.div`
      position: absolute;
      top: 0px;
      right: 7px;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background-color: red;
    //   border: 1px solid white;
`

const HomeMenuText = styled.div`
    text-align: left;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`

const DropShadow = styled.div`
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:6px;
    border-radius:3px;
    background-color:${secondaryColor};

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        display: none;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        // display: ${props => props.$Label === props.$ActivePage ? 'block' : 'none'};
        display: block;
        
    }
   
`



const IconSelector = ({label}) => {
    switch (label) {
        case "Home":
            return(<HomeIcon size={25} />);
        case "Highlights":
            return(
                <div style={{fontSize:'32px',translate:'0px 4px'}}>
                    <PiFilmReel />
                </div>
            )
        case "Notifications":
            return(<BellIcon size={25} />);
        case "Leagues":
            return(<TrophyIcon size={25} />);
        case "VIP Community":
            return(<CrownIcon size={25} />);
        case "SlateSwipe":
            return(<SlateSwipeIcon size={25} />);
        default:
            return(<HomeIcon size={25}/>);
    }
}

const HomeMenuItem = ({label,link,isNewNotification,onClick}) => {
    
    const navigate = useNavigate();
    let location = useLocation();

    const goToLink = () => {
        if (link === location.pathname) return;
        navigate(link,{state:{previousLocation: location.pathname}});
    }
    
    return(
        
            <HomeMenuItemContainer onClick={onClick? onClick : goToLink}>
                <HomeMenuIcon>
                    <IconSelector label={label} />
                    {isNewNotification && (<NotificationDot></NotificationDot>)}
                </HomeMenuIcon>
                <HomeMenuText>
                    {label}
                </HomeMenuText>
                {link === location.pathname && (<DropShadow></DropShadow>)}
            </HomeMenuItemContainer>
            
        
    );
}

const SlateSwipeContainer = styled.div`
    background-color: ${secondaryColor};
    color:white;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding-right: 30px;
    font-size: 20px;
    font-weight: bold;
    padding-left: 30px;
    &:hover {
        background-color: ${lightGray};
    }

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 50px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`

const SlateSwipeItem = ({openSlateSwipe}) => {

    return(
        <SlateSwipeContainer onClick={openSlateSwipe}>
            SLATESWIPE
        </SlateSwipeContainer>
    )

}

const HomeMenu = ({username,openSlateSwipe}) => {

    const {newNotifications,loggedInProfileData} = React.useContext(AuthenticationContext);
    const testUsers = ['paulstuart24','paulstuart22','TheGodfather','cuzbuzz'];
    return(
        <HomeMenuContainer>
            <HomeMenuItem label={"Home"} link={'/'}/>
            <HomeMenuItem label={"Highlights"} link={'/highlights'}/>
            <HomeMenuItem label={"Notifications"} link={'/notifications'} isNewNotification={newNotifications}/>
            <HomeMenuItem label={"Leagues"} link={'/leagues'} />
            {/* {testUsers.includes(loggedInProfileData.username) && (<SlateSwipeItem openSlateSwipe={openSlateSwipe}/>)} */}
            {/* {testUsers.includes(loggedInProfileData.username) && (<HomeMenuItem label={"Slate Swipe"} link={`/slate-swipe`} onClick={openSlateSwipe}/>)} */}
            <HomeMenuItem label={"SlateSwipe"} link={`/slate-swipe`} onClick={openSlateSwipe}/>
        </HomeMenuContainer>
    );
}

export default HomeMenu;