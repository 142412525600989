import './App.css';
import AppRoutes from './Routes';
import { AppLoading, Authentication,AuthenticationContext } from './components';
import UserService from './api/userService';
import { fileToUrl } from './utils';
import styled from 'styled-components';
import React from 'react';


const AppClass = styled.div`
  height: 100%;
  text-align: center;
`

function App() {

  const {authState,setAuthenticationState,setLoggedInProfileData} = React.useContext(AuthenticationContext);
  const [username,setUsername] = React.useState('');
  const [resetCount,setResetCount] = React.useState(0);

  React.useEffect(() => {

    const pingHello = async () => {
      try {
        const {data,errorMsg} = await UserService.hello(setAuthenticationState,authState);
        
        if (!errorMsg) {
          
          const {users} = data;
          
          if (users.length === 0 || users[0].username === null) {
            setAuthenticationState(authState.OAUTH_USERNAME_NEEDED);
          }
          else {
            setUsername(users[0].username);
            setAuthenticationState(authState.AUTHENTICATED);
          }
        } else {
          console.log(errorMsg);
        }        
      } catch (error) {
        console.log("Unexpected error:",error);
      }
    }

    pingHello();
    // eslint-disable-next-line
  },[resetCount]);

  const LoadUserProfile = React.useCallback(async () => {
    try {
        const {data,errorMsg} = await UserService.getUsers(setAuthenticationState,authState,username);
        
        if (errorMsg) {
            throw new Error(errorMsg);
        } else if (!data.users || data.users.length === 0) {
            throw new Error ("No user data was returned");
        } else if (data.users.length > 1) {
            throw new Error("More than 1 user profile returned");
        } else {
            
            const { 
              full_name
              , profile_photo_filename
              , cover_photo_filename
              , preferred_book
              , preferred_book_name
              , num_followers
              , num_following 
              , profile_bio
            } = data.users[0];
            
            setLoggedInProfileData(
                {
                    fullName: full_name,
                    username: username,
                    profilePhotoFilename: fileToUrl(profile_photo_filename),
                    coverPhotoFilename:fileToUrl(cover_photo_filename),
                    profileScore:null,
                    preferredBook:preferred_book,
                    preferredBookName:preferred_book_name,
                    numFollowers: num_followers,
                    numFollowing: num_following,
                    profileBio:profile_bio,
                }
            );
        }

    } catch (error) {
        console.log(error);
    }
    // eslint-disable-next-line
  },[username,setAuthenticationState,setLoggedInProfileData]);

  React.useEffect(() => {
    if (username) {
      LoadUserProfile();
    }  
      // eslint-disable-next-line
  },[username]);

    return (
      <AppRoutes 
        setUsername={setUsername}
        setResetCount={setResetCount}
        />
    );
  
}

export default App;

// if (authenticationState === authState.UNKNOWN) {
  //   return (
  //     <AppLoading />
  //   );
  // }

  // else if (authenticationState === authState.AUTHENTICATED) {
  //   return (
  //     <AppRoutes 
  //       />
  //   );
  // }

  // else if (authenticationState === authState.UNAUTHENTICATED || authenticationState === authState.OAUTH_USERNAME_NEEDED) {
  //   return (
  //     <Authentication setUsername={setUsername}/>
  //   );
  // }