import React from 'react';
import styled from 'styled-components';
import { checkDate, roundOdds, roundScore } from '../../utils';
import { borderColor, lightGray, secondaryColor } from '../../styles/Styles';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorDisplay from './ErrorDisplay';
import OddsByDisplay from './OddsBy';
import { SlateSwipeIcon } from '../../assets/icons';
import SlateSwipe from '../SlateSwipe/Swipe';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
`
const Container = styled.div`
    display: flex;
    flex-direction: row;
    // background-color: ${lightGray};
    border: 1px solid ${borderColor};
    font-size: 0.75em;
    border-radius: 10px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 1;
`

const RowOne = styled.div`
    flex: 1;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
`

const ColumnOne = styled.div`
    flex: 1.75;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    
`

const OddsColumn = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`


const RowTwo = styled.div`
    flex: 2.5;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`



const OddsLabel = styled.div`
    font-weight: bold;
`

const OddsContainer = styled.div`
    border-radius: 5px;
    background-color: ${borderColor};
    height: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`

const Normal = styled.div`
`

const Bold = styled.div`
     font-weight: bold;
`

const SlateSwipeButton  = styled.button`
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-top: 2px;
    // padding-bottom: 2px;
    // border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${secondaryColor};
    border: none;
    cursor: pointer;
`


const EventItemTeamMain = ({record,handleBetSelection}) => {

    let navigate = useNavigate();
    let location = useLocation();

    const [showSwipe,setShowSwipe] = React.useState(false);

    if (!record) {
        
        return(
            <ErrorDisplay />
        );
    }

    const { event_id
            , event_datetime
            , event_status
            , event_details
            , league_abbreviation
        } = record;

    if (!event_details || event_details.length === 0) {
        return(
            <ErrorDisplay />
        );
    }

    const {
        book_name
        , away_team_location
        , home_team_location
        , away_team_name
        , home_team_name
        , away_moneyline_price
        , home_moneyline_price
        , away_spread_price
        , home_spread_price
        , away_spread_point
        , home_spread_point
        , away_total_price
        , home_total_price
        , away_total_point
        , home_total_point
        , away_score
        , home_score
    } = event_details[0];

    const handleClick = (event) => {
        event.preventDefault();
        const destination = `/event/${event_id}`;
        if (destination === location.pathname) return;
        navigate(destination);
    }

    function handleOpenSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSwipe(true);
    }

    function handleCloseSwipe(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowSwipe(false);
    }
    
    return(
        <Wrapper>
            <Container>
                <Content onClick={handleClick}>
                    <RowOne>
                        <ColumnOne>
                            <div style={{fontWeight:'bold',color:secondaryColor}}>
                                {checkDate(event_datetime,event_status)}
                            </div>
                        </ColumnOne>
                        <OddsColumn>
                            <OddsLabel >ML</OddsLabel>
                        </OddsColumn>
                        <OddsColumn >
                            <OddsLabel >Spread</OddsLabel>
                        </OddsColumn>
                        <OddsColumn >
                            <OddsLabel >Total</OddsLabel>
                        </OddsColumn>
                    </RowOne>
                    <RowTwo >
                        <ColumnOne>
                            <div style={{paddingRight:'10px',fontWeight:'bold'}}>{['MLB','NBA','NHL'].includes(league_abbreviation) ? away_team_name : away_team_location}</div>
                            <div style={{fontSize:'1.2em',fontWeight:'bold'}}>{roundScore(away_score)}</div>
                        </ColumnOne>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,0) : null}>
                                <Bold>{roundOdds(away_moneyline_price,0)}</Bold>  
                            </OddsContainer>
                        </OddsColumn>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,1) : null}>
                                <Normal>{roundOdds(away_spread_point,1)}</Normal>
                                <Bold>{roundOdds(away_spread_price,0)}</Bold>
                            </OddsContainer>
                        </OddsColumn>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,2) : null}>
                                <Normal>{roundOdds(away_total_point,1,'over')}</Normal>
                                <Bold>{roundOdds(away_total_price,0)}</Bold>
                            </OddsContainer>
                        </OddsColumn>
                    </RowTwo>
                    <RowTwo >
                        <ColumnOne>
                            <div style={{paddingRight:'10px',fontWeight:'bold'}}>{['MLB','NBA','NHL'].includes(league_abbreviation) ? home_team_name : home_team_location}</div>
                            <div style={{fontSize:'1.2em',fontWeight:'bold'}}>{roundScore(home_score)}</div>
                        </ColumnOne>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,3) : null}>
                                <Bold>{roundOdds(home_moneyline_price,0)}</Bold>
                            </OddsContainer>
                        </OddsColumn>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,4) : null}>
                                <Normal>{roundOdds(home_spread_point,1)}</Normal>
                                <Bold>{roundOdds(home_spread_price,0)}</Bold>
                            </OddsContainer >
                        </OddsColumn>
                        <OddsColumn >
                            <OddsContainer onClick={handleBetSelection ? (event) => handleBetSelection(event,5): null}>
                                <Normal>{roundOdds(home_total_point,1,'under')}</Normal>
                                <Bold>{roundOdds(home_total_price,0)}</Bold>
                            </OddsContainer>
                        </OddsColumn>
                    </RowTwo>
                </Content>
                <div style={{display:'flex',alignItems:'center',paddingRight:'10px'}}>
                    <SlateSwipeButton onClick={handleOpenSwipe}>
                        <SlateSwipeIcon 
                            size={16}
                            color={'white'}
                            />
                    </SlateSwipeButton>
                </div>
            </Container>
            <OddsByDisplay bookName={book_name} />
            {showSwipe && (
                <SlateSwipe 
                    closeModal={handleCloseSwipe}
                    leagueAbbreviation={league_abbreviation}
                    eventId={event_id}
                />
            )}
        </Wrapper>
    );
}

export default EventItemTeamMain;