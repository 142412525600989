import React from 'react';
import { MediaContainer, Video } from '../styles/Styles';
import { fileToUrl } from '../utils';
import { FaPlay } from "react-icons/fa";


const VideoPlayer = ({ videoFilename }) => {

    const videoRef = React.useRef(null);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    React.useEffect(() => {
        const video = videoRef.current;
    
        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        const handleEnded = () => setIsPlaying(false);
        const handleFullscreenChange = () => {
            if (document.fullscreenElement === video) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
                video.pause();
            }
          };
    
        if (video) {
          video.addEventListener('play', handlePlay);
          video.addEventListener('pause', handlePause);
          video.addEventListener('ended', handleEnded);
        }

        document.addEventListener('fullscreenchange', handleFullscreenChange);
    
        // Cleanup function to remove event listeners
        return () => {
          if (video) {
            video.removeEventListener('play', handlePlay);
            video.removeEventListener('pause', handlePause);
            video.removeEventListener('ended', handleEnded);
          }

          document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
      }, []);

    const togglePlay = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (videoRef.current) {
          if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play();
            setIsPlaying(true);
          } else {
            videoRef.current.pause();
            setIsPlaying(false);
          }
        }
      };
    
    const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
    
          // Request full screen when video starts playing
          if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen().catch(err => {
              alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
          }
        }
      };


    function handleVideoClick(event,isFullScreen) {
      if (isFullScreen) {
        event.stopPropagation();
      }
    }
    return(
        <MediaContainer onClick={togglePlay}>
            <Video onClick={(event) => handleVideoClick(event,isFullScreen)} controls={isFullScreen} autoPlay={false} ref={videoRef} onPlay={handlePlay} $FullScreen={isFullScreen}>
                <source src={fileToUrl(videoFilename)} type="video/mp4"/>
            </Video>
            {!isFullScreen && !isPlaying && (
                <div onClick={togglePlay} style={{top:'50%',left:'50%',position:'absolute',width:'50px',height:'50px'
                    ,border:'2px solid white',borderRadius:'999px',transform:'translate(-50%,-50%)',color:'white',fontSize:'2em'
                    ,fontWeight:'bold'
                    ,display:'flex'
                    ,alignItems:'center'
                    ,justifyContent:'center'}}>
                    <div style={{width:'32px',height:'32px',paddingLeft:'7px'}}>
                        <FaPlay />
                    </div>
                    
                </div>
            )}
        </MediaContainer>
        
    );
}

export default VideoPlayer