import { HighlightFeed } from "../components/Highlights";
import { useLocation } from "react-router-dom";
import {Layout} from "../components";

const Highlights = () => {

    let location = useLocation();
    const previousLocation = location.state ? location.state.previousLocation : "/";
    const highlightId = location.state ? location.state.highlightId : null;

    return (
        <Layout isHighlight={true}>
            <HighlightFeed highlightId={highlightId} previousLocation={previousLocation} />
        </Layout>
    );
}

export default Highlights;