import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';
import { FaChevronCircleDown } from 'react-icons/fa';

function calcPrefix(url) {
    return url.includes('?') ? '&' : '?';
}

function errorHandler(error,setAuthenticationState,authState) {
    const errorCode = error.response ? error.response.status : 500;
    let errorMessage = "";

    switch (errorCode) {
        case 400:
            errorMessage='Required field is missing from request or has invalid characters.'
            break
        case 401:
            setAuthenticationState(authState.UNAUTHENTICATED);
            errorMessage = "User not logged in or token expired";
            break
        case 403:
            errorMessage = "Unable to make change to specified account";
            break
        case 404:
            errorMessage = 'No user record found.';
            break;
        case 409:
            errorMessage = "A profile with that username already exists";
            break;
        default:
            errorMessage = 'An unexpected database error occurred.';
            break;
    }
    return {data: null, errorMsg: errorMessage};
}

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}


const EventService = {
    
    loadLeagues: async (setAuthenticationState,authState,leagueAbbreviation) => {
        const LOAD_LEAGUES = '/load-leagues';
        let url = `${API_BASE_URL}${LOAD_LEAGUES}`;

        if (leagueAbbreviation) {
            url += `${calcPrefix(url)}leagueAbbreviation=${leagueAbbreviation}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },loadEvents: async (setAuthenticationState,authState,leagueAbbreviation,eventId,preferredBook,teamId,startDate,endDate,isSwipe) => {
         
        const LOAD_EVENTS = '/load-events';
        let url = `${API_BASE_URL}${LOAD_EVENTS}`;

        if (leagueAbbreviation !== null && leagueAbbreviation !== undefined) {
            url += `${calcPrefix(url)}leagueAbbreviation=${leagueAbbreviation}`;
        }
        
        if (eventId !== null && eventId !== undefined) {
            url += `${calcPrefix(url)}eventId=${eventId}`;
        }

        if (preferredBook !== null & preferredBook !== undefined) {
            url += `${calcPrefix(url)}preferredBook=${preferredBook}`;
        }

        if (teamId !== null & teamId !== undefined) {
            url += `${calcPrefix(url)}teamId=${teamId}`
        }

        if (startDate) {
            url += `${calcPrefix(url)}startDate=${startDate}`;
        }

        if (endDate) {
            url += `${calcPrefix(url)}endDate=${endDate}`;
        }

        if (isSwipe) {
            url += `${calcPrefix(url)}isSwipe=${isSwipe}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },loadTeams: async (setAuthenticationState,authState,teamId) => {
        const LOAD_TEAMS_ENDPOINT = '/load-teams';
        let url = `${API_BASE_URL}${LOAD_TEAMS_ENDPOINT}`;
        if (!teamId) {
            return;
        } else {
            url += `${calcPrefix(url)}teamId=${teamId}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },loadPlayers: async (setAuthenticationState,authState,playerId) => {
        const LOAD_PLAYER_ENDPOINT = '/load-players';
        let url = `${API_BASE_URL}${LOAD_PLAYER_ENDPOINT}`;
        url += `${calcPrefix(url)}playerId=${playerId}`;

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },getEventType: async (setAuthenticationState,authState,leagueAbbreviation) => {
        const GET_EVENT_TYPE_ENDPOINT = '/get-event-type';
        if (!leagueAbbreviation) {
            throw new Error("league abbreviation cannot be blank");
        }
        let url = `${API_BASE_URL}${GET_EVENT_TYPE_ENDPOINT}`
        url += `${calcPrefix(url)}leagueAbbreviation=${leagueAbbreviation}`;
        try{
            const headers = setAuthHeaders();
            const response = await axios.get(url,{withCredentials:true,headers:headers});
            return {data: response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    }
}

export default EventService;