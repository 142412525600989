import React from 'react';
import BookLink from "../BookLink";
import styled from 'styled-components';
import { secondaryColor } from '../../styles/Styles';
import { roundOdds,golfScore } from '../../utils';

const BetButton = styled.button`
    border: none;
    padding: 10px;
    padding-right: 12px;
    border-radius: 999px;
    background-color: ${secondaryColor};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const GolfEventThenNow = ({
    golfEventThenNow
    ,event_id    
    ,event_status
    ,event_name
}) => {

    const [showBookLink,setShowBookLink] = React.useState(false);
    const [betSelection,setBetSelection] = React.useState(null);

    const handleBetSelection = (event,choice) => {
        event.preventDefault();
        event.stopPropagation();
        setBetSelection(choice);
        openModal(event);
    }

    function openModal(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowBookLink(true);
    }

    function closeModal(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowBookLink(false);
        setBetSelection(null);
    }
    
    return(
        <div style={{display:'flex'}}>
            <div style={{flex:'1'}}>
                <div style={{width:'100%',paddingBottom:'5px',fontSize:'1.1em',fontWeight:'bold'}}>
                    {event_name}
                </div>
                <div style={{width:'100%',textAlign:'left',display:'flex'}}>
                    <div style={{flex:'2',display:'flex',flexDirection:'column',alignItems:'center',boxSizing:'border-box',paddingRight:'10px'}}>
                        <div style={{fontSize:'1.1em'}}>THEN</div>
                        {golfEventThenNow.then_odds.map((golfEvent,index) => (
                            <div key={index} style={{display:'flex',width:'100%',fontSize:'0.8em'}}>
                                <div style={{flex:'1.35'}}>{golfEvent.player_last_name}</div>
                                <div style={{width:'17px',textAlign:'center'}}>{golfEvent.then_current_position === 'WAITING' ? '-' : golfEvent.then_current_position}</div>
                                <div style={{width:'17px',textAlign:'center'}}>{!golfEvent.then_thru ? '-' : golfScore(golfEvent.then_score)}</div>
                                {/* <div style={{flex:'1.25'}}>{roundOdds(golfEvent.then_price,0)}</div> */}
                            </div>
                        ))}
                    </div>
                    <div style={{flex:'2',display:'flex',flexDirection:'column',alignItems:'center',boxSizing:'border-box',paddingRight:'10px'}}>
                        <div style={{fontSize:'1.1em'}}>NOW</div>
                        {golfEventThenNow.now_odds.map((golfEvent,index) => (
                            <div key={index} style={{display:'flex',width:'100%',fontSize:'0.8em'}}>
                                <div style={{flex:'1.35'}}>{golfEvent.player_last_name}</div>
                                <div style={{width:'17px',textAlign:'center'}}>{golfEvent.current_position === 'WAITING' ? '-' : golfEvent.current_position}</div>
                                <div style={{width:'17px',textAlign:'center'}}>{!golfEvent.thru ? '-' : golfScore(golfEvent.score)}</div>
                                {/* <div style={{flex:'1.25'}}>{roundOdds(golfEvent.price,0)}</div> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{width:'50px',display: 'flex',alignItems:'center',justifyContent:'center'}}>
                {event_status !== 'complete' && (
                    <BetButton onClick={(event) => openModal(event)}>
                        BET
                    </BetButton>
                )}
                {event_status == 'complete' && (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'16px',color:secondaryColor,fontWeight:'bold',fontStyle:'italic'}}>
                        Final
                    </div>
                )}
                {showBookLink && event_status !== 'complete' && (
                    <BookLink closeModal={closeModal} betSelection={betSelection} setBetSelection={setBetSelection} handleBetSelection={handleBetSelection} eventId={event_id}/>  
                )}
            </div>
        </div>
    );
}

export default GolfEventThenNow;