import React from "react";
import styled from 'styled-components';
import { AuthenticationContext } from "../../components";
import { SwipeService } from "../../api";
import { useNavigate } from "react-router-dom";
import { borderColor, lightGray, secondaryColor, H1 } from "../../styles/Styles";
import DateFilter from "./DateFilter";
import { formatDateToSeconds } from "../../utils";
import { Loader } from "../../styles/Styles";
import Filters from "./Filters";


const LeaderboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8em;
`

const TitleRow = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
    background-color: ${lightGray};
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
`

const Row = styled.div`
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${borderColor};
    align-items: center;
    cursor: pointer;
    background-color: ${props => props.$IsCurrentUser ? secondaryColor : 'white'};
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
`

const filterOptions = {
    WEEK: "Week",
    MONTH: "Month",
    ALL: "All"
};

const leagues = {
    MLB: "MLB",
    NBA: "NBA",
    PGA: "PGA",
    ALL: "ALL"
}


const DropShadow = styled.div`
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:6px;
    border-radius:3px;
    background-color:${secondaryColor};
    display: ${props => props.$IsSelected ? 'block' : 'none'};
`





function getStartDate(choice) {

    let startDate;
    const currentDate = new Date();

    switch(choice) {
        case filterOptions.WEEK:
            startDate = new Date(currentDate)
            startDate.setDate(currentDate.getDate() - 7);
            return formatDateToSeconds(startDate);
        case filterOptions.MONTH:
            startDate = new Date(currentDate)
            startDate.setDate(currentDate.getDate() - 30);
            return formatDateToSeconds(startDate);
        case filterOptions.ALL:
            return null;
        default:
            return null;
    }
}

const Leaderboard = () => {

    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    const [results,setResults] = React.useState([]);
    const [filterChoice,setFilterChoice] = React.useState(filterOptions.ALL);
    const [loading,setLoading] = React.useState(false);
    const [league,setLeague] = React.useState(null);
    let navigate = useNavigate();

    React.useEffect(() => {
        
        async function loadResults() {
            try {
                setLoading(true);
                const startDate = getStartDate(filterChoice);
                const {data,errorMsg} = await SwipeService.loadSwipeLeaderboardV2(setAuthenticationState,authState,startDate,league === leagues.ALL ? null : league);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    setResults(data.results);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        
        loadResults();
        
    },[filterChoice,league]);

    function handleClick(event,username) {
        event.preventDefault();
        navigate(`/slate-swipe/users/${username}`);
    }

    return(
        <LeaderboardContainer>
            <div style={{textAlign:'center'}}>
                <H1>Leaderboard</H1>
            </div>
            <Filters 
                league={league} 
                setLeague={setLeague} 
                filterChoice={filterChoice} 
                setFilterChoice={setFilterChoice} 
                filterOptions={filterOptions}
                leagues={leagues}
                />
            {loading && (
                <div style={{width: '100%',height:'100px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {!loading && (
                <>
                    <TitleRow>
                        <div style={{flex:'2.25'}}></div>
                        <div style={{flex:'2'}}></div>
                        <div style={{flex:'1',textAlign:'center'}}>Return</div>
                        <div style={{flex:'1',textAlign:'center'}}>Record</div>
                    </TitleRow>
                    {results.map((leaderRecord,index) => (
                        <Row key={index} onClick={(event) => handleClick(event,leaderRecord.username)} $IsCurrentUser={loggedInProfileData.username === leaderRecord.username}>
                            <div style={{flex:'2.25'}}>{leaderRecord.full_name}</div>
                            <div style={{flex:'2'}}>{leaderRecord.username}</div>
                            <div style={{flex:'1',display:'flex',justifyContent:'center'}}>{leaderRecord.total_return}</div>
                            <div style={{flex:'1',display:'flex',justifyContent:'center'}}>{leaderRecord.record}</div>
                        </Row>
                    ))}
                </>
            )}

        </LeaderboardContainer>
    )
}

export default Leaderboard;