import React from 'react';
import styled, { keyframes } from 'styled-components';
import Avatar from '../Avatar';
import { AuthenticationContext } from '../AuthenticationContext';
import { lightGray, borderColor,mediumGray,navbarHeight,secondaryColor } from '../../styles/Styles';
import { MOBILE_WIDTH } from '../../config';
import { CloseButton } from '../Buttons';
import Comment from './Comment';
import Highlight from './Highlight';
import { useParams } from 'react-router-dom';

const NewPostDisplayContainer = styled.div`
    flex: none;
    width: 100%;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    box-sizing: border-box;
    align-items: center;
`

const AvatarWrapper = styled.div`
    margin-left: 4px;
    margin-right: 15px;
`

const textContainerStyle = {
    backgroundColor:lightGray
    ,borderRadius:'999px'
    ,height:'50px'
    ,display:'flex'
    ,alignItems:'center'
}

const textWrapperStyle = {
    paddingLeft:'15px',
    color: mediumGray
}

const NewPostDisplay = ({setNewPostVisible}) => {

    const {loggedInProfileData} = React.useContext(AuthenticationContext);

    const handleOpenNewPost = (event) => {
        event.preventDefault();
        setNewPostVisible(true);
    }

    return (
        <NewPostDisplayContainer>
            <AvatarWrapper>
                <Avatar imageSource={loggedInProfileData.profilePhotoFilename}/>
            </AvatarWrapper>
            <div style={{flex:1,marginRight:'15px'}} onClick={handleOpenNewPost}>
                <div style={textContainerStyle}>
                    <div style={textWrapperStyle}>What's your bet?</div>
                </div>
            </div>
        </NewPostDisplayContainer>
    )
}

const NewPostContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    z-index: 5;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        align-items: center;
        justify-content: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        align-items: end;
    }
`

const slideUp = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const NewPostContentWrapper = styled.div`
    
    background-color: white;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 95%;
        width: 60%;
        border-radius: 20px;
        align-items: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 100%;
        max-height: 100%;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        animation: ${slideUp} 0.3s ease-out forwards;
    }
`

const ToggleContainer = styled.div`
    height: 40px;
    width: 200px;
    border-radius: 999px;
    border: 1px solid ${borderColor};
    margin-right: 10px;
    display: flex;
`

const ToggleOption = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.$selected ? 'white' : 'black'};
    background-color: ${props => props.$selected ? secondaryColor : 'white'};
    border-radius: 999px;
    font-weight: bold;
`

const Toggle = ({toggleOption,setToggleOption,toggleOptions}) => {

    function handleSetToggleOption(event,option) {
        event.preventDefault();
        setToggleOption(option);
    }

    return (
        <ToggleContainer>
            <ToggleOption 
                $selected={toggleOption===toggleOptions.POST}
                onClick={(event) => handleSetToggleOption(event,toggleOptions.POST)}
                >Post
            </ToggleOption>
            <ToggleOption 
                $selected={toggleOption===toggleOptions.HIGHLIGHT}
                onClick={(event) => handleSetToggleOption(event,toggleOptions.HIGHLIGHT)}
                >Highlight
            </ToggleOption>
        </ToggleContainer>
    )

}

const topBarContainer = {
    display:'flex',
    width:'100%',
    height:'60px',
    minHeight:'60px',
    alignItems:'center',
    justifyContent:'space-between',
}

const TopBar = ({setNewPostVisible,toggleOption,setToggleOption,toggleOptions}) => {

    function handleClose(event) {
        event.preventDefault();
        setNewPostVisible(false);
    }

    return (
        <div style={topBarContainer}>
            <div style={{paddingLeft:'10px'}}><CloseButton onClick={handleClose} color={'black'} /></div>
            <div>
                <Toggle 
                    toggleOption={toggleOption}
                    setToggleOption={setToggleOption}
                    toggleOptions={toggleOptions}
                    />
            </div>
        </div>
    )
}


const NewPost = ({replyToPostId,setFeed}) => {

    let { eventId } = useParams();

    const toggleOptions = {
        POST:0,
        HIGHLIGHT:1,
    }

    const [newPostVisible,setNewPostVisible] = React.useState(false);
    const [toggleOption,setToggleOption] = React.useState(toggleOptions.POST);
    
    

    return (
        <>
        <NewPostDisplay setNewPostVisible={setNewPostVisible}/>
        {newPostVisible && (
            <NewPostContainer>
                <NewPostContentWrapper>
                    <>
                        <TopBar 
                            setNewPostVisible={setNewPostVisible}
                            toggleOption={toggleOption}
                            setToggleOption={setToggleOption}
                            toggleOptions={toggleOptions}/>
                        {toggleOption===toggleOptions.POST && (
                            <div style={{paddingLeft:'5px',paddingRight:'5px',boxSizing:'border-box'}}>
                                <Comment setFeed={setFeed}/>
                            </div>
                        )}
                        {toggleOption===toggleOptions.HIGHLIGHT && (
                            <Highlight eventId={eventId} setNewPostVisible={setNewPostVisible} />
                        )}
                    </>
                </NewPostContentWrapper>
            </NewPostContainer>
        )}
        </>
    )
}

export default NewPost;