import styled from 'styled-components';
import React from 'react';
import { fileToUrl } from '../../utils';
import { AuthenticationContext } from '../AuthenticationContext';
import { MOBILE_WIDTH } from '../../config';
import Signup from './Signup';
import Login from './Login';
import Choice from './Choice';
import OauthDetails from './OauthDetails';

const PageContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 125px;
`

const Img = styled.img`
    width: 600px;
    height: auto;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 400px;
      }
`

const betboard_banner_logo = fileToUrl('betboard_banner_logo.png');

const Authentication = ({setUsername}) => {

    const {authenticationState,setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    const display = {
        CHOICE: 0,
        LOGIN: 1,
        SIGNUP: 2,
        OAUTH_DETAILS: 3,
    }

    const [displayState,setDisplayState] = React.useState(authenticationState == authState.UNAUTHENTICATED ? display.CHOICE : display.OAUTH_DETAILS);
    
    // console.log("betboard_banner_logo===>",betboard_banner_logo);

    return(
        <PageContainer>
            <div style={styles.imageContainer}>
                <img style={styles.image} src={betboard_banner_logo} />
            </div>
            {displayState === display.CHOICE && (
                <Choice display = {display} setDisplayState={setDisplayState} setUsername={setUsername}/>
            )}
            {displayState === display.LOGIN && (
                <Login display={display} setDisplayState={setDisplayState} setAuthenticationState={setAuthenticationState} authState={authState} setUsername={setUsername} />
            )}
            {displayState === display.SIGNUP && (
                <Signup display={display} setDisplayState={setDisplayState} setAuthenticationState={setAuthenticationState} authState={authState} setUsername={setUsername} />
            )}
            {displayState === display.OAUTH_DETAILS && (
                <OauthDetails setAuthenticationState={setAuthenticationState} authState={authState} setUsername={setUsername} />
            )}
        </PageContainer>
    );
}

export default Authentication;
export {Login, Signup};

const styles = {
    imageContainer: {
        width:window.innerWidth > MOBILE_WIDTH ? 600 : '100%',
        // height:200,
        // backgroundColor:'orange',
    },image: {
        width:'100%',
        height:'auto',
    }
}