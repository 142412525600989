import React from 'react';
import styled from 'styled-components';
import SwipeEvent from './SwipeEvent';
import { Loader, borderColor, secondaryColor } from '../../styles/Styles';
import { MOBILE_WIDTH } from '../../config';
import { SwipeService } from '../../api';
import { AuthenticationContext } from '../AuthenticationContext';
import { PrimaryButton } from '../Buttons';
import { useNavigate } from 'react-router-dom';

const SwipeColumnContainer = styled.div`
    // flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    scroll-snap-type: x mandatory;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 400px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
    }
`

const SwipeEventColumn = styled.div`
    flex-shrink: 0;
    width: 100%;
    scroll-snap-align: start;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 10px;
`

const NextButton = styled.button`
    border-radius: 999px;
    border: none;
    background-color: ${secondaryColor};
    color: white;
    cursor: pointer;
    padding: 10px 15px 10px 15px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`

const defaultChoice = {
    over:null,
    under:null,
    away:null,
    home: null,
}

function getChoiceData(choice,eventRecord) {

    const {event_details} = eventRecord;
    const choiceData = {
        eventId:eventRecord.event_id,
        moneylineTeamId:null,
        moneylinePrice:null,
        spreadTeamId:null,
        spreadChoice: null,
        spreadPrice: null,
        spreadPoint: null,
        totalChoice: null,
        totalPrice: null,
        totalPoint: null,
    }

    if (choice.over) {
        choiceData.totalChoice = 'over';
        choiceData.totalPrice = event_details[0].away_total_price;
        choiceData.totalPoint = event_details[0].away_total_point;
    } else if (choice.under) {
        choiceData.totalChoice = 'under';
        choiceData.totalPrice = event_details[0].home_total_price;
        choiceData.totalPoint = event_details[0].home_total_point;
    }

    if (choice.away) {
        if (['MLB','NHL'].includes(eventRecord.league_abbreviation)) {
            choiceData.moneylineTeamId = event_details[0].away_team_id;
            choiceData.moneylinePrice = event_details[0].away_moneyline_price;
        } else {
            choiceData.spreadChoice = 'away';
            choiceData.spreadPrice = event_details[0].away_spread_price;
            choiceData.spreadPoint = event_details[0].away_spread_point;
            choiceData.spreadTeamId = event_details[0].away_team_id;
        }
        
        
    } else if (choice.home) {
        if (['MLB','NHL'].includes(eventRecord.league_abbreviation)) {
            choiceData.moneylineTeamId = event_details[0].home_team_id;
            choiceData.moneylinePrice = event_details[0].home_moneyline_price;
        } else {
            choiceData.spreadChoice = 'home';
            choiceData.spreadPrice = event_details[0].home_spread_price;
            choiceData.spreadPoint = event_details[0].home_spread_point;
            choiceData.spreadTeamId = event_details[0].home_team_id;
        }
    }

    return choiceData;
}

const TheEnd = ({closeModal}) => {

    let navigate = useNavigate();

    function goToLeaderboard(event) {
        event.preventDefault();
        navigate('/slate-swipe');
        closeModal(event);
    }

    return(
    <div style={{width: '100%',height:'100%',fontSize:'20px',textAlign:'center',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <p style={{marginBottom:'30px'}}>That's the end of the upcoming slate. Check back for more events soon.</p>
        <PrimaryButton onClick={goToLeaderboard} style={{fontWeight:'bold',fontSize: '0.9em'}}>View Leaderboard</PrimaryButton>
    </div>
    );
}

const SwipeColumn = ({events,closeModal}) => {

    const [startX, setStartX] = React.useState(null);
    const [eventNumber,setEventNumber] = React.useState(0);
    const containerRef = React.useRef(null);
    const [currentChoice,setCurrentChoice] = React.useState(defaultChoice);
    const [choices,setChoices] = React.useState([]);
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    const handleChoice = React.useCallback((event,choice) => {
        event.preventDefault();
        event.stopPropagation();

        switch (choice) {
            case 0:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[eventNumber].over = true;
                    updatedChoices[eventNumber].under = null;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,over:true,under:null,}));
                break;
            case 1:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[eventNumber].away = true;
                    updatedChoices[eventNumber].home = null;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,away:true,home:null,}));
                break;
            case 2:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[eventNumber].under = true;
                    updatedChoices[eventNumber].over = null;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,under:true,over:null,}));
                break;
            case 3:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[eventNumber].home = true;
                    updatedChoices[eventNumber].away = null;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,home:true,away:null,}));
                break;
            default:
                setCurrentChoice(defaultChoice);
                return;
        }
    },[eventNumber]);

    React.useEffect(() => {
        const newChoices = events.map(event => ({ ...defaultChoice }));
        setChoices(newChoices);
    }, [events]);

    const scrollRight = (event) => {
        if (eventNumber >= events.length) return;
        event.stopPropagation();
        setEventNumber(prevPosition => prevPosition + 1);
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: containerRef.current.clientWidth,
                behavior: 'smooth'
            });
          }
    }

    React.useEffect(() => {
        function handleTouchMove(e) {
            e.preventDefault();
            if (e.touches.length !== 1 || startX === null) return;
        }
    
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
    
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startX]);

    function saveAndScroll(e) {
        e.preventDefault();
        const choiceData = getChoiceData(choices[eventNumber],events[eventNumber]);
        SwipeService.saveSwipe(setAuthenticationState,authState,choiceData);
        scrollRight(e);
    }

    function handleTouchEnd(e) {
        if (eventNumber >= events.length) {
            setStartX(null);
            return;
        }
        if (startX !== null) {
            const deltaX = e.changedTouches[0].clientX - startX;
            if (deltaX < -100) {
                saveAndScroll(e);
            }
            setStartX(null);
        }
    }

    return(
        <>
            
            {(!events.length > 0 || !events[0] || choices.length === 0) && (
                <div style={{width:'100%',height:'100px'}}>
                    <TheEnd closeModal={closeModal} />
                </div>
            )}
            {events.length > 0 && events[0] && choices.length > 0 && (
                <>
                <SwipeColumnContainer
                    onTouchStart={e => setStartX(e.touches[0].clientX)}
                    onTouchEnd={e => handleTouchEnd(e)}
                    ref={containerRef}
                    >
                    {events.map((eventRecord,index) => (
                        <SwipeEventColumn key={index}>
                            <SwipeEvent eventRecord={eventRecord} currentChoice={choices[index]} handleChoice={handleChoice}/>
                        </SwipeEventColumn>
                    ))}
                    <SwipeEventColumn>
                        <TheEnd closeModal={closeModal} />
                    </SwipeEventColumn>
                    
                </SwipeColumnContainer>
                {eventNumber < events.length && (
                    <NextButton onClick={saveAndScroll}>Next</NextButton>
                )}
                
                </>
            )}
        </>
    );
}

export default SwipeColumn;