import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MOBILE_WIDTH } from '../config';
import { BackButton } from './Buttons';
import {EventItem} from '.';
import { borderColor, secondaryColor, mediumGray } from '../styles/Styles';
import { roundOdds } from '../utils';
import { AuthenticationContext } from './AuthenticationContext';
import { AnalyticsService, EventService } from '../api';
import { Loader } from '../styles/Styles';

const BookLinkContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    z-index: 4;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        align-items: center;
        justify-content: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        align-items: end;
    }
`

// Define the keyframe animation
const slideUp = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const BookLinkContentWrapper = styled.div`
    
    background-color: white;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 85%;
        width: 60%;
        border-radius: 20px;
        align-items: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 85%;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        animation: ${slideUp} 0.3s ease-out forwards;
    }
`

const BookLinkContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 70%;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const ChangeButton = styled.button`
    
    padding: 10px;
    border-radius: 999px;
    border: 1px solid ${borderColor};
    color: ${mediumGray};
    font-size: 14px;
    background-color: white;
    cursor: pointer;

`

function updateSelectedBet(choice) {
    
    if (choice === null || choice === undefined) return {side:null,market:null};
    
    
    let betChoice = {};
    
    switch (choice) {
        case 0:
            betChoice = {side:'away',market:'moneyline'};
            break;
        case 1:
            betChoice = {side:'away',market:'spread'};
            break;
        case 2:
            betChoice = {side:'away',market:'total'};
            break;
        case 3:
            betChoice = {side:'home',market:'moneyline'};
            break;
        case 4:
            betChoice = {side:'home',market:'spread'};
            break;
        case 5:
            betChoice = {side:'home',market:'total'};
            break;
        default:
            betChoice = {side:null,market:null};
            break;
    }

    return betChoice;
}

const BookLink = ({closeModal,record: initialRecord,betSelection,setBetSelection,handleBetSelection,eventId}) => {

    const { setAuthenticationState,authState,loggedInProfileData } = React.useContext(AuthenticationContext);
    const [record,setRecord] = React.useState(initialRecord || null);
    const [event_type, setEventType] = React.useState(null);
    const [event_details, setEventDetails] = React.useState(null);
    const [internal_name,setInternalName] = React.useState(null);
    const [selectedBet,setSelectedBet] = React.useState({side: null,market:null,});

    React.useEffect(() => {
        const getEvents = async (eventId) => {
            try { 
                const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,null,eventId,loggedInProfileData.preferredBook);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    setRecord(data.events[0]);
                    setEventType(data.events[0].event_type);
                    setEventDetails(data.events[0].event_details);
                    setInternalName(data.events[0].event_details[0].internal_name);
                }
            } catch (error) {
                console.log(error)
            }
        }
        
        if (!initialRecord && eventId) {
            getEvents(eventId);
        } else {
            setEventType(initialRecord.event_type);
            setEventDetails(initialRecord.event_details);
            setInternalName(initialRecord.event_details[0].internal_name);
        }
    },[initialRecord,eventId]);
    
    React.useEffect(() => {
        if (betSelection !== null && betSelection !== undefined) {
            const {side,market} = updateSelectedBet(betSelection);
            setSelectedBet({side:side,market:market});
            // setBetSelection(null);
        }
    },[betSelection]);

    React.useEffect(() => {
        const postData = {activityDescription:'open_book_link'};
        AnalyticsService.logActivity(setAuthenticationState,authState,postData);
    },[setAuthenticationState,authState]);

    function resetBet(event) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedBet({side:null,market:null});
    }

    function handleClickBookLink(event) {
        event.stopPropagation();
        const postData = {activityDescription:`click_book_link: ${internal_name}`};
        AnalyticsService.logActivity(setAuthenticationState,authState,postData)
    }

    if (!record || !event_type) {
        return(
            <div style={{width:'100%',height:'100px'}}>
                <Loader />
            </div>
        )
    }

    return(
        <BookLinkContainer>
            <BookLinkContentWrapper>
                <div style={{paddingTop:'20px',paddingLeft:'20px',height:'60px',width:'100%',boxSizing:'border-box'}}>
                    <BackButton onClick={closeModal} />
                </div>
                
                <BookLinkContent >
                    
                    {!selectedBet.side && (
                        <>
                            <div style={{textAlign:'left',width: '100%',marginBottom:'20px'}}>
                                <h2 style={{marginTop:'10px',marginBottom:'10px'}}>Choose Your Bet</h2>
                                <p style={{fontSize:'0.9em'}}>Select a tile to chose your bet to place at your preferred sportsbook.</p>
                            </div>
                            <EventItem record={record} eventType={event_type} handleBetSelection={handleBetSelection}/>
                        </>
                    )}
                    {selectedBet.side && (
                        <>
                            <div style={{textAlign:'left',width: '100%'}}>
                                <h2 style={{marginTop:'10px',marginBottom:'10px'}}>Bet Your Preferred Book</h2>
                                <p style={{fontSize:'0.9em'}}>Follow the links below to place your bet at your preferred sportsbook. You can change your preferred book at any time in your profile.</p>
                            </div>
                            <DisplaySelectedBet selectedBet={selectedBet} eventDetails={event_details[0]} onClick={handleClickBookLink} />
                            <div style={{width: '100%',marginTop:'10px'}}>
                                <ChangeButton onClick={resetBet}>Change my Bet</ChangeButton>
                            </div>
                        </>
                    )}
                    <div style={{width:'100%',marginTop: '20px',color:mediumGray}}>
                        <h3>Disclaimer</h3>
                        <p style={{fontSize:'0.9em'}}><span style={{fontWeight:'bold'}}>You are using a test version of BetBoard. The links on this page will not yet take you to the exact or correct event on your preferred sportsbook's app.</span> Over time, we will improve this.</p>
                        <p style={{fontSize:'0.9em',marginTop: '10px'}}>You are fully responsible for your own betting activity. You are fully responsible for verifying the accuracy, relevance, and any other characteristics any and all information you obtain from the BetBoard platform.</p>
                        <p style={{fontSize:'0.9em',marginTop: '10px'}}>BetBoard, Inc. does not warrant that any data including odds and scores or any other information on BetBoard including user-generated content is timely, accurate, or relvant.</p>
                        <p style={{fontSize:'0.9em',marginTop: '10px',marginBottom:'20px'}}>By using this feature, you agree to BetBoard's <a href="https://terms.betboard.us/terms-of-service.html" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://terms.betboard.us/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                </BookLinkContent>
                
            </BookLinkContentWrapper>
        </BookLinkContainer>
    )
}


function queueBet(selectedBet,eventDetails) {
    if (!selectedBet.side || !selectedBet.market) return;

    if (selectedBet.side === 'home') {
        switch (selectedBet.market) {
            case 'moneyline':
                return {team:eventDetails.home_team_location
                    ,price:roundOdds(eventDetails.home_moneyline_price,0)
                    ,point: null};
            case 'spread':
                return {team:eventDetails.home_team_location
                    ,price:roundOdds(eventDetails.home_spread_price,0)
                    ,point: roundOdds(eventDetails.home_spread_point,1)};
            case 'total':
                return {team:eventDetails.home_team_location
                        ,price:roundOdds(eventDetails.home_total_price,0)
                        ,point: roundOdds(eventDetails.home_total_point,0,'under')};
            default:
                return {team:null,price: null,point: null};
        }
    } else if (selectedBet.side === 'away') {
        switch (selectedBet.market) {
            case 'moneyline':
                return {team:eventDetails.away_team_location
                    ,price:roundOdds(eventDetails.away_moneyline_price,0),point: null};
            case 'spread':
                return {team:eventDetails.away_team_location
                    ,price:roundOdds(eventDetails.away_spread_price,0)
                    ,point: roundOdds(eventDetails.away_spread_point,1)};
            case 'total':
                return {team:eventDetails.away_team_location
                    ,price:roundOdds(eventDetails.away_total_price,0)
                    ,point: roundOdds(eventDetails.away_total_point,0,'over')};
            default:
                return {team:null,price: null, point: null};
        }
    } 
}

function getBetDisplayName(bet) {
    switch (bet) {
        case 'moneyline':
            return 'Moneyline';
        case 'spread':
            return 'Point Spread';
        case 'total':
            return 'Total';
        default:
            return null;
    }
}

const SelectedBetContainer = styled.div`
    margin-top: 20px;
    border-top: 3px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
`

const TeamNameContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
`

const PriceContainer = styled.div`
    font-size: 16px;
`

const PointContainer = styled.div`
    font-size: 16px;
`

const BetButton = styled.button`
    border: none;
    background-color: ${secondaryColor};
    border-radius: 999px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 95px;

    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    font-style: italic;
`

const DisplaySelectedBet = ({selectedBet,eventDetails,onClick}) => {
    
    const { loggedInProfileData } = React.useContext(AuthenticationContext);
    const [price,setPrice] = React.useState(null);
    const [point,setPoint] = React.useState(null);
    const [team,setTeam]   = React.useState(null);
    const {book_base_url} = eventDetails;

    React.useEffect(() => {
        
        if (selectedBet.side && selectedBet.market) {
            const {team,price,point} = queueBet(selectedBet,eventDetails);
            setPrice(price);
            setPoint(point);
            setTeam(team);
        }
        
    },[selectedBet,eventDetails]);
    
    return(
        
        <SelectedBetContainer>
            <div>
                <TeamNameContainer>
                    {team}
                </TeamNameContainer>
                <div style={{fontSize:'14px'}}>
                    {getBetDisplayName(selectedBet.market)}
                </div>
            </div>
            <div style={{display:'flex'}}>
                <div style={{display:'flex',paddingRight:'10px',alignItems:'center'}}>
                    <span style={{marginRight:'10px'}}>
                        <PointContainer>
                            {point}
                        </PointContainer>
                    </span>
                    <PriceContainer>
                        ({price})
                    </PriceContainer>
                </div>
                <div>
                    <a href={book_base_url} target="_blank" rel="noopener noreferrer">
                        <BetButton onClick={onClick}>
                            Bet On {loggedInProfileData.preferredBookName}
                        </BetButton>
                    </a>
                </div>
            </div>
        </SelectedBetContainer>
    );
}

export default BookLink;

