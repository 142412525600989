import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Avatar, EventItem, AuthenticationContext, NewPost, Feed } from '../components';
import styled from 'styled-components';
import { EventService } from '../api';
import { fileToUrl, formatEventDate, formatDateToSeconds,roundScore } from '../utils';
import { Loader, borderColor, mediumGray } from '../styles/Styles';

const TeamHeaderContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    padding-bottom: 15px;
    // padding-left: 10px;
    // padding-right: 10px;
    border-bottom: 1px solid ${borderColor};
    width: 100%;
`

const TeamLogoContainer = styled.div`
    flex: flex-grow;
`

const TeamName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
    
`

const TeamEventContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 2px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
`

const TeamEventItem = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid ${borderColor};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 0.9em;
`

const TeamEventItemColumn = styled.div`
    
`

function WinOrLoss({eventId,teamId,homeTeamId,awayScore,homeScore,eventStatus}) {
    let isHome;

    if (!awayScore || !homeScore || eventStatus !== 'complete') {
        return(
            <>
                <div style={{width:'20px',display:'inline-block'}}></div>
            </>
        );   
    }

    if (Number(teamId) === Number(homeTeamId)) {
        isHome = true;
    } else {
        isHome = false;
    }
    
    if ((isHome && Number(homeScore) > Number(awayScore)) || (!isHome && Number(awayScore) > Number(homeScore))) {
        return(
            <>
                <div style={{color:'green',fontWeight:'bold',width:'20px',display:'inline-block'}}>W</div>
            </>
        );
    } else {
        return(
            <>
                <div style={{color:'red',fontWeight:'bold',width:'20px',display:'inline-block'}}>L</div>
            </>
        );
    }
}

function atOrVs(teamId,homeTeamId,awayTeamLocation,HomeTeamLocation) {
    
    let av;
    let opponent;

    if (Number(teamId) === Number(homeTeamId)) {
        av = 'vs.';
        opponent = awayTeamLocation;
    } else {
        av = '@';
        opponent = HomeTeamLocation;
    }

    const description = `${av} ${opponent}`
    return description;
}

const TeamEvents = ({teamId,teamEvents}) => {
    const navigate = useNavigate();

    const handleClick = (event,eventId) => {
        event.preventDefault();
        navigate(`/event/${eventId}`);
    }

    return(
        // recent
        <TeamEventContainer>
            {teamEvents.map((teamEvent,index) => (
                <TeamEventItem key={index} onClick={(event) => handleClick(event,teamEvent.event_id)}>
                    <TeamEventItemColumn style={{paddingLeft:'10px',color:mediumGray,fontSize: '0.9em',flex:'1'}}>{formatEventDate(teamEvent.event_datetime,teamEvent.event_status === 'complete' ? true : false)}</TeamEventItemColumn>
                        <div style={{paddingLeft:'20px',color:mediumGray,fontSize: '0.9em',width:'100px'}}>
                                <WinOrLoss 
                                    eventId={teamEvent.event_id}
                                    teamId={teamId}
                                    homeTeamId={teamEvent.home_team_id} 
                                    awayScore={teamEvent.event_details[0].away_score}
                                    homeScore={teamEvent.event_details[0].home_score}    
                                    eventStatus={teamEvent.event_status} />{roundScore(teamEvent.event_details[0].away_score)} - {roundScore(teamEvent.event_details[0].home_score)}
                        </div>
                    <TeamEventItemColumn style={{flex:'2'}}>
                        {atOrVs(teamId,teamEvent.home_team_id
                            ,teamEvent.league_abbreviation === 'MLB' ? teamEvent.event_details[0].away_team_name : teamEvent.event_details[0].away_team_location
                            ,teamEvent.league_abbreviation === 'MLB' ? teamEvent.event_details[0].home_team_name : teamEvent.event_details[0].home_team_location
                            )}
                    </TeamEventItemColumn>
                </TeamEventItem>
            ))}
        </TeamEventContainer>
    )

}



const TeamHeader = ({teamId,teamData}) => {

    const {league_id,team_name,team_location,team_abbreviation,team_photo_filename,team_home_photo_filename} = teamData[0];
    const [loading,setLoading] = React.useState(false);
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    const [teamEvents,setTeamEvents] = React.useState([]);

    React.useEffect(() => {
        const LoadEvents = async () => {
            try {
                setLoading(true);
                const preferredBook = loggedInProfileData.preferredBook;
                const currentDate = new Date();
                const startDate = new Date(currentDate)
                startDate.setMonth(currentDate.getMonth() - 6);
                const endDate = new Date(currentDate)
                endDate.setDate(currentDate.getDate() + 4);
                const startDateFormatted = formatDateToSeconds(startDate);
                const endDateFormatted = formatDateToSeconds(endDate);
                const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,null,null,preferredBook,teamId,startDateFormatted,endDateFormatted);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    setTeamEvents(data.events);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

        LoadEvents();
    },[teamId,loggedInProfileData.preferredBook,setAuthenticationState,authState]);
    
    return(
        <TeamHeaderContainer>
            <TeamLogoContainer>
                <Avatar imageSource={fileToUrl(team_home_photo_filename)} imageSize={'75px'} isTeamLogo={true} />
            </TeamLogoContainer>
            <TeamName>
                {league_id === 3 ? team_name : team_location}
            </TeamName>
            {!loading && (<TeamEvents teamId={teamId} teamEvents={teamEvents} />)}
            {loading && (
                <div style={{width: '100%',height: '50px',position:'relative'}}>
                    <Loader />
                </div>
            )}
        </TeamHeaderContainer>
    )
    
}

const Team = () => {
    
    let { teamId } = useParams();
    const [feed,setFeed] = React.useState([]);

    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [teamData,setTeamData] = React.useState(null);
    const [loading,setLoading] = React.useState(false);

    

    React.useEffect(() => {
        const LoadTeamEvents = async () => {
            try {
                setLoading(true);
                const {data,errorMsg} = await EventService.loadTeams(setAuthenticationState,authState,teamId);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    setTeamData(data.teams);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        LoadTeamEvents();
    },[teamId]);

    if (!teamData) {
        return(
            <div style={{height:'200px',width:'100%',position:'relative'}}>
                <Loader />
            </div>
        )
    }

    return (
        <Layout >
            <TeamHeader teamId={teamId} teamData={teamData} />
            <NewPost setFeed={setFeed} teamId={teamId}/>
            <Feed feed={feed} setFeed={setFeed} teamId={teamId} />
            <div style={{height:'100px',width:'100%'}}></div>
        </Layout>
    );
}

export default Team;