import React from 'react';
import styled from 'styled-components';
import UserService from '../api/userService';
import Avatar from './Avatar';
import { PrimaryButton } from './Buttons';
import EditProfile from './EditProfile';
import FollowService from '../api/followService';
import { borderColor } from '../styles/Styles';
import { AuthenticationContext } from './AuthenticationContext';
import { fileToUrl, scoreProfile } from '../utils';
import { MOBILE_WIDTH } from '../config';
import { SlateSwipeIcon } from '../assets';
import { useNavigate } from 'react-router-dom';
import { Slate } from 'slate-react';
import SlateSwipe from './SlateSwipe';

const ProfileHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-top: 10px;
    }
`

const CoverImageContainer = styled.div`
    width: 100%;
    height: 200px;
    background-color: ${borderColor};
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.$imageUrl});
`

const AvatarWrapper = styled.div`
    position: absolute;
    top: 140px;
    left: 10px;
`

const ProfileInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ProfileScoreBadge = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: ${props => props.$BackgroundColor};
    margin-left: 7px;
`

const FollowContainer = styled.div`
    height: 70px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10px;
`

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 10px;
`

const NameWrapper = styled.div`
    font-size: 1.2em;
    font-weight: bold;
`

const UsernameWrapper = styled.div`
`

const FollowersWrapper = styled.div`
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 10px;
`

const StatisticWrapper = styled.div`
    width: 150px;
    display: flex;
    justify-content: start;
    align-items: center;
`

const Number = styled.span`
    font-weight: bold;
    padding-right: 5px;
`

const FollowComponent = ({viewedUsername,setAuthenticationState,authState,displayComponent,setCurrentDisplayComponent,setProfileData}) => {
    const {setLoggedInProfileData} = React.useContext(AuthenticationContext);
    const handleFollow = React.useCallback(async (event) => {
        event.preventDefault();
        
        try {
            const postData = {
                username:viewedUsername,   
            }
            const {errorMsg} = await FollowService.followUser(postData,setAuthenticationState,authState);
            if (!errorMsg) {
                setCurrentDisplayComponent(displayComponent.FOLLOWING);
                setProfileData(prevProfileData => ({
                    ...prevProfileData,
                    numFollowers:prevProfileData.numFollowers + 1,
                }));
                setLoggedInProfileData(prevloggedInProfileData => ({
                    ...prevloggedInProfileData,
                    numFollowing:prevloggedInProfileData.numFollowing + 1,
                }));
            } else {
                console.log(errorMsg);
            }
        } catch (error) {
            console.log("Unexpected error:",error);
        }
        
    },[viewedUsername,setAuthenticationState,authState,setCurrentDisplayComponent,displayComponent]);

    return(
        <>
            <PrimaryButton onClick={handleFollow}>Follow</PrimaryButton>
        </>
    );
}

const CoverImage = ({profilePhotoFilename,imageUrl}) => {

    const [fullScreen,setFullScreen] = React.useState(false);

    function handleSetFullScreen(event) {
        event.preventDefault();
        event.stopPropagation();
        setFullScreen(true);
    }

    function handleCloseFullScreen(event) {
        event.preventDefault();
        event.stopPropagation();
        setFullScreen(false);
    }

    return(
        <CoverImageContainer $imageUrl={imageUrl}>
            {profilePhotoFilename && (
            <AvatarWrapper onClick={handleSetFullScreen}>
                <Avatar  imageSize={"120px"} imageSource={profilePhotoFilename} fullScreen={fullScreen} closeFullScreen={handleCloseFullScreen} />
            </AvatarWrapper>)}
        </CoverImageContainer>
    );
}

const EditProfileContainer = ({showModal,handleOpenModal,closeModal,fullName,setAuthenticationState,authState,username,profileData,setProfileData}) => {
    return(
        <>
            <PrimaryButton onClick={handleOpenModal}>Edit Profile</PrimaryButton>
                    {showModal && (
                        <EditProfile 
                            closeModal={closeModal} 
                            fullName = {fullName} 
                            setAuthenticationState={setAuthenticationState} 
                            authState={authState}
                            username={username}
                            profileData={profileData}
                            setProfileData={setProfileData}
                            />)}
        </>
    );
}


const ProfileHeader = ({viewedUsername}) => {

    const {setAuthenticationState,authState,loggedInProfileData,setLoggedInProfileData} = React.useContext(AuthenticationContext);
    let navigate = useNavigate();

    const displayComponent = {
        EDIT_MODAL:0,
        FOLLOW: 1,
        FOLLOWING:2,
    }

    const [currentDisplayComponent,setCurrentDisplayComponent] = React.useState();
    
    React.useState(() =>{
        if (viewedUsername !== undefined) {
            if (viewedUsername === loggedInProfileData.username) {
                setCurrentDisplayComponent(displayComponent.EDIT_MODAL);
            } else {
                setCurrentDisplayComponent(displayComponent.FOLLOW);
            }
        } 
    },[viewedUsername,loggedInProfileData,displayComponent,setCurrentDisplayComponent]);
    
    const [profileData,setProfileData] = React.useState(
        {
            fullName:'',
            username:'',
            profilePhotoFilename:'',
            coverPhotoFilename:'',
            profileScore:null,
            numFollowers:0,
            numFollowing:0,
            profileBio:'',
            preferredBook:'',
            preferredBookName:'',
        }
    );

    const [showModal,setShowModal] = React.useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'auto';
    }

    const LoadUserProfile = React.useCallback(async () => {
        try {
            if (viewedUsername === null || viewedUsername === undefined) {
                throw new Error(`current username is ${viewedUsername}`);
            }
            const isCheckFollowing = true;
            const {data,errorMsg} = await UserService.getUsers(setAuthenticationState,authState,viewedUsername,isCheckFollowing);
            if (errorMsg) {
                throw new Error(errorMsg);
            } else if (!data.users || data.users.length === 0) {
                throw new Error ("No user data was returned");
            } else if (data.users.length > 1) {
                throw new Error("More than 1 user profile returned");
            } else {
                
                const { full_name, username, profile_photo_filename, cover_photo_filename, followee_username, profileScoreLikes, num_followers, num_following, profile_bio } = data.users[0];
                
                setProfileData(
                    {
                        fullName: full_name,
                        username: username,
                        profilePhotoFilename: fileToUrl(profile_photo_filename),
                        coverPhotoFilename:fileToUrl(cover_photo_filename),
                        profileScore: scoreProfile(profileScoreLikes),
                        numFollowers: num_followers,
                        numFollowing: num_following,
                        profileBio: profile_bio,
                    }
                );

                if (followee_username) {
                    setCurrentDisplayComponent(displayComponent.FOLLOWING);
                } else {
                    setCurrentDisplayComponent(displayComponent.FOLLOW);
                }
            }

        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    },[setAuthenticationState,authState,viewedUsername,setCurrentDisplayComponent]);

    React.useEffect(() => {
        
        if (viewedUsername === loggedInProfileData.username) {
            setProfileData({
                fullName:loggedInProfileData.fullName,
                username:loggedInProfileData.username,
                profilePhotoFilename:loggedInProfileData.profilePhotoFilename,
                coverPhotoFilename:loggedInProfileData.coverPhotoFilename,
                profileScore:null,
                numFollowers: loggedInProfileData.numFollowers,
                numFollowing: loggedInProfileData.numFollowing,
                profileBio: loggedInProfileData.profileBio,
            });
            setCurrentDisplayComponent(displayComponent.EDIT_MODAL);
        } else {
            LoadUserProfile();
        }

        
    },[viewedUsername, setProfileData,loggedInProfileData,setCurrentDisplayComponent,LoadUserProfile,displayComponent.EDIT_MODAL]);

    function goToSlateswipe(event) {
        event.preventDefault();
        navigate(`/slate-swipe/users/${viewedUsername}`);
    }

    return(
        <ProfileHeaderContainer>
            {profileData.profilePhotoFilename !== '' && (<CoverImage profilePhotoFilename={profileData.profilePhotoFilename} imageUrl={profileData.coverPhotoFilename} />)}
            <ProfileInfoContainer>
                <FollowContainer>
                    {currentDisplayComponent === displayComponent.EDIT_MODAL && (
                        <EditProfileContainer 
                            showModal={showModal}
                            handleOpenModal={handleOpenModal}
                            closeModal={handleCloseModal} 
                            fullName = {profileData.fullName} 
                            setAuthenticationState={setAuthenticationState} 
                            authState={authState}
                            username={viewedUsername}
                            profileData={loggedInProfileData}
                            setProfileData={setLoggedInProfileData}
                        />
                    )}
                    {currentDisplayComponent === displayComponent.FOLLOW && (
                        <FollowComponent 
                            viewedUsername={viewedUsername}
                            setAuthenticationState={setAuthenticationState}
                            authState={authState}
                            displayComponent={displayComponent}
                            setCurrentDisplayComponent={setCurrentDisplayComponent}
                            setProfileData={setProfileData}
                        />
                    )}
                    {currentDisplayComponent === displayComponent.FOLLOWING && (
                        <PrimaryButton>Following</PrimaryButton>
                    )}
                </FollowContainer>
                <NameContainer>
                    <NameWrapper>{profileData.fullName}</NameWrapper>
                    <UsernameWrapper>@{profileData.username}</UsernameWrapper>
                    <div onClick={goToSlateswipe} style={{cursor:'pointer',paddingTop:'5px',paddingBottom:'5px',height:'30px',display:'flex',alignItems:'center'}}><SlateSwipeIcon size={'25px'} /> <span style={{fontWeight:'bold',paddingLeft:'5px'}}>SLATESWIPE</span></div>
                    <div style={{paddingTop:'5px',paddingBottom:'5px'}}>{profileData.profileBio}</div>
                    <ProfileScoreBadge $BackgroundColor={profileData.profileScore}></ProfileScoreBadge>
                    
                </NameContainer>
                <FollowersWrapper>
                    <StatisticWrapper>
                        <Number>{profileData.numFollowing}</Number>Following
                    </StatisticWrapper>
                    <StatisticWrapper>
                        <Number>{profileData.numFollowers}</Number>Followers
                    </StatisticWrapper>                    
                </FollowersWrapper>
            </ProfileInfoContainer>
        </ProfileHeaderContainer>
    );
}

export default ProfileHeader;