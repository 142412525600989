import styled, {keyframes} from 'styled-components';
import BeatLoader from 'react-spinners/BeatLoader';
import { MOBILE_WIDTH } from '../config';
const borderColor = '#dfe6e9';
const primaryColor = '#00795A';
const secondaryColor = '#00ca95';
const navbarHeight = "55px";
const lightGray = "#ecf0f1";
const mediumGray = "#636e72";


const fontString = `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`;

const NewMediaContainer = styled.div`
    width: calc(100% - 10px);
    // background-color: orange;
    flex: 1;
    max-height: 500px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    // border-radius: 15px;
`

const NewImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
    max-height: 500px;
    border-radius: 15px;
`

const NewVideo = styled.video`
    height: 100%;
    width: 100%;
    max-height: 500px;
    border-radius: 15px;
    object-fit: contain;
    object-position: center center;
`

const MediaContainer = styled.div`
    margin-top: ${props => props.$FullScreen ? '0' : '10px'};
    flex: 1;
    display: flex; /* Or use 'display: grid;' */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* This ensures the image does not overflow its container */
    width: 100%; /* Full width of the parent element */
    
    border-radius: ${props => props.$FullScreen ? '0' : '15px'};
    border: ${props => props.$FullScreen ? 'none' : '1px solid' + borderColor};
    box-sizing: border-box;
    position: ${props => props.$FullScreen ? 'fixed' : 'relative'};
    top: ${props => props.$FullScreen ? '0' : 'auto'};
    left: ${props => props.$FullScreen ? '0' : 'auto'};
    max-height: ${props => props.$FullScreen ? '100%' : '500px'};
    height: ${props => props.$FullScreen ? '100%' : 'auto'};
    background-color: black;
    z-index: ${props => props.$FullScreen ? '100' : '0'};
`

// style={{maxHeight:'100%',maxWidth:'100%',objectFit:'cover',objectPosition:'center'}}

const Image = styled.img`
    width: 100%;
    height: ${props => props.$FullScreen ? '100%' : 'auto'};
    object-fit: ${props => props.$FullScreen ? 'contain' : 'cover'};
    object-position: center center;
`

const Video = styled.video`
    width: 100%; 
    height: ${props => props.$FullScreen ? '100%' : 'auto'};
    object-fit: ${props => props.$FullScreen ? 'contain' : 'cover'};
    object-position: center center;
`

const Select = styled.select`
      height: 35px;
      border: 1px solid ${borderColor};
      border-radius: 5px;
      font-weight: bold;
      width: 100px;
      padding-left: 5px;
      cursor: pointer;
`

const Dropdown = styled.div`
    margin-left: 5px;
    margin-right: 5px;

    @media screen and (max-width: 600px) {
        margin-top: 3px;
        margin-bottom: 3px;
    }
`

const H1 = styled.h1`
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
`

const Loader = ({backgroundColor}) => {
    return(
        <div style={{
            display:'flex',
            alignItems:'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: backgroundColor ? backgroundColor : 'white',
            opacity: 0.5,
        }}>
            <BeatLoader />
        </div>
    );
}


const SlideUpContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    z-index: 5;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        align-items: center;
        justify-content: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        align-items: end;
    }
`

const slideUp = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const SlideUpContentWrapper = styled.div`
    
    background-color: white;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: ${props => props.$FullScreenHeight ? props.$FullScreenHeight : '95%'};
        width: ${props => props.$FullScreenWidth ? props.$FullScreenWidth : '60%'};
        border-radius: 20px;
        align-items: center;
    }
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: ${props => props.$MobileHeight ? props.$MobileHeight : '100%'};
        max-height: ${props => props.$MobileHeight ? props.$MobileHeight : '100%'};
        width: ${props => props.$MobileWidth ? props.$MobileWidth : '100%'};
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        animation: ${slideUp} 0.3s ease-out forwards;
    }
`

export {borderColor, 
    primaryColor, 
    secondaryColor, 
    navbarHeight,
    lightGray,
    mediumGray, 
    MediaContainer, 
    Image, 
    Video, 
    Loader, 
    Select, 
    Dropdown, 
    NewMediaContainer, 
    NewImage, 
    NewVideo,
    fontString,
    H1,
    SlideUpContainer,
    SlideUpContentWrapper,
};