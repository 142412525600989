import React from "react";
import styled from 'styled-components';
import { borderColor } from "../../styles/Styles";
import { MOBILE_WIDTH } from "../../config";
import ErrorDisplay from "./ErrorDisplay";
import { roundOdds } from "../../utils";

const IndividualEventDetailContent = styled.div`
    flex: 1;
    background-color: ${borderColor};
    font-size: 0.8em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;   
`

const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: row;
        align-items: center;
    }
`

const IndividualEventDetailContainerSidebar = styled.div`
    width: 100%
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-bottom: 10px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        flex: 1;
        width: 150px;
        margin-bottom: 0px;
        margin-right: 10px;
    }
`

const EventItemIndividualSidebar = ({record,handleClick}) => {

    // let navigate = useNavigate();
    // let location = useLocation();

    if (!record) {
        return(
            <ErrorDisplay />
        );
    }
    
    const { 
            event_details = []
        } = record || {};

    if (!event_details || event_details.length === 0) {
        return(
            <ErrorDisplay />
        );
    }

    // const {
    //     book_name
    //     , away_team_location
    //     , home_team_location
    //     , away_moneyline_price
    //     , home_moneyline_price
    //     , away_spread_price
    //     , home_spread_price
    //     , away_spread_point
    //     , home_spread_point
    //     , away_total_price
    //     , home_total_price
    //     , away_total_point
    //     , home_total_point
    //     , away_score
    //     , home_score
    // } = event_details[0];

    return(
        <SidebarWrapper>
            {
                record.event_details.map((eventDetail,index) => (
                    <IndividualEventDetailContainerSidebar key={index} onClick={handleClick} >
                        <IndividualEventDetailContent $IsSidebar={true} $Index={index} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div>{eventDetail.player_name}</div>
                            <div style={{fontWeight:'bold'}}>{roundOdds(eventDetail.price,0)}</div>
                        </IndividualEventDetailContent>
                    </IndividualEventDetailContainerSidebar>
                ))
            }
        </SidebarWrapper>
    );
}

export default EventItemIndividualSidebar;