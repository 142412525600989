import React from 'react';
import styled from 'styled-components';
import { borderColor, navbarHeight, lightGray, secondaryColor } from '../styles/Styles';
import { FaSearch } from "react-icons/fa";
import betboard_logo_256 from "../assets/betboard_logo_256.png";
import Avatar from './Avatar';
import Logout from './Logout';
import { useDebounce } from 'use-debounce';
import { SearchService } from '../api';
import { AuthenticationContext } from './AuthenticationContext';
import { useNavigate } from 'react-router-dom';
import { BackButton } from './Buttons';
import { MOBILE_WIDTH } from '../config';

const NavbarContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    height: ${navbarHeight};
    width: 100%;
    border-bottom: 1px solid ${borderColor};
    background-color: white;
    display: flex;
    
`

const SearchForm = styled.form`
    flex: 1;
    display: flex;
    align-items: center; 
    height: calc(${navbarHeight} - 15px);
    border: 1px solid ${borderColor};
    background-color: ${lightGray};
    border-radius: 999px;
`


const SearchInputWrapper = styled.div`
    flex: 1;
    height: 70%;
    display: flex;
    align-items: center;
    margin-left: 20px;
`

const SearchInput = styled.input`
    width: 100%;
    display: block;
    background: none;
    border: none;
    outline: none;
`

const SearchButtonContainer = styled.button`
    width: 40px;
    height: 80%;
    border: none;
    border-left: 1px solid ${borderColor};
    padding-top: 5px;
    cursor: pointer;
    background: none;
`

const SearchButtonIcon = styled.span`
    font-size: 1.3em;
`

const Search = ({searchText,setSearchText,onSubmit}) => {

    const handleInputChange = (event) => {
        setSearchText(event.target.value);
    }

    return(
        <>
            <SearchForm id='navbar-search' name='navbar-search' onSubmit={onSubmit}>
                <SearchInputWrapper>
                    <SearchInput 
                        id='searchInput' 
                        name='searchInput'
                        value = {searchText}
                        onChange={handleInputChange}
                        />
                </SearchInputWrapper>
                <SearchButtonContainer>
                    <SearchButtonIcon type='submit' style={{color:secondaryColor}}><FaSearch /></SearchButtonIcon>
                </SearchButtonContainer>
            </SearchForm>
        </>
    );
}

const LogoContainer = styled.div`
    height: calc(${navbarHeight} - 15px);
    width: calc(${navbarHeight} - 15px);
    overflow: hidden;
    margin-left: 10px;
`

const LogoImg = styled.img`
    width: 100%;
    height: auto;
`

const Logo = () => {

    let navigate = useNavigate();

    const goHome = (event) => {
        event.preventDefault();
        navigate('/');
    }

    return(
        <>
            <LogoContainer onClick={goHome}>
                <LogoImg src={betboard_logo_256} />
            </LogoContainer>
        </>
    );
}

const Section = styled.div`
    height: 100%;
`

const Left = styled(Section)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Center = styled(Section)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        flex: 2;
    }
    
`

const Right = styled(Section)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
`

const SearchResult = styled.div`
    position: absolute;
    top: 55px;
    left: 0;
    width:100%;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px ${borderColor};

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        position: fixed;
        left: 10px;
        width: calc(100% - 20px);
    }

`

const SearchItems = styled.div`
    width: 100%;
    height: 35px;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    
    &:hover {
        background-color: ${lightGray};
    }
`

function calculateResultSymbol(result) {
    if (result.type === 'users') {
        return '@';
    } else if (result.type === 'hashtags') {
        return '#';
    } else {
        return '';
    }
}

const Navbar = ({imageUrl,hideBack}) => {

    const [showLogout,setShowLogout] = React.useState(false);
    const [searchText,setSearchText] = React.useState('');
    const [debouncedSearchText] = useDebounce(searchText,250);
    const [searchResults,setSearchResults] = React.useState({});
    const [displayResults,setDisplayResults] = React.useState([]);
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [stateGoBack,setStateGoBack] = React.useState(false);
    const navigate = useNavigate();



    const openLogout = () => {
        setShowLogout(true);
    }

    const closeLogout = () => {
        setShowLogout(false);
    }

    const handleClose = (event) => {
        event.preventDefault();
        setSearchText('');
    }

    function goBack(event) {
        navigate(-1);
    }

    const handleSearchClick = (event,result) => {
        // event.stopPropagation();
        
        if (result.type === 'users') {
            
            navigate(`/${result.result_value_1}`);
            setSearchText('');
        } else if (result.type === 'teams') {
            
            navigate(`/team/${result.id}`);
            setSearchText('');
        } else if (result.type === 'hashtags'){
            navigate(`/hashtag/${result.result_value_1}`);
            setSearchText('');
        } else {
            console.log("unknown type:",result.type);
            return;
        }

        // navigate(`/${id}`);
    }

    const handleSearchSubmit = React.useCallback((event) => {
        event.preventDefault();
        if (Object.keys(searchResults).length === 0 ) {
            return;
        }
        navigate(`/search`, {state: { searchResults }});
    },[searchResults]);

    React.useEffect(() => {

        const search = async() => {
            try {
                const {data,errorMsg} = await SearchService.search(setAuthenticationState,authState,debouncedSearchText);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else{
                    // console.log(data);
                    setSearchResults(data.results);
                    setDisplayResults(data.results.ranked);
                }
            } catch (error) {
                console.log(error);
            }
        }
        
        if (debouncedSearchText !== '') {
            search();
        }
        

    },[debouncedSearchText,setSearchResults]);

    return(
        <NavbarContainer $ShowLogout={showLogout}>
            <Left>
                <Logo />
                {!hideBack && (
                    <div >
                        <BackButton onClick={goBack} />
                    </div>
                )}
            </Left>
            <Center >
                <Search searchText={searchText} setSearchText={setSearchText} onSubmit={handleSearchSubmit}/>
                {searchText && (
                    <>
                        <div onClick={handleClose} style={{position:'fixed',top:55,left:0,height: 'calc(100% - 55px)',width:'100%',backgroundColor:'black',opacity:'0.1'}}></div>
                        <SearchResult>
                            <div style={{borderTop:`1px solid ${borderColor}`}}>
                                {displayResults.map((result,index) => (
                                    <SearchItems key={index} onClick={(event) => (handleSearchClick(event,result))}>
                                        <div style={{paddingLeft:'10px'}}>
                                            <span style={{paddingRight:'10px',fontWeight: 'bold'}}>{result.result_value_2}</span>
                                            <span>{calculateResultSymbol(result)}{result.result_value_1}</span>
                                        </div>
                                    </SearchItems>
                                ))}
                            </div>
                        </SearchResult>
                    </>
                )}
            </Center>
            <Right>
                <Avatar onClick={openLogout} imageSource={imageUrl} imageSize={"40px"} margin={"0px 10px 0px 0px"}/>
                {showLogout && (<Logout closeLogout={closeLogout} />)}
            </Right>
        </NavbarContainer>
    );
}

export default Navbar;