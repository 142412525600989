import React from 'react';
import styled from 'styled-components';
import LeagueList from './LeagueList';

const LeagueIconScrollContainer = styled.div`
    display: flex;
    align-items: center;
    // height: 100px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`

const LeagueIconScroll = ({setSelectedLeague}) => {
    return(
        <LeagueIconScrollContainer>
            <LeagueList 
                isScroll={true}
                setSelectedLeague={setSelectedLeague}
                />
        </LeagueIconScrollContainer>
    )
}

export default LeagueIconScroll;