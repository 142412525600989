import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout, AuthenticationContext, Feed } from '../components';
import PostService from '../api/postService';

const Hashtag = () => {
    let { hashtag } = useParams();
    const [feed,setFeed] = React.useState([]);

    return(
        <Layout>
            <div style={{textAlign:'left'}}>
                <h1>#{hashtag}</h1>
            </div>
            <Feed feed={feed} setFeed={setFeed} hashtagText={hashtag} />
        </Layout>
    )
}

export default Hashtag;