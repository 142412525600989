import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

function calcPrefix(url) {
    return url.includes('?') ? '&' : '?';
}

function setAuthHeaders() {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}

function errorHandler(error,setAuthenticationState,authState) {
    const errorCode = error.response ? error.response.status : 500;
    let errorMessage = "";

    switch (errorCode) {
        case 400:
            errorMessage='Required field is missing from request or has invalid characters.'
            break
        case 401:
            setAuthenticationState(authState.UNAUTHENTICATED);
            errorMessage = "User not logged in or token expired";
            break
        case 403:
            errorMessage = "Unable to make change to specified account";
            break
        case 404:
            errorMessage = 'No user record found.';
            break;
        case 409:
            errorMessage = "A profile with that username already exists";
            break;
        default:
            errorMessage = 'An unexpected database error occurred.';
            break;
    }
    return {data: null, errorMsg: errorMessage};
}

const SearchService = {
    search: async (setAuthenticationState,authState,searchText) => {
        if (searchText === null || searchText === undefined) {
            throw new Error("search text cannot be null");
        }

        const SEARCH_ENDPOINT = "/search";
        const url = `${API_BASE_URL}${SEARCH_ENDPOINT}?searchText=${encodeURIComponent(searchText)}`;
        
        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            });
            return {data:response.data,errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    }
}

export default SearchService;