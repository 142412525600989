import React from "react";
import styled from "styled-components";
import { borderColor } from "../styles/Styles";

const ReactionChoice = styled.div`
    width: ${props => props.$Size ? `${props.$Size}px` : '40px'};
    height: ${props => props.$Size ? `${props.$Size}px` : '40px'};
    border-radius: 999px;
    margin-right: ${props => props.$IsSelection ? '10px' : '0px'};
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.$IsPositive ? 'rgba(0, 202, 148,0.5)' : 'transparent'};

    &:hover {
        background-color: ${props => props.$IsSelection ? '10px' : '0px'};
    }
`

const MoneybagReaction = ({size = 40,positive,handleReaction}) => {
    return(
        <ReactionChoice $IsPositive ={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'moneybag')}>
            <div style={{fontSize:`${Math.ceil(size / 1.5)}px`,paddingBottom:'1px'}}>{'\u{1F4B0}'}</div>
        </ReactionChoice>
    );
}

const LikeReaction = ({size = 40,positive,handleReaction}) => {
    return(
        <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'like')}>
            <div style={{color:positive ?'white' : 'blue',fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F44D}'}</div>
        </ReactionChoice>
    )
}

const LaughReaction = ({size=40,positive,handleReaction}) => {
    return(
        <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'laugh')}>
            <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F923}'}</div>
        </ReactionChoice>
    );
}

const IceReaction = ({size=40,positive,handleReaction}) => {
    return(
        <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'ice')}>
            <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F976}'}</div>
        </ReactionChoice>
    );
}

const DislikeReaction = ({size = 40,positive,handleReaction}) => {
    return(
        <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'dislike')}>
            <div style={{color:positive ? 'white' : 'red',fontSize:`${Math.ceil(size / 2)}px`,paddingTop:`${Math.floor(size / 10)}px`,paddingBottom:'1px'}}>{'\u{1F44E}'}</div>
        </ReactionChoice>
    )
}

const Reactions = ({closeModal,reactions,handleReaction}) => {

    return(
        <>
            <div style={{position:'absolute',bottom:'35px',left:'0px',paddingTop:'5px',paddingBottom:'5px',minWidth:'150px',display:'flex',paddingLeft:'10px',alignItems:'center',backgroundColor:'white',border:`1px solid ${borderColor}`,borderRadius:'10px',zIndex:'2'}} >
                <LikeReaction positive={reactions.like} handleReaction={handleReaction} />
                <DislikeReaction positive={reactions.dislike} handleReaction={handleReaction} />
                <LaughReaction positive={reactions.laugh} handleReaction={handleReaction} />
                <MoneybagReaction positive={reactions.moneybag} handleReaction={handleReaction} />
                <IceReaction positive = {reactions.ice} handleReaction={handleReaction} />
            </div>
            <div style={{position:'fixed',top:'0px',left:'0px',width:'100%',height:'100%',zIndex:'1'}} onClick={closeModal}>
            </div>
        </>
    )
}

export default Reactions;
export {LikeReaction,DislikeReaction,LaughReaction,MoneybagReaction,IceReaction};