import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

function calcPrefix(url) {
    return url.includes('?') ? '&' : '?';
}

function errorHandler(error,setAuthenticationState,authState) {
    const errorCode = error.response ? error.response.status : 500;
    let errorMessage = "";

    switch (errorCode) {
        case 400:
            errorMessage='Required field is missing from request or has invalid characters.'
            break
        case 401:
            setAuthenticationState(authState.UNAUTHENTICATED);
            errorMessage = "User not logged in or token expired";
            break
        case 403:
            errorMessage = "Unable to make change to specified account";
            break
        case 404:
            errorMessage = 'No post record found.';
            break;
        case 409:
            errorMessage = "Post cannot be deleted after 2 minutes.";
            break;
        default:
            errorMessage = 'An unexpected database error occurred.';
            break;
    }
    return {data: null, errorMsg: errorMessage};
}

function setAuthHeaders(isForm) {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}

const PostService = {
    createPost: async (postData,setAuthenticationState,authState) => {
        const CREATE_POST_ENDPOINT = "/create-post";

        const headers = setAuthHeaders(true);

        try {
            const response = await axios.post(`${API_BASE_URL}${CREATE_POST_ENDPOINT}`,postData,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
            // return response;
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },
    loadPosts: async (setAuthenticationState,authState,loadedPostIds,viewedUsername,postId,replyToPostId,eventId,teamId,hashtagText,playerId,highlightId) => {
        const LOAD_POSTS_ENDPOINT = "/load-posts";

        try {
            let url = `${API_BASE_URL}${LOAD_POSTS_ENDPOINT}`
            
            if (viewedUsername !== null && viewedUsername !== undefined) {
                url += `?username=${viewedUsername}`
            }

            if (loadedPostIds.length > 0) {
                const prefix = url.includes('?') ? '&' : '?';
                url += `${prefix}loadedPostIds=${loadedPostIds.join(',')}`;
            }

            if (postId) {
                const prefix = url.includes('?') ? '&' : '?';
                url += `${prefix}postId=${postId}`;
            }

            if (replyToPostId) {
                const prefix = url.includes('?') ? '&' : '?';
                url += `${prefix}replyToPostId=${replyToPostId}`;
            }

            if (eventId) {
                url += `${calcPrefix(url)}eventId=${eventId}`;
            }

            if (teamId) {
                url += `${calcPrefix(url)}teamId=${teamId}`;
            }
            
            if (hashtagText) {
                url += `${calcPrefix(url)}hashtagText=${hashtagText}`
            }

            if (playerId) {
                url += `${calcPrefix(url)}playerId=${playerId}`
            }

            if (highlightId) {
                url += `${calcPrefix(url)}highlightId=${highlightId}`
            }

            const headers = setAuthHeaders();

            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            }
            );
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },
    likePost: async (setAuthenticationState,authState,postData) => {
        const LIKE_POST_ENDPOINT = '/like-post';

        const headers = setAuthHeaders();

        try {
            const url = `${API_BASE_URL}${LIKE_POST_ENDPOINT}`;
            const response = await axios.post(url,postData,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },unlikePost: async (setAuthenticationState,authState,postId) => {
        const LIKE_POST_ENDPOINT = '/unlike-post';
        try {
        
            const url = `${API_BASE_URL}${LIKE_POST_ENDPOINT}?postId=${postId}`;
            const headers = setAuthHeaders();

            const response = await axios.delete(url,{
                withCredentials:true,
                headers:headers,
            });
            
            return {data: response.data, errorMsg: null};
        } catch (error) {
            
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },loadPostWithParents: async (setAuthenticationState,authState,postId) => {
        const LOAD_POST_WITH_PARENTS = "/load-post-with-parents";

        try {
            let url = `${API_BASE_URL}${LOAD_POST_WITH_PARENTS}`
            
            if (!postId) {
                throw new Error("postId cannot be null or undefined");
            } else {
                url += `?postId=${postId}`;
            }

            const headers = setAuthHeaders();

            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            }
            );
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },searchHashtags: async (setAuthenticationState,authState,hashtagText) => {
        const SEARCH_HASHTAGS = "/search-hashtags";

        try {
            let url = `${API_BASE_URL}${SEARCH_HASHTAGS}`
            
            if (!hashtagText) {
                throw new Error("postId cannot be null or undefined");
            } else {
                url += `?hashtagText=${hashtagText}`;
            }

            const headers = setAuthHeaders();

            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
            }
            );
            return {data: response.data, errorMsg: null};
        } catch (error) {
            const errorCode = error.response ? error.response.status : 500;
            let errorMessage = 'An unexpected database error occurred.';

            switch (errorCode) {
                case 400:
                    errorMessage = 'Required field is missing from request or has invalid characters.';
                    break;
                case 401:
                    setAuthenticationState(authState.UNAUTHENTICATED);
                    errorMessage = 'User is not logged in or token has expired.';
                    break;
                case 404:
                    errorMessage = 'No user record found.';
                    break;
                default:
                    errorMessage = 'An unexpected database error occurred.';
                    break;
                }
            return { data: null, errorMsg: errorMessage };
        }
    },deletePost: async (setAuthenticationState,authState,postId) => {
        const DELETE_POST_ENDPOINT = "/delete-post";

        try {
            const headers = setAuthHeaders();
            const response = await axios.delete(`${API_BASE_URL}${DELETE_POST_ENDPOINT}?postId=${postId}`,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    }
}

export default PostService; 