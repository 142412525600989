import React from 'react';
import styled from 'styled-components';
import { borderColor } from '../styles/Styles';
import { MOBILE_WIDTH } from '../config';

const EventContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 20px;
    margin-righ: 20px;
    margin-top: 20px;
    width: 100%;

    

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        padding-top: 0px;
        flex-direction: row;
        height: 75px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        justify-content: start;
        margin-top: 0px;
      }
`;

const EventItem = styled.div`
      height: 100px;
      width: 95%;
      border: 1px solid ${borderColor};
      border-radius: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      

      @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 100px;
        height: 60px;
        margin-left: 5px;
        margin-right: 5px;
        flex-shrink: 0;
      }
      
`;

const Team = styled.div`
    display: flex;
    height: 50%;
`

const TeamName = styled.div`
      flex: 2;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
`

const TeamOdds = styled.div`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: ${MOBILE_WIDTH}px) {
        flex: 1.5;
      }
`

const Event = () => {
    return(
        <EventContainer>
            <EventItem>
                <Team>
                    <TeamName>SF</TeamName>
                    <TeamOdds>-1.5</TeamOdds>
                </Team>
                <Team>
                    <TeamName>KC</TeamName>
                    <TeamOdds>1.5</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>SAS</TeamName>
                    <TeamOdds>-5.5</TeamOdds>
                </Team>
                <Team>
                    <TeamName>BKN</TeamName>
                    <TeamOdds>5.5</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>PHI</TeamName>
                    <TeamOdds>-4.5</TeamOdds>
                </Team>
                <Team>
                    <TeamName>WAS</TeamName>
                    <TeamOdds>4.5</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>CHI</TeamName>
                    <TeamOdds>-5.0</TeamOdds>
                </Team>
                <Team>
                    <TeamName>ORL</TeamName>
                    <TeamOdds>5.0</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>MEM</TeamName>
                    <TeamOdds>-4.5</TeamOdds>
                </Team>
                <Team>
                    <TeamName>CHA</TeamName>
                    <TeamOdds>4.5</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>CLE</TeamName>
                    <TeamOdds>-9</TeamOdds>
                </Team>
                <Team>
                    <TeamName>TOR</TeamName>
                    <TeamOdds>9</TeamOdds>
                </Team>
            </EventItem>
            <EventItem>
                <Team>
                    <TeamName>IND</TeamName>
                    <TeamOdds>-5</TeamOdds>
                </Team>
                <Team>
                    <TeamName>NYK</TeamName>
                    <TeamOdds>5</TeamOdds>
                </Team>
            </EventItem>
            
            </EventContainer>
    );
}

export default Event;