import React from "react";
import styled from 'styled-components';
import { borderColor, secondaryColor } from "../../styles/Styles";
import { TiArrowSortedDown } from "react-icons/ti";
import { BiSolidDownArrow } from "react-icons/bi";

const DateFilterContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
`

const DateFilterOption = styled.div`
    margin-right: 6px;
    margin-left: 6px;
    padding: 7px 12px 7px 12px;
    border: 1px solid ${props => props.$IsChosen ? secondaryColor : borderColor};
    border-radius: 999px;
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.$IsChosen ? 'white' : 'black'};
    background-color: ${props => props.$IsChosen ? secondaryColor : 'white'};
    // width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
`

const Select = styled.select`
    margin-right: 6px;
    margin-left: 6px;
    padding: 7px 12px 7px 12px;
    border: 1px solid ${props => props.$IsChosen ? secondaryColor : borderColor};
    border-radius: 999px;
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.$IsChosen ? 'white' : 'black'};
    background-color: ${props => props.$IsChosen ? secondaryColor : 'white'};
`

const DateFilter = ({filterOptions,filterChoice,setFilterChoice}) => {

    function handleClick(event,choice) {
        event.preventDefault();
        setFilterChoice(choice);
    }

    return(
        <DateFilterContainer>
            <DateFilterOption $IsChosen={filterChoice === filterOptions.WEEK} onClick={(event) => handleClick(event,filterOptions.WEEK)}>Week</DateFilterOption>
            <DateFilterOption $IsChosen={filterChoice === filterOptions.MONTH} onClick={(event) => handleClick(event,filterOptions.MONTH)}>Month</DateFilterOption>
            <DateFilterOption $IsChosen={filterChoice === filterOptions.ALL} onClick={(event) => handleClick(event,filterOptions.ALL)}>All Time</DateFilterOption>
            <Select
                value={"league"}
                >
                <option value="1">MLB</option>
                <option value="1">NBA</option>
                <option value="1">PGA</option>
            </Select>            
        </DateFilterContainer>
    )
}

export default DateFilter;