import React from 'react';
import styled from 'styled-components';
import UserService from '../api/userService';
import Avatar from './Avatar';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { Input, Textarea } from './Inputs';
import { SmallButton, BackButton } from './Buttons';
import { BiArrowBack } from "react-icons/bi";
import { borderColor, Select, Dropdown } from '../styles/Styles';
import { MOBILE_WIDTH } from '../config';

const EditProfileContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgb(255,255,255); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalToolbar = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const CloseModalContainer = styled.div`
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
    border-radius: 25px;
    font-size: 1.5em;

    &:hover {
        background-color: ${borderColor};
    }


`

const ModalContent= styled.div`
    height: 600px;
    width: 500px;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 11;
    padding-bottom: 50px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: calc(100vh - 200px);
        width: 100%;
    }

`

const Form = styled.form`
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: start;
`

const ProfileImageWrapper = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    margin-top: 10px;
`

const ProfileImageButton = styled.button`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    background-color: rgba(255,255,255,0.5); /* Black w/ opacity */
`

const CoverImageWrapper = styled.div`
    width: 100%;
    height: 200px;
    background-image: url(${props => props.$ImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${borderColor};
    position: relative;
    margin-top: 10px;
`

const CoverImageButton = styled.button`
    position: absolute;
    width: 40px;
    height: 40px;
    top: ${props => props.$Height}px;
    left: ${props => props.$Width}px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    background-color: rgba(255,255,255,0.5); /* Black w/ opacity */
`

function setBookName(internalName) {
    switch (internalName) {
        case 'bovada':
            return 'Bovada';
        case 'draftkings':
            return 'DraftKings';
        case 'fanduel':
            return 'FanDuel';
        default:
            return null;
    }
}


const EditProfile = ({closeModal,fullName,setAuthenticationState,authState,username,profileData,setProfileData}) => {

    const coverImageRef = React.useRef(null);
    const [profileImageFile,setProfileImageFile] = React.useState(null);
    // const [profileImageUrl,setProfileImageUrl] = React.useState(profilePhotoFilename);
    const [coverImageFile,setCoverImageFile] = React.useState(null);
    // const [coverImageUrl,setCoverImageUrl] = React.useState(coverPhotoFilename);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [newProfileData,setNewProfileData] = React.useState({
        fullName:fullName,
        profileImageUrl:profileData.profilePhotoFilename,
        coverImageUrl:profileData.coverPhotoFilename,
        preferredBook:profileData.preferredBook,
        preferredBookName:profileData.preferredBookName,
        profileBio:profileData.profileBio,
    });

    React.useEffect(() => {
        // Ensure the element is rendered
        if (coverImageRef.current) {
            setWidth((coverImageRef.current.offsetWidth - 40) / 2);
            setHeight((coverImageRef.current.offsetHeight - 40) / 2);
        }
      }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'profileBio' && value.length > 100) return;
        setNewProfileData({
            ...newProfileData
            ,[name]: value
        });
    }

    const handleSelectChange = (event) => {
        
        setNewProfileData({
            ...newProfileData
            ,preferredBook: event.target.value
            ,preferredBookName: setBookName(event.target.value)
        });
    }
    
    const handleProfileImageChange = React.useCallback((event) => {
        const file = event.target.files[0];
        setProfileImageFile(file);
        // setProfileImageUrl(URL.createObjectURL(file));
        setNewProfileData(prevState => ({
            ...prevState,
            profileImageUrl:URL.createObjectURL(file)
        }));
    },[setProfileImageFile,setNewProfileData]);

    const handleCoverImageChange = React.useCallback((event) => {
        const file = event.target.files[0];
        setCoverImageFile(file);
        // setCoverImageUrl(URL.createObjectURL(file));
        setNewProfileData(prevState => ({
            ...prevState,
            coverImageUrl:URL.createObjectURL(file)
        }));
    },[setCoverImageFile,setNewProfileData]);

    const uploadForm = React.useCallback(async () => {
        const {fullName,preferredBook,profileBio} = newProfileData;
        const formData = new FormData();
        if (profileImageFile) {
            formData.append('profileImage',profileImageFile);
        }
        if (coverImageFile) {
            formData.append('coverImage',coverImageFile);
        }
        formData.append('fullName',fullName);
        formData.append('preferredBook',preferredBook);
        formData.append('profileBio',profileBio);

        try {
            await UserService.updateProfile(setAuthenticationState,authState,username,formData);
        } catch (error) {
            console.log(error);
        }
    },[profileImageFile,coverImageFile,setAuthenticationState,authState,username,newProfileData]);

    const handleSubmit = React.useCallback((event) => {
        event.preventDefault();
        uploadForm();
        setProfileData(prevState => ({
            ...prevState,
            fullName:newProfileData.fullName,
            // profilePhotoFilename:profileImageUrl,
            // coverPhotoFilename:coverImageUrl,
            profilePhotoFilename:newProfileData.profileImageUrl,
            coverPhotoFilename:newProfileData.coverImageUrl,
            preferredBook:newProfileData.preferredBook,
            preferredBookName:newProfileData.preferredBookName,
            profileBio:newProfileData.profileBio,
        }));
        closeModal();
    },[uploadForm,closeModal,setProfileData,newProfileData]);

    return(
        <EditProfileContainer>
            <ModalContent>
                <Form onSubmit={handleSubmit} id="edit-profile" name ='edit-profile'>
                    <ModalToolbar>
                        <BackButton onClick={closeModal} />
                        <SmallButton type = 'submit'>Save</SmallButton>
                    </ModalToolbar>
                    {/* <CoverImageWrapper> */}
                    <CoverImageWrapper ref = {coverImageRef} $ImageUrl = {newProfileData.coverImageUrl}>
                        <CoverImageButton type = 'button' $Width = {width} $Height = {height} onClick={() => document.getElementById('coverImage').click()}>
                            <MdOutlineAddPhotoAlternate size={'25px'} />
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/gif"
                                id="coverImage"
                                name="coverImage"
                                onChange={handleCoverImageChange}
                                style={{ display: 'none' }}
                            />
                        </CoverImageButton>
                        {/* <CoverImg src = {fileToUrl(coverPhotoFilename)} /> */}
                    </CoverImageWrapper>
                    <ProfileImageWrapper>
                        <Avatar 
                            imageSource = {newProfileData.profileImageUrl} 
                            imageSize={"120px"}
                            />
                        <ProfileImageButton type = 'button' onClick={() => document.getElementById('profileImage').click()}>
                            <MdOutlineAddPhotoAlternate size={'25px'} />
                            <input
                                data-testid="profileImage"
                                type="file"
                                accept="image/png, image/jpeg, image/gif"
                                id="profileImage"
                                name="profileImage"
                                onChange={handleProfileImageChange}
                                style={{ display: 'none' }}
                            />
                        </ProfileImageButton>
                    </ProfileImageWrapper>
                    <Input
                        type = "text"
                        id = "fullName"
                        name = "fullName"
                        value = {newProfileData.fullName}
                        onChange = {handleInputChange}
                        placeholder="Name"
                        style = {{marginTop: 10}}
                    />
                    <Textarea
                        type = "text"
                        id = "profileBio"
                        name = "profileBio"
                        value = {newProfileData.profileBio}
                        onChange = {handleInputChange}
                        placeholder="Bio"
                        style = {{marginTop: 10}}
                    />
                    
                    <label style={{marginLeft:'5px',marginTop:'10px'}}>Preferred Book:</label>
                    <Dropdown style={{width:'150px'}}>
                        <Select style={{width:'100%'}} onChange={handleSelectChange} value={newProfileData.preferredBook}>   
                            <option value='draftkings'>DraftKings</option>
                            {/* <option value='williamhill_us'>Caesars</option> */}
                            <option value='fanduel'>FanDuel</option>
                            {/* <option value='bovada'>Bovada</option> */}
                            {/* <option value='mybookieag'>MyBookie.ag</option>
                            <option value='betrivers'>BetRivers</option>
                            <option value='unibet_us'>Unibet</option>
                            <option value='wynnbet'>WynnBET</option>
                            <option value='betmgm'>BetMGM</option>
                            <option value='pointsbetus'>PointsBet (US)</option>
                            <option value='betonlineag'>BetOnline.ag</option>
                            <option value='lowvig'>LowVig.ag</option>
                            <option value='betus'>BetUS</option>
                            <option value='superbook'>SuperBook</option> */}
                        </Select>
                    </Dropdown>    
                </Form>
            </ModalContent>
        </EditProfileContainer>
    );
}

export default EditProfile;



