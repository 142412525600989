import React from "react";
import styled from "styled-components";
import { borderColor,mediumGray } from "../../styles/Styles";

const ReactionChoice = styled.div`
    width: ${props => props.$Size ? `${props.$Size}px` : '40px'};
    height: ${props => props.$Size ? `${props.$Size}px` : '40px'};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.$IsPositive ? 'rgba(0, 202, 148,0.5)' : 'transparent'};

    &:hover {
        background-color: ${props => props.$IsSelection ? '10px' : '0px'};
    }
`

const MoneybagReaction = ({size = 40,positive,handleReaction,reactionCount}) => {
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <ReactionChoice $IsPositive ={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'moneybag')}>
                <div style={{fontSize:`${Math.ceil(size / 1.5)}px`,paddingBottom:'1px'}}>{'\u{1F4B0}'}</div>
            </ReactionChoice>
            <div style={{height:'20px',fontSize:'12px',color:mediumGray}}>{reactionCount?.moneybag ? reactionCount?.moneybag : null}</div>
        </div>
    );
}

const LikeReaction = ({size = 40,positive,handleReaction,reactionCount}) => {
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => {handleReaction(event,'like')}}>
                <div style={{color:positive ?'white' : 'blue',fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F44D}'}</div>
            </ReactionChoice>
            <div style={{height:'20px',fontSize:'12px',color:mediumGray}}>{reactionCount?.like ? reactionCount?.like : null}</div>
        </div>
    )
}

const LaughReaction = ({size=40,positive,handleReaction,reactionCount}) => {
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'laugh')}>
                <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F923}'}</div>
            </ReactionChoice>
            <div style={{height:'20px',fontSize:'12px',color:mediumGray}}>{reactionCount?.laugh ? reactionCount?.laugh : null}</div>
        </div>
    );
}

const IceReaction = ({size=40,positive,handleReaction,reactionCount}) => {
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'ice')}>
                <div style={{fontSize:`${Math.ceil(size / 1.75)}px`,paddingBottom:'1px'}}>{'\u{1F976}'}</div>
            </ReactionChoice>
            <div style={{height:'20px',fontSize:'12px',color:mediumGray}}>{reactionCount?.ice ? reactionCount?.ice : null}</div>
        </div>
    );
}

const DislikeReaction = ({size = 40,positive,handleReaction,reactionCount}) => {
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <ReactionChoice $IsPositive={positive} $IsSelection={handleReaction ? true : false} $Size={size} onClick={(event) => handleReaction?.(event,'dislike')}>
                <div style={{color:positive ? 'white' : 'red',fontSize:`${Math.ceil(size / 2)}px`,paddingTop:`${Math.floor(size / 10)}px`,paddingBottom:'1px'}}>{'\u{1F44E}'}</div>
            </ReactionChoice>
            <div style={{height:'20px',fontSize:'12px',color:mediumGray}}>{reactionCount?.dislike ? reactionCount?.dislike : null}</div>
        </div>
    )
}

const HighlightReactions = ({closeModal,reactions,handleReaction,reactionCount}) => {

    const iconSize = 60;

    return(
        <>
            <div style={{marginLeft:'20px',marginRight:'20px',marginTop:'40px',paddingBottom:'5px',width:'100%',display:'flex',alignItems:'start',justifyContent:'space-between'}}>
                <LikeReaction size={iconSize} positive={reactions.like} handleReaction={handleReaction} reactionCount={reactionCount} />
                <DislikeReaction size={iconSize} positive={reactions.dislike} handleReaction={handleReaction} reactionCount={reactionCount} />
                <LaughReaction size={iconSize} positive={reactions.laugh} handleReaction={handleReaction} reactionCount={reactionCount} />
                <MoneybagReaction size={iconSize} positive={reactions.moneybag} handleReaction={handleReaction} reactionCount={reactionCount} />
                <IceReaction size={iconSize} positive = {reactions.ice} handleReaction={handleReaction} reactionCount={reactionCount} />
            </div>
        </>
    )
}

export default HighlightReactions;
// export {LikeReaction,DislikeReaction,LaughReaction,MoneybagReaction,IceReaction};