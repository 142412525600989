import React from "react";
import { Layout } from "../components";
import { useParams } from "react-router-dom";
import { Leaderboard, Scorecard } from "../components/SlateSwipe";

const SlateSwipe = () => {

    let {viewedUsername} = useParams();

    return(
        <Layout>
            {!viewedUsername && (
                <Leaderboard />
            )}
            {viewedUsername && (
                <Scorecard viewedUsername={viewedUsername} />
            )}
        </Layout>
    )
}

export default SlateSwipe;