import React from "react";
import styled from "styled-components";
import { Input } from "../Inputs";
import { PrimaryButton, WhiteButton } from "../Buttons";
import { UserService } from "../../api";
import {  useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
const USERNAME_LENGH_LIMIT = 25;


const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 0.9em;
`

const ErrorMessageContainer = styled(MessageContainer)`
    color: red;
`

const Login = ({display, setDisplayState, authState,setAuthenticationState, setUsername}) => {

    const [postData,setPostData] = React.useState({
        fullName:'',
        username:'',
        phone:'',
        email:'',
        password:'',
    });

    const [errorMessage,setErrorMessage] = React.useState();
    const [displayMessage,setDisplayMessage] = React.useState();
    const [forgotPassword,setForgotPassword] = React.useState(false);
    const [forgotPasswordData,setForgotPasswordData] = React.useState({email:''});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "username") {
            if (value.length <= USERNAME_LENGH_LIMIT && !/\s/.test(value)) {
                setPostData({
                    ...postData
                    ,[name]: value
                });
            }
        } else {
            setPostData({
                ...postData
                ,[name]: value
            });
        }
        
    }

    const handleBack = () => {
        setDisplayState(display.CHOICE);
    }

    const handleSubmit = React.useCallback( async (event) => { 
        event.preventDefault();
        try {

            const {data,errorMsg} = await UserService.loginUser(postData,setAuthenticationState,authState);
            
            if (errorMsg) {
                setErrorMessage(errorMsg);
            } else {
                setUsername(data.username);
                setAuthenticationState(authState.AUTHENTICATED);
            }
        } catch (error) {
            console.log("Unexpected error",error);
        }
    },[postData,setAuthenticationState,authState, setUsername]);

    async function verifyGoogleToken(response) {
        try {
            const postData = {token:response.access_token};
            const {data,errorMsg} = await UserService.verifyGoogleToken(setAuthenticationState,authState,postData);
            if (!data) {
                console.log("API Error:",errorMsg);
            } else {
                if (data.username === null) {
                    setDisplayState(display.OAUTH_DETAILS);
                } else {
                    setUsername(data.username);
                    setAuthenticationState(authState.AUTHENTICATED);
                }
                
            }
        } catch (error) {
            console.log(error);
        }
    }

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            verifyGoogleToken(tokenResponse);
        },
    });

    function showForgotPassword(event) {
        event.preventDefault();
        setForgotPassword(true);
    }

    function hideForgotPassword(event) {
        event.preventDefault();
        setForgotPasswordData({email:''});
        setForgotPassword(false);
    }

    function handleUpdateForgotPasswordEmail(event) {
        setForgotPasswordData({email:event.target.value});
    }

    async function handleForgotPasswordSubmit(event) {
        event.preventDefault();
        try {
            const {data,errorMsg} = await UserService.requestPasswordReset(setAuthenticationState,authState,forgotPasswordData);
            if (!data) {
                setErrorMessage(errorMsg);
            } else {
                setDisplayMessage("Password reset email sent");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <>
            {forgotPassword && (
                <>
                    <Form onSubmit={handleForgotPasswordSubmit}>
                        <div style={{fontSize:'1.3em',fontWeight:'bold',marginTop:'10px'}}>Forgot Password?</div>
                        <Input 
                            type="email"
                            name = "email"
                            value = {forgotPasswordData.email}
                            placeholder='email'
                            onChange={handleUpdateForgotPasswordEmail}
                            required={true}
                        />
                        <PrimaryButton>Reset Password</PrimaryButton>
                        {displayMessage && (
                            <MessageContainer>{displayMessage}</MessageContainer>
                        )}
                        {errorMessage && (
                            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                        )}
                    </Form>
                    <div onClick={hideForgotPassword} style={{fontSize:'0.9em',marginTop:'5px',cursor:'pointer'}}>Back</div>
                </>
            )}
            {!forgotPassword && (
                <>
                    <WhiteButton onClick={() => login()} style={{marginBottom:'20px'}}><div style={{fontSize:'18px',paddingRight:'5px',paddingTop:'5px',fontFamily:'"Google Sans",arial,sans-serif'}}><FcGoogle /></div>Sign in with Google</WhiteButton>
                    <Form onSubmit={handleSubmit}>
                        <Input 
                            data-testid = "input-email"
                            type = "email"
                            name = "email"
                            value = {postData.email}
                            onChange = {handleInputChange}
                            placeholder="email"
                            // onBlur={}
                        />
                        <Input
                            data-testid = "input-password"
                            type="password"
                            name = "password"
                            value = {postData.password}
                            onChange={handleInputChange}
                            placeholder='password'
                        />
                        <ButtonContainer>
                            <PrimaryButton data-testid = 'login-signup-submit' type='submit'>Submit</PrimaryButton>
                            <WhiteButton onClick={handleBack}>Back</WhiteButton>
                        </ButtonContainer>
                        {errorMessage && (
                            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                        )}
                    </Form>
                    <div onClick={showForgotPassword} style={{fontSize:'0.9em',marginTop:'5px',cursor:'pointer'}}>Forgot Password</div>
                </>
            )}
        </>
    );
}

export default Login;