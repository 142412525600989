let API_BASE_URL;
let S3_BASE_URL;
const MOBILE_WIDTH = 768;

// API_BASE_URL="https://test.api.betboard.us";
// S3_BASE_URL="https://betboard-bucket.nyc3.cdn.digitaloceanspaces.com/";

// API_BASE_URL="";
// S3_BASE_URL="http://127.0.0.1:9000/betboard-bucket/";

if (process.env.REACT_APP_IS_DEV === 1) {
    API_BASE_URL="";
    S3_BASE_URL="http://127.0.0.1:9000/betboard-bucket/";
    // API_BASE_URL="https://test.api.betboard.us";
    // S3_BASE_URL="https://betboard-bucket.nyc3.cdn.digitaloceanspaces.com/";
} else if (process.env.REACT_APP_IS_TEST) {
    API_BASE_URL="https://test.api.betboard.us";
    S3_BASE_URL="https://betboard-bucket.nyc3.cdn.digitaloceanspaces.com/";
} else {
    API_BASE_URL="https://api.betboard.us";
    S3_BASE_URL="https://betboard-bucket.nyc3.cdn.digitaloceanspaces.com/";
}

export {API_BASE_URL,S3_BASE_URL,MOBILE_WIDTH};