import React from "react";
import styled from "styled-components";
import { Input } from "./Inputs";
import { PrimaryButton, WhiteButton } from "./Buttons";
import { useParams } from "react-router-dom";
import { UserService } from "../api";
import { AuthenticationContext } from "./AuthenticationContext";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ContentContainer = styled.div`
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 0.9em;
`

const ErrorMessageContainer = styled(MessageContainer)`
    color: red;
`

const ResetPassword = ({setResetCount}) => {

    let {resetToken} = useParams();
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [postData,setPostData] = React.useState({
        newPassword:null,
    });
    const [displayMessage,setDisplayMessage] = React.useState();
    const [errorMessage,setErrorMessage] = React.useState();
    let navigate = useNavigate();

    const handleInputChange = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        setPostData({
            ...postData
            ,[name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const {data,errorMsg} = await UserService.resetPassword(setAuthenticationState,authState,postData,resetToken);
            if (!data) {
                console.log("API Error: ",errorMsg);
                setErrorMessage(errorMsg);
            } else {
                setDisplayMessage("Password reset successfully");
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleNavigateToLogin(event) {
        event.preventDefault();
        setResetCount((prevCount) => prevCount + 1);
        navigate(`/`);
    }

    return (
        <>
            {resetToken && (
                <PageContainer>
                    <ContentContainer>
                        <Form onSubmit={handleSubmit}>
                            <div style={{fontSize:'2em',fontWeight:'bold'}}>Reset Password</div>
                            <Input 
                                type="password" 
                                placeholder="password"
                                name="newPassword"
                                value={postData.newPassword}
                                onChange={handleInputChange}
                                required={true}
                                />
                            <PrimaryButton>Reset Password</PrimaryButton>
                        </Form>
                        {displayMessage && (
                            <>
                                <MessageContainer>{displayMessage}</MessageContainer>
                                <WhiteButton onClick={handleNavigateToLogin}>Login</WhiteButton>
                            </>
                        )}
                        {errorMessage && (
                            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                        )}
                    </ContentContainer>
                </PageContainer>
            )}
        </>
    );
}

export default ResetPassword;