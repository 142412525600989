import axios from 'axios';
import Cookies from 'js-cookie';

import { API_BASE_URL } from '../config';

function calcPrefix(url) {
    return url.includes('?') ? '&' : '?';
}

function setAuthHeaders(isForm) {
    
    const headers = {
        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
        'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
    }
    
    if (process.env.REACT_APP_IS_TEST === '1') {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
}
function errorHandler(error,setAuthenticationState,authState) {
    const errorCode = error.response ? error.response.status : 500;
    let errorMessage = "";

    switch (errorCode) {
        case 400:
            errorMessage='Required field is missing from request or has invalid characters.'
            break
        case 401:
            setAuthenticationState(authState.UNAUTHENTICATED);
            errorMessage = "User not logged in or token expired";
            break
        case 403:
            errorMessage = "Unable to make change to specified account";
            break
        case 404:
            errorMessage = 'No post record found.';
            break;
        case 409:
            errorMessage = "Post cannot be deleted after 2 minutes.";
            break;
        default:
            errorMessage = 'An unexpected database error occurred.';
            break;
    }
    return {data: null, errorMsg: errorMessage};
}

const HighlightService = {
    createHighlight: async function(highlightData,setAuthenticationState,authState) {
        const CREATE_HIGHLIGHT_ENDPOINT = `${API_BASE_URL}/highlights/create`;

        try {
            const headers = setAuthHeaders(true);
            const response = await axios.post(CREATE_HIGHLIGHT_ENDPOINT,highlightData,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },loadHighlights: async function(setAuthenticationState,authState,preferredBook,highlightId,loadedHighlightIds) {
        // const LOAD_HIGHLIGHTS_ENDPOINT = `${API_BASE_URL}/highlights/load`;

        let url = `${API_BASE_URL}/highlights/load`;

        if (highlightId) {
            url += `${calcPrefix(url)}highlightId=${highlightId}`;
        }

        if (loadedHighlightIds && loadedHighlightIds.length > 0) {
            url += `${calcPrefix(url)}loadedHighlightIds=${loadedHighlightIds.join(',')}`;
        }

        try {
            const headers = setAuthHeaders();
            const response = await axios.get(url,{
                withCredentials:true,
                headers:headers,
                params: {
                    preferredBook: preferredBook,
                }
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },likePost: async function(setAuthenticationState,authState,postData) {
        let url = `${API_BASE_URL}/highlights/like`;

        try {
            const headers = setAuthHeaders();
            const response = await axios.post(url,postData,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    },unlikePost: async function(setAuthenticationState,authState,highlightId) {
        let url = `${API_BASE_URL}/highlights/unlike`;
        url += `${calcPrefix(url)}highlightId=${highlightId}`;

        try {
            const headers = setAuthHeaders();
            const response = await axios.delete(url,{
                withCredentials:true,
                headers:headers,
            });
            return {data: response.data, errorMsg: null};
        } catch (error) {
            return errorHandler(error,setAuthenticationState,authState);
        }
    }
}

export default HighlightService;