import React from "react";
import { CiWarning } from "react-icons/ci";

const ErrorDisplay = () => {
    return(<>
        <div style={{width: '100%',height:'200px',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'16px'}}>
                <div style={{display:'flex'}}>
                    <div style={{paddingRight: '5px',paddingTop:'3px'}}><CiWarning /></div><div>There was an issue loading event data.</div>
                </div>
            </div>
    </>);
}

export default ErrorDisplay;