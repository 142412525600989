import React from 'react';
import { useNavigate } from 'react-router-dom';
import { lightGray } from '../../styles/Styles';
import ErrorDisplay from './ErrorDisplay';
import { formatEventDate } from '../../utils';
import { EventItemTeamMain, EventTeamItemSidebar, GolfMain, EventItemIndividualSidebar } from '.';

const EventIndividualSummary = ({record}) => {

    let navigate = useNavigate();

    if (!record) {
        return(
            <>
                <ErrorDisplay />
            </>
        )
    }

    const {
        event_id,
        event_datetime,
        event_name,
    } = record;

    const handleClick = () => {
        navigate(`/event/${event_id}`);
    }

    return(
        <div onClick={handleClick} style={{cursor:'pointer',backgroundColor:lightGray,padding:'10px',borderRadius:'10px'}}>
            <h2 style={{marginTop:'5px'}}>{event_name}</h2>
            <p style={{marginBottom:'5px'}}>{formatEventDate(event_datetime,true)}</p>
        </div>
    );
}

const EventItem = ({record,selectedOdds,summaryOnly,handleBetSelection}) => {

    const { event_type } = record;

    if (record && record.league_abbreviation === 'NFL') {
        return(
            <>
                <EventIndividualSummary record={record} />
            </>
        )
    }

    if (!event_type || event_type === 'team') {

        if (!selectedOdds) {
            return (
                <>
                    <EventItemTeamMain record={record} handleBetSelection={handleBetSelection}/>
                </>
            )
        }

        else {
            return(
                <>
                    <EventTeamItemSidebar record={record} selectedOdds={selectedOdds} />        
                </>
            );
        }
    } else if (event_type === 'individual') {

        if (summaryOnly) {
            return(
                <>
                    <EventIndividualSummary record={record} />
                </>
            );
        }
        if (!selectedOdds) {
            return(
                <>
                    <GolfMain record={record} />
                </>
            );
        } else if (selectedOdds) {
            return(
                <>
                    <EventItemIndividualSidebar record={record} selectedOdds={selectedOdds} />
                </>
            );
        }
    } 
}

export default EventItem;
