import React, { useMemo } from 'react';
import { createEditor, Editor, Range, Transforms, Text , Path, last} from 'slate';
import { Editable, Slate, withReact, DefaultElement, ReactEditor } from 'slate-react';
import areEqual from "deep-equal";
import UserService from '../api/userService';
import PostService from '../api/postService';
import {useDebounce} from 'use-debounce';
import { borderColor, primaryColor } from '../styles/Styles';
import styled from 'styled-components';

const SearchBox = styled.div`
    // min-height: 50px;
    width: 100%;
    background-color: white;
    border: 1px solid ${borderColor};
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
`

const SearchResult = styled.div`
    height: 40px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;

    &:hover {
        background-color: ${borderColor};
    }
`

const SearchContent = ({editor,insertTag,currentMention,debouncedCurrentMention,setAuthenticationState,authState,closeSearch}) => {

  const [users,setUsers] = React.useState([]);
  const [hashtags,setHashtags] = React.useState([]);

  React.useEffect(() => {
      
      const searchMentions = async () => {
          if (debouncedCurrentMention.startsWith('@')) {
              const viewedUsername = debouncedCurrentMention.substring(1);
              if (!viewedUsername) return;
              try {
                  const {data,errorMsg} = await UserService.searchUsers(setAuthenticationState,authState,viewedUsername);
                  if (data) {
                      setUsers(data.users);
                  } else {
                      console.log("API Error:",errorMsg);
                  }
                  
              } catch (error) {
                  console.log(error);
              }
          } else if (debouncedCurrentMention.startsWith('#')) {
              const hashtag = debouncedCurrentMention.substring(1);
              //TODO: Make API call
              try {
                const {data,errorMsg} = await PostService.searchHashtags(setAuthenticationState,authState,hashtag);
                if (data) {
                  setHashtags(data.hashtags);
                } else {
                  console.log("API Error:",errorMsg);
                }
              } catch (error) {
                console.log(error);
              }
          }
      }
      
      searchMentions();
      
  },[debouncedCurrentMention,setUsers,setAuthenticationState,authState]);

  return(
      <SearchBox>
          
          <SearchResult style={{borderTop: `1px solid ${borderColor}`}} onClick={(event) => {
            event.preventDefault();
            insertTag(editor,currentMention);
            closeSearch();
          }} >{currentMention}</SearchResult>
          {users.map((record,index) => (
              <SearchResult key={index} onClick={(event) => {
                event.preventDefault();
                insertTag(editor,currentMention.substring(0,1)+record.username);
                closeSearch();
              }}><span style={{fontWeight:'bold',paddingRight:'5px'}}>{record.full_name}</span>@{record.username}</SearchResult>
          ))}
          {hashtags.map((record,index) => (
            <SearchResult key = {index} onClick={(event) => {
              event.preventDefault();
              insertTag(editor,currentMention.substring(0,1) + record.hashtagText);
              closeSearch();
            }} >#{record.hashtagText}</SearchResult>
          ))}
          
      </SearchBox>
  );
}

const InitialValue = [
  {
    type: "paragraph",
    children: [
      {text:''},
    ]
  }
]

function renderLeaf({ attributes, children, leaf }) {
  let el = <>{children}</>;

  if (leaf.bold) {
    el = <strong>{el}</strong>;
  }

  if (leaf.color) {
    el = <span style={{ color: leaf.color }}>{el}</span>;
  }

  if (leaf.code) {
    el = <code>{el}</code>;
  }

  if (leaf.italic) {
    el = <em>{el}</em>;
  }

  if (leaf.underline) {
    el = <u>{el}</u>;
  }

  if (leaf.type === 'link') {
    el = (
      <a {...attributes} href={leaf.url} target="_blank" rel="noopener noreferrer">
        {el}
      </a>
    );
  }

  return <span {...attributes}>{el}</span>;
}


function renderElement(props) {
  const { element, children, attributes } = props;
  switch (element.type) {
    case "paragraph":
      return <p {...attributes}>{children}</p>;
    case "h1":
      return <h1 {...attributes}>{children}</h1>;
    case "h2":
      return <h2 {...attributes}>{children}</h2>;
    case "h3":
      return <h3 {...attributes}>{children}</h3>;
    case "h4":
      return <h4 {...attributes}>{children}</h4>;
    case "a":
        return <a {...attributes} href={element.url}>{children}</a>;
    default:
      // For the default case, we delegate to Slate's default rendering. 
      return <DefaultElement {...props} />;
  }
}

function getActiveStyles(editor) {
  const activeMarks = Editor.marks(editor) || {};
  const activeStyles = new Set(Object.keys(activeMarks));
  const activeColor = activeMarks.color;

  if (activeColor === 'blue') {
    activeStyles.add('blue'); // Assuming 'blue' is the name of the color style
  }

  return activeStyles;
}

function setBold(editor) {
  const style = "bold";
  const colorStyle = "color";
  const activeStyles = getActiveStyles(editor);
  if (!activeStyles.has(style)) {
    
      Editor.addMark(editor, style, true);
      Editor.addMark(editor,colorStyle , primaryColor);

  }
}



function replaceRecentMention(editor, replacementText) {
  
  if (!editor.selection || Range.isCollapsed(editor.selection)) {
    const { anchor } = editor.selection;
    const textBefore = Editor.string(editor, { anchor: { path: anchor.path, offset: 0 }, focus: anchor });
    const wordsBeforeCursor = textBefore.split(/\s+/);
    const reversedWords = wordsBeforeCursor.slice().reverse();
    const reverseIndex = reversedWords.findIndex(word => word.startsWith(replacementText.substring(0,1)));

    if (reverseIndex !== -1) {
      // Found a word starting with '@' or '#'... technically, the first character of whatever was passed as 'replacementText' which should only get called with something starting with @ or #
      const atWordIndex = reversedWords.length - reverseIndex - 1;
      const atWord = wordsBeforeCursor[atWordIndex];
      const startOffset = wordsBeforeCursor.slice(0, atWordIndex).join(' ').length + (atWordIndex > 0 ? 1 : 0);
      const endOffset = startOffset + atWord.length;

      //define range we're replacing
      const atWordRange = {
        anchor: { path: anchor.path, offset: startOffset },
        focus: { path: anchor.path, offset: endOffset },
      };

      //select and insert text
      Transforms.select(editor, atWordRange);
      // Transforms.delete(editor);
      Transforms.insertText(editor, replacementText);
      
      //update anchor and focus
      const newEndOffset = startOffset + replacementText.length;
      const newRange = {
        anchor: { path: anchor.path, offset: startOffset },
        focus: { path: anchor.path, offset: newEndOffset },
      };

      //apply styling
      Transforms.select(editor, newRange);
      Editor.addMark(editor, 'bold', true);
      Editor.addMark(editor, 'color', primaryColor);  

      //define range to update
      const spaceOffset = newEndOffset + 1;
      const newRangeSpace = {
        anchor: {path: anchor.path, offset: newEndOffset},
        focus: {path: anchor.path, offset: spaceOffset},
      };

      //select and insert text
      Transforms.select(editor, newRangeSpace);
      Transforms.insertText(editor,' ');
      

      //select and apply styling
      Transforms.select(editor,newRangeSpace);
      Editor.removeMark(editor, 'bold');
      Editor.removeMark(editor, 'color');

      // Optionally, collapse the selection to the end to mimic typing behavior
      Transforms.collapse(editor, { edge: 'end' });

      setTimeout(() => {
        ReactEditor.focus(editor);
        }, 0);

    }
  }
}


// Example function to check if a character is not a letter or a number
function isNonAlphaNumeric(char) {
  const nonAlphaNumericRegex = /[^a-zA-Z0-9]/;
    return nonAlphaNumericRegex.test(char);
}

function isInvalidUrlCharacter(char) {
  const invalidUrlCharacterRegex = /[^a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]/;
  return invalidUrlCharacterRegex.test(char);
}

const findUrlsInText = (text) => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

  const matches = text.match(urlRegex);

  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : [];
};

function markUrls(editor,textBefore) {
  
  const activeStyles = getActiveStyles(editor);

  if (isInvalidUrlCharacter(textBefore[textBefore.length - 1])) return;

  if (!activeStyles.has('blue')) {
    const {anchor,focus} = editor.selection;
    
    const urlMatches = findUrlsInText(textBefore);

    urlMatches.forEach(([url,start]) => {
      const end = start + url.length;
      const newRange = {
        anchor: {path: anchor.path, offset: start},
        focus: {path: anchor.path, offset: end},
      }

      Transforms.select(editor,newRange);
      Editor.addMark(editor,'color','blue');
      Transforms.collapse(editor, { edge: 'end' });

      });
  }
}

const BetBoardEditor = ({document,onChange,setAuthenticationState,authState,setPostData,resetText,setResetText}) => {

  const editor = useMemo(() => withReact(createEditor()), []);
  const [selection,setSelection] = React.useState(useSelection(editor));
  const [currentMention,setCurrentMention] = React.useState('');
  const [debouncedCurrentMention] = useDebounce(currentMention,250);
  const [searchVisible,setSearchVisible] = React.useState(false);
  const [endingUrl,setEndingUrl] = React.useState(false);
  

  function useSelection(editor) {
    const [selection,setSelection] = React.useState(editor.selection);
    const setSelectionOptimized = React.useCallback((newSelection) => {
      if (areEqual(selection,newSelection)) {
        return;
      }
      setSelection(newSelection)
    },[setSelection,selection]);

    return [selection,setSelectionOptimized];
  }

  

  const closeSearch = () => {
    setCurrentMention('');
    setSearchVisible(false);
    
    // Using Slate's API to focus the editor
    setTimeout(() => {
      ReactEditor.focus(editor);
    }, 0);
  }


  const onChangeHandler = React.useCallback((document) => {

    onChange(document);
    const {anchor} = editor.selection;
    setSelection(anchor);
    const textBefore = Editor.string(editor, { anchor: { path: anchor.path, offset: 0 }, focus: anchor });
    if (!endingUrl) markUrls(editor,textBefore);
    setCurrentMention(textBefore)
    const plainText = Editor.string(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: [Infinity, Infinity], offset: 0 } });
    const richText = JSON.stringify({richText:document});
  
    const postWords = plainText.split(/[\s,.;]+/);
    const mentionsArray = postWords.filter(word => word.startsWith('@'));
    const mentionsArrayNoPrefix = mentionsArray.map(word => word.substring(1));

    const hashtagArray = postWords.filter(word => word.startsWith('#'));
    const hashtagArrayNoPrefix = hashtagArray.map(word => word.substring(1));
    
    setPostData(prevPostData => ({
      ...prevPostData,
      postText:plainText,
      postTextRich:richText,
      mentions:mentionsArrayNoPrefix.length > 0 ? mentionsArrayNoPrefix : null,
      hashtags:hashtagArrayNoPrefix.length > 0 ? hashtagArrayNoPrefix : null,
    }));


    
  },[editor.selection,onChange,setSelection,setCurrentMention,setBold,setSearchVisible,endingUrl]);


  function removeBold(editor,eventKey,force) {
  
    const style = "bold";
    const colorStyle = "color";
    const activeStyles = getActiveStyles(editor);

    if (force && (activeStyles.has(style) || activeStyles.has(colorStyle)) && (!isInvalidUrlCharacter(eventKey) || eventKey === 'Enter')) {
      Editor.removeMark(editor, style);
      Editor.removeMark(editor, colorStyle);
    } else if ((activeStyles.has(style) || activeStyles.has(colorStyle)) && !activeStyles.has('blue')) {
      Editor.removeMark(editor, style);
      Editor.removeMark(editor, colorStyle);
    } else if (isInvalidUrlCharacter(eventKey)|| eventKey === 'Enter') {
      setEndingUrl(true);
    }
  }

  React.useEffect(() => {
    
    if (endingUrl) {
      Editor.removeMark(editor,'color');
      setEndingUrl(false);
      
    }
  },[editor,endingUrl,setEndingUrl]);


  const onKeyDown = React.useCallback(
    (event) => {
      
      if (event.key === '@' || event.key === '#') {  
        setBold(editor);
        setSearchVisible(true);
      // } else if (event.key === ' ') {
      } else if (isNonAlphaNumeric(event.key) || event.key === 'Enter') {
        removeBold(editor,event.key);
        setSearchVisible(false);
      } 
      
    },
    [editor,setSearchVisible]
  );

  React.useEffect(() => {
    
    if (editor.selection) {

      const {anchor} = editor.selection;
      const textBefore = Editor.string(editor, { anchor: { path: anchor.path, offset: 0 }, focus: anchor });
      if (textBefore.length === 0) {
        Transforms.select(editor,{ anchor: { path: anchor.path, offset: 0 }, focus: anchor });
        Editor.removeMark(editor,'color');
        Editor.removeMark(editor,'bold');
      }
    }
  },[editor.selection]);

  
  React.useEffect(()=>{
    
    const resetEditorToInitialValue = (editor, initialValue) => {
      // Replace the entire editor content with the initial value
      Editor.withoutNormalizing(editor, () => {
        // Remove all existing nodes
        editor.children = [...initialValue];
        // Reset the selection to the start of the document
        editor.selection = { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } };
      });
      // You might need to manually call onChange or update any related state here
    };
    
    if (resetText) {
      resetEditorToInitialValue(editor,InitialValue);
      setResetText(false);
      setPostData(prevPostData => ({
        postText:'',
        postTextRich:'',
        replyToPostId: prevPostData.replyToPostId,
        eventId: prevPostData.eventId,
        teamId: prevPostData.teamId,
        playerId: prevPostData.playerId,
        mentions:null,
        hashtags: null,
        imageUrl:null,
        imageFile:null,
        videoUrl:null,
        videoFile:null,
        highlightId: prevPostData.highlightId
      }));
    }
  },[resetText,setResetText,setPostData]);

  return(
    <Slate editor={editor} initialValue={document} onChange={onChangeHandler} >
      <Editable 
        renderElement={renderElement} 
        renderLeaf={renderLeaf} 
        onKeyDown={onKeyDown}
        style={{outline:'none',minHeight: '60px',overflowWrap:'anywhere',paddingRight:'10px'}} 
        onClick={closeSearch} 
        placeholder="What's your bet?"
        autoCapitalize='on'
        
        />
      {searchVisible && currentMention.length > 1 && (currentMention.startsWith('@') || currentMention.startsWith('#')) && (
        <SearchContent editor={editor} insertTag={replaceRecentMention} currentMention={currentMention} debouncedCurrentMention={debouncedCurrentMention} setAuthenticationState={setAuthenticationState} authState={authState} closeSearch={closeSearch}/>
      )}
    </Slate>
  );
}

const TextEditor = ({setAuthenticationState,authState,setPostData,resetText,setResetText}) => {
  const [document,updateDocument] = React.useState(InitialValue);
  const [resetKey, setResetKey] = React.useState(0);

  return(
      <BetBoardEditor 
        document={document} 
        onChange={updateDocument} 
        setAuthenticationState={setAuthenticationState} 
        authState={authState} 
        setPostData={setPostData}
        resetText={resetText}
        setResetText={setResetText}
        />
  );

}

export default TextEditor;
