import React from "react";
import styled from 'styled-components';
import { AuthenticationContext } from "../../components";
import { SwipeService } from "../../api";
import { useNavigate, Link } from "react-router-dom";
import { borderColor, lightGray, secondaryColor } from "../../styles/Styles";
import { golfScore, roundOdds, roundScore, shortDate, formatDateToSeconds } from "../../utils";
import BeatLoader from 'react-spinners/BeatLoader';
import DateFilter from "./DateFilter";
import { Loader } from "../../styles/Styles";


const LeaderboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8em;
`

const TitleRow = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
    background-color: ${lightGray};
`

const Row = styled.div`
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${borderColor};
    align-items: center;
    cursor: pointer;
    background-color: ${props => props.$EventStatus !=='complete' ? 'rgb(0,202,149,0.3)' : 'white'};
`

const StyledLink = styled(Link)`
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingSpinnerContainer = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Hit = () => {
    return(
        <div style={{height: '20px',width:'20px',backgroundColor:'#39FF14',borderRadius:'999px'}}></div>
    )
}

const Miss = () => {
    return(
        <div style={{height: '20px',width:'20px',backgroundColor:'red',borderRadius:'999px'}}></div>
    )
}

const Push = () => {
    return(
        <div style={{height: '20px',width:'20px',backgroundColor:lightGray,borderRadius:'999px'}}></div>
    )
}


function selectHitIndicator(hit,price,eventStatus) {

    if (eventStatus !=='complete') {
        return(<></>);
    } else if (hit === null && price === null) {
        return(<></>);
    } else if (hit === null & price !== null) {
        return(<Push />);
    } else if (Number(hit) === 1) {
        return(<Hit />);
    } else if (Number(hit) === 0) {
        return(<Miss />);
    } else {
        return(<></>);
    }
}

function selectTeamName(record) {
    if (record.league_abbreviation === 'MLB') {
        if (!record.moneyline_team_id) {
            return;
        }
        else if (record.moneyline_team_id === record.away_team_id) {
            return record.away_team_name;
        } else {
            return record.home_team_name;
        }
    } else {
        if (!record.spread_team_id) {
            return;
        }
        else if (record.spread_team_id === record.away_team_id) {
            return record.away_team_name;
        } else {
            return record.home_team_name;
        
        }
    }
}

function getChoiceAbbreviation(choice) {
    if (choice === 'over') return 'O';
    else if (choice === 'under') return 'U';
}

const filterOptions = {
    WEEK: 0,
    MONTH: 1,
    ALL: 2
};

function getStartDate(choice) {

    let startDate;
    const currentDate = new Date();

    switch(choice) {
        case filterOptions.WEEK:
            startDate = new Date(currentDate)
            startDate.setDate(currentDate.getDate() - 7);
            return formatDateToSeconds(startDate);
        case filterOptions.MONTH:
            startDate = new Date(currentDate)
            startDate.setDate(currentDate.getDate() - 30);
            return formatDateToSeconds(startDate);
        case filterOptions.ALL:
            return null;
        default:
            return null;
    }
}

const Scorecard = ({viewedUsername,page}) => {

    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [results,setResults] = React.useState([]);
    const [userSwipeRecord,setUserSwipeRecord] = React.useState('');
    const [userSwipeReturn,setUserSwipeReturn] = React.useState(0);
    const [loading,setLoading] = React.useState(false);
    const [isEnd,setIsEnd] = React.useState(false);
    const [filterChoice,setFilterChoice] = React.useState(filterOptions.WEEK);
    const [reloading,setReloading] = React.useState(false);

    let navigate = useNavigate();

    React.useEffect(() => {
        setResults([]);
        setReloading(true);
    },[viewedUsername,filterChoice]);

    React.useEffect(() => {
        setReloading(true)
    },[page]);

    async function loadResults() {
        
        // if (loading || isEnd) return;
        let loadedSwipeIds;
        
        try {
            setLoading(true);
            
            if (results.length > 0) {
                loadedSwipeIds = results.map(result => result.swipe_id);
            }
            
            const startDate = getStartDate(filterChoice);
            const {data,errorMsg} = await SwipeService.loadSwipeResultsV2(setAuthenticationState,authState,viewedUsername,loadedSwipeIds,startDate);
            
            if (!data) {
                console.log("API Error:",errorMsg);
            } else if (data.results.length === 0) {
                setIsEnd(true);
            }else {
                setResults(prevResults => [...prevResults, ...data.results]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    async function loadUserSwipeRecord() {
        
        try {
            const startDate = getStartDate(filterChoice);
            const {data,errorMsg} = await SwipeService.loadUserSwipeRecordV2(setAuthenticationState,authState,viewedUsername,startDate);
            
            if (!data) {
                console.log("API Error:",errorMsg);
            } else {
                setUserSwipeRecord(data.results[0].record);
                setUserSwipeReturn(data.results[0].total_return);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (reloading) {
            
            loadResults();
            loadUserSwipeRecord();
            setReloading(false);
        }
    },[reloading]);

    function goToEvent(event,eventId) {
        event.preventDefault();
        navigate(`/event/${eventId}`);
    }
    
    return(
        <LeaderboardContainer>
            <h1>Scorecard / <StyledLink to={`/${viewedUsername}`}>@{viewedUsername}</StyledLink></h1>
            <DateFilter 
                filterOptions={filterOptions}
                filterChoice={filterChoice}
                setFilterChoice={setFilterChoice}
                />
            {loading && results.length === 0 && (
                <div style={{width:'100%',height:'100px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {results.length > 0 && (
                <>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'16px',paddingBottom:'10px'}}>
                        <div style={{paddingBottom:'1px'}}>Total Return: {userSwipeReturn}</div>
                        <div>Overall Record: {userSwipeRecord}</div>
                    </div>
                    <div style={{textDecoration:'underline',cursor:'pointer',fontSize:'16px',paddingBottom:'10px'}}><Link style={{color:'black'}} to={'/slate-swipe'}>Leaderboard</Link></div>
                    <div style={{paddingBottom:'10px',display:'flex',alignItems:'center'}}>
                        <div style={{width:'20px',height:'15px',marginRight:'5px',backgroundColor:'rgb(0,202,149,0.3)',borderRadius:'5px'}}></div>
                        <div> = In Progress</div>
                    </div>
                    <TitleRow>
                        <div style={{width:'35px',flexGrow:'0'}}></div>
                        <div style={{flex:'1'}}></div>
                        <div style={{width:'120px',textAlign:'center'}}>Winner</div>
                        <div style={{width:'80px',textAlign:'center'}}>O/U</div>
                    </TitleRow>
                    {results.map((result,index) => (
                        <Row $EventStatus={result.event_status} key={index} style={{fontSize:'0.8em'}} onClick={(event) => goToEvent(event,result.event_id)}>
                            <div style={{width:'35px'}}>{shortDate(result.event_datetime)}</div>
                            <div style={{flex:'1'}}>
                                <div>{result.away_name} vs. {result.home_name}</div>
                                <div style={{display:'flex'}}>
                                    <div>({result.league_abbreviation === 'PGA' ? golfScore(result.away_score) : roundScore(result.away_score)}</div>
                                    <div>{result.league_abbreviation === 'PGA' ? ',' : '-'}</div>
                                    <div>{result.league_abbreviation === 'PGA' ? golfScore(result.home_score) : roundScore(result.home_score)})</div>
                                </div>
                                
                            </div>
                            {/* <div style={{flex:'1'}}>{roundScore(result.away_score)} - {roundScore(result.home_score)}</div> */}
                            <div style={{display:'flex',alignItems:'center',boxSizing:'border-box',paddingLeft:'10px',paddingRight:'5px'}}>
                                <div style={{width:'65px',textAlign:'left'}}>
                                    {['MLB','PGA','NHL'].includes(result.league_abbreviation) ? result.moneyline_team_name : result.spread_team_name}</div>
                                <div style={{width:'35px',display:'flex',flexDirection:'column',alignItems:'end',boxSizing:'border-box',paddingRight:'10px'}}>
                                    {['MLB','PGA','NHL'].includes(result.league_abbreviation) && (
                                        <div>{roundOdds(result.moneyline_price,0)}</div>
                                    )}
                                    {!['MLB','PGA','NHL'].includes(result.league_abbreviation) && (
                                        <>
                                            <div>{roundOdds(result.spread_point,1)}</div>
                                            <div>{roundOdds(result.spread_price,0)}</div>
                                        </>
                                    )}
                                </div>
                                <div style={{width:'20px'}}>{selectHitIndicator(['MLB','PGA','NHL'].includes(result.league_abbreviation) ? result.moneyline_hit : result.spread_hit,['MLB','PGA','NHL'].includes(result.league_abbreviation) ? result.moneyline_price : result.spread_price,result.event_status)}</div>
                            </div>
                            <div style={{display:'flex',alignItems:'center',boxSizing:'border-box'}}>
                                <div style={{width:'20px',textAlign:'right',fontSize:'14px',paddingRight:'5px'}}>{getChoiceAbbreviation(result.total_choice)}</div>
                                <div style={{width:'40px',display:'flex',flexDirection:'column',alignItems:'end',boxSizing:'border-box',paddingRight:'10px'}}>
                                    <div>{roundOdds(result.total_point,1)}</div>
                                    <div>{roundOdds(result.total_price,0)}</div>
                                </div>
                                <div style={{width:'20px'}}>{selectHitIndicator(result.total_hit,result.total_price,result.event_status)}</div>
                            </div>
                        </Row>
                    ))}

                    {loading && (
                        <LoadingSpinnerContainer>
                            <BeatLoader />
                        </LoadingSpinnerContainer>
                    )}
                    
                </>
            )}
        </LeaderboardContainer>
    );
}

export default Scorecard;