import React from 'react';
import styled from 'styled-components';
import { fileToUrl } from '../../utils';
import { FaPlay } from "react-icons/fa";
import Avatar from '../Avatar';
import EventItemTeamHighlight from '../EventItem/EventItemTeamHighlight';
import { AuthenticationContext } from '../AuthenticationContext';
import { EventService } from '../../api';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../styles/Styles';
import { BiCommentDetail } from "react-icons/bi";
import { IoMdShareAlt } from "react-icons/io";
import { RiShareForwardLine } from "react-icons/ri";
import { BiRepost } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import { SlideUpContainer,SlideUpContentWrapper } from '../../styles/Styles';
import { CloseButton } from '../Buttons';
import Comment from '../NewPosts/Comment';
import Feed from '../Feed';
import HighlightReactions from './HighlightReactions';
import { HighlightService } from '../../api';
import { MOBILE_WIDTH } from '../../config';


const HighlightWrapper = styled.div`

    height: 100%;
    width: 100%;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const HighlightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 660px;
        width: 350px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH + 1}px) {
        height: 100%;
        width: 100%;
    }
`

const HighlightVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        border-radius: 20px;
    }

`

const DescriptionContainer = styled.div`
    color: white;
    font-size:1em;
    display: flex;
    align-items: center;
    font-weight: bold;
    // background: rgba(0,0,0,0.5);
`

const UserContainer = styled.div`
    color: white;
    font-size:1.05em;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
`

const FollowButton = styled.button`
    border: none;
    padding: 5px 20px 5px 20px;
    border-radius: 999px;
    background-color: white;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
`

const useIntersectionObserver = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);
  
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
        
      }, options);
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
  
    return isIntersecting;
  };

function calculateReactionCount (oldReactionValue,newReactionValue,oldReactionCountValue) {
    if (oldReactionValue && !newReactionValue) {
        return Math.max(oldReactionCountValue - 1,0);
    } else if (!oldReactionValue && newReactionValue) {
        return oldReactionCountValue + 1;
    } else if (!oldReactionValue && !newReactionValue) {
        return oldReactionCountValue;
    }
}

const Highlight = ({highlightId,highlightData,setHasInteracted,hasInteracted,previousLocation}) => {

    const {video_filename,description,profile_photo_filename,username,event_id, reply_count} = highlightData;

    const videoRef = React.useRef(null);
    const [isPlaying,setIsPlaying] = React.useState(false);
    const isVisible = useIntersectionObserver(videoRef, { threshold: 0.75 });
    const [eventRecord,setEventRecord] = React.useState({});
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showComment,setShowComment] = React.useState(false);
    const [showShare,setShowShare] = React.useState(false);
    const [showRePost,setShowRePost] = React.useState(false);
    const [showReaction,setShowReaction] = React.useState(false);
    const [eventLoading,setEventLoading] = React.useState(true);
    let navigate = useNavigate();

    const handleWaiting = () => {
        setIsLoading(true);
      };
    
      const handleCanPlay = () => {
        setIsLoading(false);
      };

    function closeComment(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowComment(false);
    }

    function openComment(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowComment(true);
    }

    function closeShare(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowShare(false);
    }

    function openShare(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowShare(true);
    }

    function closeRePost(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowRePost(false);
    }

    function openRePost(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowRePost(true);
    }

    function closeReaction(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowReaction(false);
    }

    function openReaction(event) {
        event.preventDefault();
        event.stopPropagation();
        setShowReaction(true);
    }


    const togglePlay = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHasInteracted(true);
        if (videoRef.current) {
          if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play();
            setIsPlaying(true);
          } else {
            videoRef.current.pause();
            setIsPlaying(false);
          }
        }
      };

    React.useEffect(() => {
        if (isVisible) {
        // if (isVisible && hasInteracted) {
          videoRef.current.play();
          setIsPlaying(true);
          navigate(`/highlights/${highlightId}`,{replace:true, state:{highlightId:highlightId,previousLocation:previousLocation}});
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }, [isVisible]);
    // }, [isVisible, hasInteracted]);

    React.useEffect(() => {
        const videoElement = videoRef.current;
    
        const handleEnded = () => {
          videoElement.currentTime = 0;
          videoElement.play();
        };
    
        if (isVisible) {
          videoElement.play();
          setIsPlaying(true);
        } else {
          videoElement.pause();
          setIsPlaying(false);
        }
    
        videoElement.addEventListener('ended', handleEnded);
    
        return () => {
          videoElement.removeEventListener('ended', handleEnded);
        };
    }, [isVisible]);
    // temporary - eventually get event record from highlight api
    
    React.useEffect(() => {
        const LoadEvent = async () => {
            try {
                const preferredBook = loggedInProfileData.preferredBook;
                const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,null,event_id,preferredBook);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    setEventRecord(data.events);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setEventLoading(false);
            }
        }
        if (loggedInProfileData.preferredBook && event_id) {
            LoadEvent();
        }
        
    },[event_id,setEventRecord,loggedInProfileData]);

    const [reactions,setReactions] = React.useState({
        moneybag:highlightData.user_highlight_like_reaction === 'moneybag' ? true : false,
        ice:highlightData.user_highlight_like_reaction === 'ice' ? true : false,
        like:highlightData.user_highlight_like_reaction === 'like' ? true : false,
        dislike:highlightData.user_highlight_like_reaction === 'dislike' ? true : false,
        laugh:highlightData.user_highlight_like_reaction === 'laugh' ? true : false,
    });

    const [reactionCount,setReactionCount] = React.useState({
        moneybag:highlightData.moneybag_reaction_count ? highlightData.moneybag_reaction_count : 0,
        ice:highlightData.ice_reaction_count ? highlightData.ice_reaction_count : 0,
        like:highlightData.like_reaction_count ? highlightData.like_reaction_count : 0,
        dislike:highlightData.dislike_reaction_count ? highlightData.dislike_reaction_count : 0,
        laugh:highlightData.laugh_reaction_count ? highlightData.laugh_reaction_count : 0,
    });

    const [totalReactions,setTotalReactions] = React.useState(0);

    async function handleReaction(event,reaction) {
        event.preventDefault();
        event.stopPropagation();

        const updatedReactions = Object.keys(reactions).reduce((attribute, key) => {
            attribute[key] = key === reaction ? !reactions[key] : false;
            return attribute;   
        }, {});

        const updatedReactionCount = Object.keys(reactionCount).reduce((attribute, key) => {
            attribute[key] = calculateReactionCount(reactions[key],updatedReactions[key],reactionCount[key]);
            return attribute;
        }, {});

        setReactions(updatedReactions);
        setReactionCount(updatedReactionCount);

        if (Object.values(updatedReactionCount).reduce((acc, curr) => acc + curr, 0) > 0) {
            try {
                const postData = {highlightId:highlightId,reaction:reaction};
                const {data,errorMsg} = await HighlightService.likePost(setAuthenticationState,authState,postData);
                if (!data) {
                    console.log("API error:", errorMsg);
                }
            } catch (error) {
                console.log("error");
            }
        } else {
            try {
                const {data,errorMsg} = await HighlightService.unlikePost(setAuthenticationState,authState,highlightId);
                if (!data) {
                    console.log("API error:", errorMsg);
                }
            } catch (error) {
                console.log("error");
            }
        }
    }

    React.useEffect(() => {
        setTotalReactions(Object.values(reactionCount).reduce((acc, curr) => acc + curr, 0));
    },[reactionCount]);

    return(
        <HighlightWrapper>
        <HighlightContainer onClick={togglePlay} >
            <HighlightVideo controls={false} ref={videoRef} playsInline={true} onWaiting={handleWaiting} onCanPlay={handleCanPlay} >
                <source src={fileToUrl(video_filename)} type="video/mp4"/>
            </HighlightVideo>
            {!isPlaying && !isLoading && (
                <div onClick={togglePlay} style={{top:'50%',left:'50%',position:'absolute',width:'50px',height:'50px'
                    ,border:'2px solid white',borderRadius:'999px',transform:'translate(-50%,-50%)',color:'white',fontSize:'2em'
                    ,fontWeight:'bold'
                    ,display:'flex'
                    ,alignItems:'center'
                    ,justifyContent:'center'
                    ,zIndex:'1'}}>
                    <div style={{width:'32px',height:'32px',paddingLeft:'7px'}}>
                        <FaPlay />
                    </div>
                </div>
            )}
            {!isLoading && (
                <div style={{position:'absolute',zIndex:'2',bottom:'0px',left:'0px',width:'100%',display:'flex',flexDirection:'column',paddingLeft:'5px',paddingRight:'5px',boxSizing:'border-box',paddingBottom:'25px'}}>
                    <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <HighlightAction label={"Share"} clickAction={openShare}/>
                            <HighlightAction label={"Comment"} clickAction={openComment} metric={reply_count} />
                            <HighlightAction label={"Repost"} clickAction={openRePost} />
                            <HighlightAction label={"React"} clickAction={openReaction} metric={totalReactions}/>
                        </div>
                    </div>
                    {event_id && !eventLoading && (<EventItemTeamHighlight record={eventRecord[0]} />)}
                    {event_id && eventLoading &&  (
                        <div style={{display:'flex',width:'100%',height:'100px',position:'relative'}}>
                            <Loader />
                        </div>

                    )}
                    <div style={{display:'flex',width:'100%',flexDirection:'column',paddingLeft:'10px',paddingRight:'10px'}}>
                        <UserContainer>
                            <div><Avatar imageSize={'40px'} imageSource={fileToUrl(profile_photo_filename)}/></div>
                            <div style={{marginLeft:'5px'}}>@{username}</div>
                            <div style={{marginLeft:'5px'}}><FollowButton>Follow</FollowButton></div>
                        </UserContainer>
                        <DescriptionContainer>
                            {description}
                        </DescriptionContainer>
                    </div>
                </div>
            )}
            {showComment && (
                <CommentAction close={closeComment} highlightId={highlightId}/>
            )}
            {showShare && (
                <Share close={closeShare} />
            )}
            {showRePost && (
                <RePost close={closeRePost} />
            )}
            {showReaction && (
                <Reaction
                    close={closeReaction} 
                    reactions={reactions} 
                    handleReaction={handleReaction}
                    reactionCount={reactionCount}
                    />
            )}
        </HighlightContainer>
        </HighlightWrapper>
    );
}

const HighlightAction = ({label,clickAction,metric}) => {

    function chooseLabel(label) {
        switch (label) {
            case "Comment":
                return <div style={{fontSize:'20px',translate:'0px 3px'}}><BiCommentDetail /></div>;
            case "Share":
                return <div style={{fontSize:'26px',translate:'0px 3px'}}><RiShareForwardLine /></div>;
            case "Repost":
                return <div style={{fontSize:'26px',translate:'0px 3px'}}><BiRepost /></div>;
            case "React":
                return <div style={{fontSize:'26px',translate:'0px 3px'}}><AiOutlineLike /></div>;
            default:
                return <div>X</div>;
        }
    }

    const buttonStyle = {
        width:'40px'
        ,height:'40px'
        ,marginRight:'10px'
        ,marginLeft:'10px'
        ,borderRadius:'999px'
        ,border:'2px solid white'
        ,color:'white'
        ,display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'}
    // console.log('highlightAction:','hello world!');
    return(
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={buttonStyle} onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (clickAction) {
                    clickAction(event);
                }
            }}>
                {chooseLabel(label)}
            </div>
            
            <div style={{color:'white',fontSize:'12px',fontWeight:'bold',height:'25px'}}>
                <div style={{paddingTop:'1px'}}>{metric > 0 ? metric : null}</div>
            </div>
            
        </div>
    );
}

const CommentAction = ({close,highlightId}) => {

    const [feed,setFeed] = React.useState([]);

    return(
        <SlideUpContainer onClick={(event) => {
            // event.preventDefault();
            event.stopPropagation();
        }} >
            <SlideUpContentWrapper $MobileHeight={'80%'}>
                <div style={{padding:'10px'}}>
                    <CloseButton onClick={close} color={'black'} />
                </div>
                <div style={{marginLeft:'5px',marginRight:'5px'}}>
                    <Comment highlightId={highlightId} setFeed={setFeed}/>
                    <Feed feed={feed} setFeed={setFeed} highlightId={highlightId}/>
                </div>
            </SlideUpContentWrapper>
        </SlideUpContainer>
    )
}

const Share = ({close}) => {
    return(
        <SlideUpContainer onClick={close}>
            <SlideUpContentWrapper $MobileHeight={'60%'}>
                <div style={{padding:'10px'}}>
                    <CloseButton onClick={close} color={'black'} />
                </div>
            </SlideUpContentWrapper>
        </SlideUpContainer>
    )
}

const RePost = ({close}) => {
    return(
        <SlideUpContainer onClick={close}>
            <SlideUpContentWrapper $MobileHeight={'60%'}>
                <div style={{padding:'10px'}}>
                    <CloseButton onClick={close} color={'black'} />
                </div>
            </SlideUpContentWrapper>
        </SlideUpContainer>
    )
}

const Reaction = ({close,reactions,handleReaction,reactionCount}) => {

    return(
        <SlideUpContainer onClick={close}>
            <SlideUpContentWrapper $MobileHeight={'50%'} >
                <div style={{padding:'10px'}}>
                    <CloseButton onClick={close} color={'black'} />
                </div>
                <div style={{width:'100%',flex:'1',display:'flex',alignItems:'start',justifyContent:'center'}}>
                    <HighlightReactions reactions={reactions} handleReaction={handleReaction} reactionCount={reactionCount} />
                </div>
            </SlideUpContentWrapper>
        </SlideUpContainer>
    )
}

export default Highlight;
