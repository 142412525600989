import React from "react";

import { S3_BASE_URL } from "./config";
import { primaryColor, secondaryColor } from "./styles/Styles";

const useViewportWidth = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height,setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {width,height};
};

const fileToUrl = (filename) => {
    if (filename) {
        if (filename.includes(S3_BASE_URL)) {
            return filename;
        } else {
            return `${S3_BASE_URL}${filename}`;
        }
    } else {
        return `${S3_BASE_URL}betboard_logo.png`;
    }
}


function shortDate(datetime) {
  // const year = datetime.getFullYear();

  const date = new Date(datetime);

  const month = date.getMonth() + 1; // getMonth() is zero-indexed
  const day = date.getDate();

  return `${month}/${day}`;

}

const formatDateToSeconds = (datetime) => {
  const pad = (num) => num.toString().padStart(2, '0');

  const year = datetime.getFullYear();
  const month = pad(datetime.getMonth() + 1); // getMonth() is zero-indexed
  const day = pad(datetime.getDate());
  const hours = pad(datetime.getHours());
  const minutes = pad(datetime.getMinutes());
  const seconds = pad(datetime.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const formatDate = (datetime) => {

    if (!datetime) {
        return "now";
    }

    const date = new Date(datetime);
    const now = new Date();
  
    // const timezoneOffset = now.getTimezoneOffset();
    // date.setMinutes(date.getMinutes() - timezoneOffset);

    const diffInMillis = now - date;
    const diffInSeconds = diffInMillis / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
  
    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)}s`;
    } else if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)}m`;
    } else if (diffInHours < 24) {
      return `${Math.floor(diffInHours)}h`;
    } else {
      const monthAbbreviation = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const year = date.getFullYear();
      return `${monthAbbreviation} ${day}, ${year}`;
    }
  };

  function formatEventDate(datetime,dayOnly) {
    
    function formatHour(hour) {
      switch (true) {
        case hour === 0:
          return hour + 12;
        case hour > 12:
          return hour - 12;
        default:
          return hour;
      }
    }
    
    if (!datetime) return;

    const date = new Date(datetime);
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' });
    const minutes = date.getMinutes().toString().padStart(2, '0');;
    const hour = date.getHours();
    const day = date.getDate();
    const year = date.getFullYear();
    if (dayOnly) {
      return `${monthAbbreviation} ${day}, ${year}`;
    } else {
      return `${monthAbbreviation} ${day} ${formatHour(hour)}:${minutes}${hour >= 12 ? 'PM' : 'AM'}`;
      // return `${monthAbbreviation} ${day}, ${year} ${formatHour(hour)}:${minutes} ${hour >= 12 ? 'PM' : 'AM'}`;
    }
    
}
 
function checkDate(event_datetime,eventStatus) {

  let currentTime = new Date();
  let eventDatetime = new Date(event_datetime);

  switch (true) {
    case (!eventStatus):
      return formatEventDate(eventDatetime);
    case (eventStatus === 'complete'):
      return "Final";
    case (eventDatetime <= currentTime):
      return "Live Now";
    case (eventDatetime > currentTime):
      return formatEventDate(eventDatetime); 
    default:
      return formatEventDate(eventDatetime);
  }

  // let currentTime = new Date()
  // eventDatetime = new Date(eventDatetime);

  // if (eventDatetime < currentTime) {
  //     return "Live Now";
  // } else {
  //     return formatEventDate(eventDatetime);
  // }
}

function roundScore(score) {
  if (score === undefined || score === null) {
      return null;
  } else {
      return Number(score).toFixed(0);
  }
}

function scoreProfile(profileScoreLikes) {
  switch (true) {
      case (profileScoreLikes < 50):
          // return 'rookie';
          return secondaryColor;
      case (profileScoreLikes < 100):
          // return 'veteran';
          return 'gold';
      case (profileScoreLikes < 200):
          // return 'all-star';
          return 'primaryColor';
      case (profileScoreLikes < 500):
          // return 'legend';
          return 'gold'
      default:
          // return 'hall-of-fame';
          return 'gold';
  }
}

function roundOdds(oddsValue,places,overUnder) {
  
  let prefix;
  if (overUnder === 'over') {
    prefix = 'O';
  } else if (overUnder === 'under') {
    prefix = 'U'
  } else {
    prefix = oddsValue > 0 ? "+" : "";
  }

  if (oddsValue === null || oddsValue === undefined) {
      return "-";
  } else if (oddsValue === 0) {
      return 'Even'
  } else {
      let oddsNumber = Number(oddsValue);
      oddsNumber = oddsNumber.toFixed(places);
    //   if (Math.abs(oddsNumber) > 9999) {
    //     // Format in scientific notation with one digit after the decimal point
    //     oddsNumber = oddsNumber.toExponential(1);
    // } else {
    //     // Round to the nearest integer for smaller numbers
    //     oddsNumber = oddsNumber.toFixed(places);
    // }
      return `${prefix}${oddsNumber}`;
  }
  
}

function golfScore(score) {
  if (score === null) return null;
  if (Number(score) === 0) return 'E';
  return roundOdds(score,0);   
}

export {fileToUrl,
  formatDate,
  scoreProfile, 
  formatDateToSeconds, 
  formatEventDate, 
  checkDate, 
  roundScore, 
  roundOdds, 
  shortDate, 
  golfScore,
  useViewportWidth,

};