import React from 'react';

import { NotificationsFeed,Layout, AuthenticationContext } from '../components';

const Notifications = () => {

    const [notificationsFeed,setNotificationsFeed] = React.useState([]);
    const {setNewNotifications} = React.useContext(AuthenticationContext);

    return(
        <Layout >
            <NotificationsFeed notificationsFeed={notificationsFeed} setNotificationsFeed={setNotificationsFeed} setNewNotifications={setNewNotifications}/>
        </Layout>
    );
}

export default Notifications;
