import React from 'react';
import styled from 'styled-components';
import Highlight from './Highlight';
import { AuthenticationContext } from '../AuthenticationContext';
import { HighlightService } from '../../api';
import { useNavigate,useLocation } from 'react-router-dom';
import { BackButton } from '../Buttons';
import { MOBILE_WIDTH } from '../../config';
import { navbarHeight } from '../../styles/Styles';

const HighlightFeedContainer = styled.div`
    
    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 100%;
        height: calc(100vh - ${navbarHeight}); 
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: black;
    }
    
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    
`

const HighlightContainer = styled.div`
    
    scroll-snap-align:start;
    overflow:hidden;
    width: 100%;
    height: 100%;
`

const HighlightFeed = ({highlightId,previousLocation}) => {

    const [hasInteracted, setHasInteracted] = React.useState(false);
    const {authState,loggedInProfileData,setAuthenticationState} = React.useContext(AuthenticationContext);
    const [highlights,setHighlights] = React.useState([]);
    const [resetFlag,setResetFlag] = React.useState(true);
    let navigate = useNavigate();

    function handleClose(event) {
        event.preventDefault();
        navigate(previousLocation);
    }

    React.useEffect(() => {
        if (highlightId) {
            const visibleIndex = highlights.findIndex(highlights => highlights.highlight_id === highlightId);
            if (visibleIndex >= highlights.length - 3) {
                setResetFlag(true);
            }
        }
    },[highlightId]);

    React.useEffect(() => {
        const LoadHighlights = async () => {
            try {
                const preferredBook = loggedInProfileData.preferredBook;
                const loadedHighlightIds = highlights.map(highlight => highlight.highlight_id);
                const {data,errorMsg} = await HighlightService.loadHighlights(setAuthenticationState,authState,preferredBook,loadedHighlightIds.length > 0 ? null : highlightId,loadedHighlightIds);
                if (!data) {
                    console.log("API Error:",errorMsg);
                } else {
                    // setHighlights(data.highlights);
                    setHighlights(prevHighlights => [...prevHighlights, ...data.highlights]);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setResetFlag(false);
            }
        }
        if (loggedInProfileData.preferredBook && resetFlag) {
            LoadHighlights();
        }
        
    },[loggedInProfileData,resetFlag]);

    return(
        <HighlightFeedContainer>
            {highlights.map((highlight,index) => {
                return (
                    <HighlightContainer key={index}>
                        <Highlight highlightId={highlight.highlight_id} 
                            highlightData={highlight} 
                            setHasInteracted={setHasInteracted} 
                            hasInteracted={hasInteracted} 
                            previousLocation={previousLocation} 
                            />
                    </HighlightContainer>
                )
            })
            }
            <div style={{position:'fixed',top:'0px',left:'0px',padding:'10px',color:'white'}} onClick={handleClose}>
                <BackButton arrowColor={'white'} />
            </div>
        </HighlightFeedContainer>
    );
}

export default HighlightFeed;