import React from 'react';
import styled from 'styled-components';
import { checkDate, roundOdds, roundScore } from '../../utils';
import { borderColor, secondaryColor, } from '../../styles/Styles';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorDisplay from './ErrorDisplay';
import { MOBILE_WIDTH } from '../../config';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    font-size: 0.75em;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${props => props.$LeageAbbreviation === 'MLB' ? '200px' : '250px'};
        margin-bottom: 0px;
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
    }
`

const RowOne = styled.div`
    // flex: 1;
    display: flex;
    
    

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        // margin-left: 10px;
        // margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        // display: none;
    }
`

const ColumnOne = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    margin-left: 10px;
    
`

const OddsColumn = styled.div`
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 10px;
    margin-right: 10px;
`


const RowTwo = styled.div`
    // flex: 2.5;
    display: flex;
    
    margin-bottom: 5px;
`


const OddsContainer = styled.div`
    border-radius: 5px;
    background-color: ${borderColor};
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        height: 45px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        // height: 30px;
        height: 20px;
    }

`

const Normal = styled.div`
`

const Bold = styled.div`
     font-weight: bold;
`

function oddsLabel (selectedOdds) {
    switch (selectedOdds) {
        case 'moneyline':
            return 'Moneyline';
        case 'spread':
            return 'Spread';
        case 'total':
            return 'Total';
        default:
            return null;
    }
}

const EventTeamItemSidebar = ({record,selectedOdds}) => {

    let navigate = useNavigate();
    let location = useLocation();

    if (!record) {
        return(
            <ErrorDisplay />
        );
    }
    
    const { event_id
            , event_datetime
            , event_status
            , league_abbreviation
            , event_details = []
        } = record || {};

    if (!event_details || event_details.length === 0) {
        return(
            <ErrorDisplay />
        );
    }

    const {
        away_team_location
        , home_team_location
        , away_team_name
        , home_team_name
        , away_moneyline_price
        , home_moneyline_price
        , away_spread_price
        , home_spread_price
        , away_spread_point
        , home_spread_point
        , away_total_price
        , home_total_price
        , away_total_point
        , home_total_point
        , away_score
        , home_score
    } = event_details[0];

    const handleClick = (event) => {
        event.preventDefault();
        const destination = `/event/${event_id}`;
        if (destination === location.pathname) return;
        navigate(destination);
    }

    function calcPriceAndPoint (selectedOdds,homeAway) {
        switch (selectedOdds) {
            case 'moneyline':
                if (homeAway === 'home') {
                    return {price:home_moneyline_price,point:null};
                } else {
                    return {price:away_moneyline_price,point:null};
                }
                
            case 'spread':
                if (homeAway === 'home') {
                    return {price:home_spread_price,point:home_spread_point};
                } else {
                    return {price:away_spread_price,point:away_spread_point};
                }
            case 'total':
                if (homeAway === 'home') {
                    return {price:home_total_price,point:home_total_point};
                } else {
                    return {price:away_total_price,point:away_total_point};
                }
            default:
                return {price:null,point:null};
        }
    }

    const homeOdds = calcPriceAndPoint(selectedOdds,'home');
    const awayOdds = calcPriceAndPoint(selectedOdds,'away');
    

    return(
        <Container onClick={handleClick} $LeageAbbreviation = {league_abbreviation}>
            <RowOne style={{marginBottom: '5px'}}>
                <ColumnOne style={{fontWeight:'bold',color:secondaryColor}}>
                    {checkDate(event_datetime,event_status)}
                </ColumnOne>
                <OddsColumn>
                    ML
                </OddsColumn>
            </RowOne>
            <RowTwo >
                <ColumnOne>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{paddingRight:'5px',fontWeight:'bold',fontSize:'1.1em'}}>{['MLB','NBA','NHL'].includes(league_abbreviation) ? away_team_name : away_team_location}</div>
                        <div>{roundOdds(away_spread_point,1)}</div>
                    </div>
                    <div style={{fontSize:'1.2em',fontWeight:'bold',paddingRight:'5px'}}>{roundScore(away_score)}</div>
                </ColumnOne>
                <OddsColumn >
                    <OddsContainer >
                        {awayOdds.point && (<Normal>{roundOdds(awayOdds.point,1)}</Normal>)}
                        <Bold>{roundOdds(awayOdds.price,0)}</Bold>
                    </OddsContainer>
                </OddsColumn>
            </RowTwo>
            <RowTwo >
                <ColumnOne>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{paddingRight:'5px',fontWeight:'bold',fontSize:'1.1em'}}>{['MLB','NBA','NHL'].includes(league_abbreviation) ? home_team_name : home_team_location}</div>
                        <div>{roundOdds(home_spread_point,1)}</div>
                    </div>
                    <div style={{fontSize:'1.2em',fontWeight:'bold',paddingRight:'5px'}}>{roundScore(home_score)}</div>
                </ColumnOne>
                <OddsColumn >
                    <OddsContainer>
                        {homeOdds.point && (<Normal>{roundOdds(homeOdds.point,1)}</Normal>)}
                        <Bold>{roundOdds(homeOdds.price,0)}</Bold>
                    </OddsContainer >
                </OddsColumn>
            </RowTwo>

            <div style={{display:'flex',justifyContent:'end',marginRight:'10px',marginBottom:'10px'}}>
                    <div>o/u:</div>
                    <div style={{paddingLeft:'5px'}}>{Number(home_total_point).toFixed(1)}</div>
            </div>
        </Container>
    );
}

export default EventTeamItemSidebar;