import React from 'react';
import { useParams } from 'react-router-dom';
import { Feed } from '../components';

import { Layout, ProfileHeader, AuthenticationContext } from '../components';


const Profile = () => {

    const [feed,setFeed] = React.useState([]);
    let {viewedUsername} = useParams();
    
    return(
        <Layout >
            {/* <Test loggedInProfileData={loggedInProfileData}/> */}
            <ProfileHeader viewedUsername={viewedUsername} />
            <Feed feed={feed} setFeed={setFeed} viewedUsername={viewedUsername}/>
        </Layout>
    );
}

export default Profile;