import React from 'react';
import SwipeColumn from './SwipeColumn';
import { AuthenticationContext } from '../AuthenticationContext';
import { formatDateToSeconds } from '../../utils';
import { EventService } from '../../api';
import { Loader } from '../../styles/Styles';

const NHLSwipe = ({closeModal,eventId}) => {
    
    const [loading,setLoading] = React.useState(false);
    const [events,setEvents] = React.useState([1]);
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);

    React.useEffect(() => {
        const LoadEvents = async () => {
            if (!loggedInProfileData.preferredBook) return;
            try {
                setLoading(true);
                const preferredBook = loggedInProfileData.preferredBook;
                const currentDate = new Date();
                const startDate = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000);
    
                const endDate = new Date(startDate);
                endDate.setHours(startDate.getHours() + 48);
                const startDateFormatted = eventId ? null : formatDateToSeconds(startDate);
                const endDateFormatted = eventId ? null : formatDateToSeconds(endDate);
                const leagueAbbreviation = eventId ? null : 'NHL';
                const isSwipe = true;

                const {data,errorMsg} = await EventService.loadEvents(setAuthenticationState,authState,leagueAbbreviation,eventId,preferredBook,null,startDateFormatted,endDateFormatted,isSwipe);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setEvents(data.events);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        setEvents([]);
        LoadEvents();

    },[setEvents,loggedInProfileData.preferredBook,eventId]);
    
    return (
        <>
            {loading && (
                <div style={{width:'100%',height:'200px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {!loading && (
                <SwipeColumn events={events} closeModal={closeModal}/>
            )}
        </>
    );
}

export default NHLSwipe;