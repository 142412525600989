import React from 'react';
import { useParams } from 'react-router-dom';
import { EventService } from '../api';
import { AuthenticationContext, Feed } from '../components';
import { Loader } from '../styles/Styles';

import { 
    NewPost
    // , Feed
    , Layout
    // , AuthenticationContext 
} from '../components';

const Player = () => {

    let { playerId } = useParams();
    const [feed,setFeed] = React.useState([]);
    const [player,setPlayer] = React.useState({});

    const { setAuthenticationState,authState } = React.useContext(AuthenticationContext);
    
    React.useEffect(() => {
        async function loadPlayer() {
            const {data,errorMsg} = await EventService.loadPlayers(setAuthenticationState,authState,playerId);
            if (!data) {
                console.log("API Error:",errorMsg);
            } else {
                setPlayer(data.players[0]);
            }
        }

        loadPlayer();
    },[playerId]);


    return(
        <Layout >
            {Object.keys(player).length === 0  && (
                <div style={{width:'100%',height:'100px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {Object.keys(player).length > 0 && (
                <h1>{player.player_name}</h1>
            )}
            <NewPost setFeed={setFeed} playerId={playerId} />
            <Feed feed={feed} setFeed={setFeed} playerId={playerId}/>
        </Layout>
    );
}

export default Player;