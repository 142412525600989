import React from 'react';
import styled from 'styled-components';
import GolfSwipeEvent from './GolfSwipeEvent';
import { Loader, borderColor, secondaryColor } from '../../../styles/Styles';
import { MOBILE_WIDTH } from '../../../config';
import { SwipeService } from '../../../api';
import { AuthenticationContext } from '../../AuthenticationContext';
import { PrimaryButton } from '../../Buttons';
import { useNavigate } from 'react-router-dom';

const SwipeColumnContainer = styled.div`
    // flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    scroll-snap-type: x mandatory;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 50px;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 400px;
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
    }
`

const SwipeEventColumn = styled.div`
    flex-shrink: 0;
    width: 100%;
    scroll-snap-align: start;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 10px;
`

const NextButton = styled.button`
    border-radius: 999px;
    border: none;
    background-color: ${secondaryColor};
    color: white;
    cursor: pointer;
    padding: 10px 15px 10px 15px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`

const defaultChoice = {
    player1:null,
    player2:null,
}

function getChoiceData(choice,matchup) {

    const choiceData = {
        matchupOddsId:matchup.matchup_odds_id,
        playerId:null,
        price:null,
    }

    if (choice.player1) {
        choiceData.playerId = matchup.player_1_id;
        choiceData.price = matchup.player_1_price;
    } else if (choice.player2) {
        choiceData.playerId = matchup.player_2_id;
        choiceData.price = matchup.player_2_price;
    }

    return choiceData;
}

const TheEnd = ({closeModal}) => {

    let navigate = useNavigate();

    function goToLeaderboard(event) {
        event.preventDefault();
        navigate('/slate-swipe');
        closeModal(event);
    }

    return(
    <div style={{width: '100%',height:'100%',fontSize:'20px',textAlign:'center',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <p style={{marginBottom:'30px'}}>That's the end of the upcoming slate. Check back for more events soon.</p>
        <PrimaryButton onClick={goToLeaderboard} style={{fontWeight:'bold',fontSize: '0.9em'}}>View Leaderboard</PrimaryButton>
    </div>
    );
}

const GolfSwipeColumn = ({matchups,closeModal}) => {

    const [startX, setStartX] = React.useState(null);
    const [matchupNumber,setMatchupNumber] = React.useState(0);
    const containerRef = React.useRef(null);
    const [currentChoice,setCurrentChoice] = React.useState(defaultChoice);
    const [choices,setChoices] = React.useState([]);
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    const handleChoice = React.useCallback((event,choice) => {
        event.preventDefault();
        event.stopPropagation();

        switch (choice) {
            case 0:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[matchupNumber].player1 = true;
                    updatedChoices[matchupNumber].player2 = null;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,over:true,under:null,}));
                break;
            case 1:
                setChoices(prevChoices => {
                    const updatedChoices = [...prevChoices];
                    updatedChoices[matchupNumber].player1 = null;
                    updatedChoices[matchupNumber].player2 = true;
                    return updatedChoices;
                });
                // setCurrentChoice(prevChoices => ({...prevChoices,away:true,home:null,}));
                break;
            default:
                setCurrentChoice(defaultChoice);
                return;
        }
    },[matchupNumber]);

    React.useEffect(() => {
        const newChoices = matchups.map(event => ({ ...defaultChoice }));
        setChoices(newChoices);
    }, [matchups]);

    const scrollRight = (event) => {
        if (matchupNumber >= matchups.length) return;
        event.stopPropagation();
        setMatchupNumber(prevPosition => prevPosition + 1);
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: containerRef.current.clientWidth,
                behavior: 'smooth'
            });
          }
    }

    React.useEffect(() => {
        function handleTouchMove(e) {
            e.preventDefault();
            if (e.touches.length !== 1 || startX === null) return;
        }
    
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
    
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startX]);

    function saveAndScroll(e) {
        e.preventDefault();
        const choiceData = getChoiceData(choices[matchupNumber],matchups[matchupNumber]);
        SwipeService.saveGolfMatchupSwipe(setAuthenticationState,authState,choiceData);
        // SwipeService.saveSwipe(setAuthenticationState,authState,choiceData);
        scrollRight(e);
    }

    function handleTouchEnd(e) {
        if (matchupNumber >= matchups.length) {
            setStartX(null);
            return;
        }
        if (startX !== null) {
            const deltaX = e.changedTouches[0].clientX - startX;
            if (deltaX < -100) {
                saveAndScroll(e);
            }
            setStartX(null);
        }
    }

    return(
        <>
            
            {(!matchups.length > 0 || !matchups[0] || choices.length === 0) && (
                <div style={{width:'100%',height:'100px'}}>
                    <TheEnd closeModal={closeModal} />
                </div>
            )}
            {matchups.length > 0 && choices.length > 0 && (
                <>
                <SwipeColumnContainer
                    onTouchStart={e => setStartX(e.touches[0].clientX)}
                    onTouchEnd={e => handleTouchEnd(e)}
                    ref={containerRef}
                    >
                    {matchups.map((matchup,index) => (
                        <SwipeEventColumn key={index}>
                            <GolfSwipeEvent matchup={matchup} currentChoice={choices[index]} handleChoice={handleChoice}/>
                        </SwipeEventColumn>
                    ))}
                    <SwipeEventColumn>
                        <TheEnd closeModal={closeModal} />
                    </SwipeEventColumn>
                    
                </SwipeColumnContainer>
                {matchupNumber < matchups.length && (
                    <NextButton onClick={saveAndScroll}>Next</NextButton>
                )}
                </>
            )}
        </>
    );
}

export default GolfSwipeColumn;