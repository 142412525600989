import React from "react";
import styled from "styled-components";
import { AuthenticationContext } from "../AuthenticationContext";
import { UserService } from "../../api";
import {  useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { PrimaryButton, WhiteButton } from "../Buttons";

const Choice = ({display, setDisplayState,setUsername}) => {

    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);

    const handleLogin = () => {
        setDisplayState(display.LOGIN);
    }

    const handleSignup = () => {
        setDisplayState(display.SIGNUP);
    }

    async function verifyGoogleToken(response) {
        try {
            const postData = {token:response.access_token};
            const {data,errorMsg} = await UserService.verifyGoogleToken(setAuthenticationState,authState,postData);
            console.log(data);
            if (!data) {
                console.log("API Error:",errorMsg);
            } else {
                if (data.username === null) {
                    setDisplayState(display.OAUTH_DETAILS);
                } else {
                    setUsername(data.username);
                    setAuthenticationState(authState.AUTHENTICATED);
                }
                
            }
        } catch (error) {
            console.log(error);
        }
    }

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            verifyGoogleToken(tokenResponse);
        },
    });

    const responseFacebook = (response) => {
        console.log(response);
      }

    return(
        <>
           {/* <FacebookLogin
            appId="286307221012617"
            autoLoad={false}
            callback={responseFacebook}
            render={renderProps => (
                <WhiteButton onClick={renderProps.onClick}><div style={{fontSize:'18px',paddingRight:'5px',paddingTop:'5px',fontFamily:'"Google Sans",arial,sans-serif'}}><FaFacebook /></div>Sign up with Facebook</WhiteButton>
              )}
           /> */}
           {/* <FacebookLogin
                appId="286307221012617"
                autoLoad={true}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="my-facebook-button-class"
                icon="fa-facebook"
            /> */}
            <WhiteButton onClick={() => login()} style={{marginBottom:'20px'}}><div style={{fontSize:'18px',paddingRight:'5px',paddingTop:'5px',fontFamily:'"Google Sans",arial,sans-serif'}}><FcGoogle /></div>Sign up with Google</WhiteButton>
            <WhiteButton onClick={handleSignup}>Signup</WhiteButton>
            <PrimaryButton onClick={handleLogin}>Login</PrimaryButton>
        </>
    );
}

export default Choice;