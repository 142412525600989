import React from 'react';
import styled from 'styled-components';
import { borderColor } from '../styles/Styles';
import { AuthenticationContext } from './AuthenticationContext';
import { UserService } from '../api';
import { useNavigate } from 'react-router-dom';

const LogoutContainer = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
`

const LogoutWrapper = styled.div`
    margin-top: 60px;
    margin-right: 5px;
    border: 1px solid ${borderColor};
    border-radius: 10px;
    width: 150px;
    height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0px 0px 15px ${borderColor};
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
`

const Option = styled.div`
    
    border-bottom: 1px solid ${borderColor};
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`

const Logout = ({closeLogout}) => {
    let navigate = useNavigate();
    const {setAuthenticationState,authState,loggedInProfileData} = React.useContext(AuthenticationContext);

    const handleLogout = async (event) => {
        event.preventDefault();
        await UserService.logout(setAuthenticationState,authState);
        // setAuthenticationState(authState.UNAUTHENTICATED);
    }

    const goToProfile = (event) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/${loggedInProfileData.username}`);
    }

    return(
        <LogoutContainer onClick={closeLogout}>
            <LogoutWrapper>
                <div style={{display:'flex',boxSizing:'border-box',height: '100px',flexDirection:'column',borderTop:`1px solid ${borderColor}`,alignItems:'center'}}>
                    <Option onClick={goToProfile}>Profile</Option>
                    <Option onClick={handleLogout}>Logout</Option>
                </div>
            </LogoutWrapper>
        </LogoutContainer>
    );
}

export default Logout;