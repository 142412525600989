import React from "react";
import styled from 'styled-components';
import { formatEventDate } from "../../../utils";
import { secondaryColor,primaryColor,lightGray, borderColor,mediumGray } from "../../../styles/Styles";
import { roundOdds } from "../../../utils";
import Avatar from "../../Avatar";
import { MOBILE_WIDTH, S3_BASE_URL } from "../../../config";
import ErrorDisplay from "../../EventItem/ErrorDisplay";

const SwipeEventBackground = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-image: ${props => props.$EventImage ? `url(${S3_BASE_URL}${props.$EventImage})` : 'none'};
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    border: 1px solid ${borderColor};
    position: relative;
`

const SwipeEventContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, rgba(0,60,128, 1), rgba(241,55,61, 1));
    border-radius: 20px;

`

const ChoicesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`

const SwipeChoices = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 50%;
    }
`

const SwipeChoice = styled.button`
    width: 80px;
    height: 80px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: none;
    box-sizing: border-box;
    background-color: ${props => props.$IsChosen ? secondaryColor : 'transparent'};
    color: white;
    border: 4px solid ${props => props.$IsChosen ? secondaryColor : 'white'};

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 85px;
        height: 85px;
    }

    cursor: pointer;
`

const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`

const TeamLogoBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
`

const TeamName = styled.div`
    text-align: center;
    padding-top: 10px;
    font-size: 1.3em;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    // color : ${props => props.$EventImage ? 'white' : 'black'};
    color: white;
`

const GolfSwipeEvent = ({matchup,currentChoice,handleChoice}) => {

    if (!matchup) {
        return(
            <ErrorDisplay />
        );
    }

    const {player_1_last_name,player_1_price,player_2_last_name,player_2_price} = matchup;

    return(
        <SwipeEventBackground 
            // $EventImage={event_photo_filename ? event_photo_filename : league_photo_filename}
            >
            <SwipeEventContainer>
                    {/* <LogoContainer>
                        <TeamLogoBox >
                            <Avatar imageSize={'95px'} 
                                // isTeamLogo={true} 
                                // imageSource={`${S3_BASE_URL}${away_team_photo_filename}`}
                                />
                            <TeamName 
                                // $EventImage={event_photo_filename}
                                >
                                {player_1_last_name}
                            </TeamName>
                        </TeamLogoBox>
                        <TeamLogoBox >
                            <Avatar imageSize={'95px'} 
                                // isTeamLogo={true} 
                                // imageSource={`${S3_BASE_URL}${home_team_photo_filename}`}
                                />
                            <TeamName 
                                // $EventImage={event_photo_filename}
                                >
                                {player_2_last_name}
                            </TeamName>
                        </TeamLogoBox>
                    </LogoContainer> */}
                <ChoicesContainer>
                    <SwipeChoices>
                        <div>
                            <SwipeChoice 
                                $IsChosen = {currentChoice.player1} 
                                onClick={event => (handleChoice(event,0))} style={{top:'0px',right:'0px'}}>
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(player_1_price,0)}</div>
                            </SwipeChoice> 
                            <div style={{width:'100%',textAlign:'center',color:'white',paddingTop:'5px'}}>{player_1_last_name}</div>
                        </div>  
                        <div>
                            <SwipeChoice 
                                $IsChosen = {currentChoice.player2} 
                                onClick={event => (handleChoice(event,1))} style={{top:'0px',left:'0px'}}>
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(player_2_price,0)}</div>
                            </SwipeChoice>
                            <div style={{width:'100%',textAlign:'center',color:'white',paddingTop:'5px'}}>{player_2_last_name}</div>
                        </div>    
                    </SwipeChoices>
                </ChoicesContainer>
                {/* <div style={{paddingBottom:'20px',marginTop:'10px'}}>
                </div> */}
                {/* {event_photo_filename && (<div style={{width:'100%',textAlign:'left',paddingLeft:'10px',paddingBottom:'5px',boxSizing:'border-box',fontSize:'0.8em',fontStyle:'italic',color: 'white'}}>Source: Getty Images</div>)} */}
            </SwipeEventContainer>
        </SwipeEventBackground>
    );
}

export default GolfSwipeEvent;