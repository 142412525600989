import home from './home.png';
import bell from './bell.png';
import crown from './crown.png';
import trophy from './trophy.png';
import ncaab from './ncaab.png';
import proGolf from './pro-golf.png';
import nba from './nba.png';
import mlb from './mlb.png';
import nhl from './nhl.png';
import nfl from './nfl.png';
import React from 'react';
import { ReactComponent as LfgSvg } from './LFG.svg'; 
import { ReactComponent as LfgGreenSvg } from './LFGGreen.svg'; 

function calcIconSize(size) {
    return `${size}px` ? `${size}px` : '22px';
}

const HomeIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={home} alt="home" style={{width:iconSize, height: iconSize}} />
    );
}

const BellIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={bell} alt="bell" style={{width:iconSize, height: iconSize}} />
    );
}

const CrownIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={crown} alt="crown" style={{width:iconSize, height: iconSize}} />
    );
}

const TrophyIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={trophy} alt="trophy" style={{width:iconSize, height: iconSize}} />
    );
}

const NcaabIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={ncaab} alt="ncaab" style={{width:iconSize, height: iconSize}} />
    );
}

const NbaIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={nba} alt="nba" style={{width:iconSize, height: iconSize}} />
    );

}

const ProGolfIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={proGolf} alt="pga" style={{width:iconSize, height: iconSize}} />
    );
}

const MlbIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={mlb} alt="mlb" style={{width:iconSize, height: iconSize}} />
    );
}

const NhlIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={nhl} alt="nhl" style={{width:iconSize, height: iconSize}} />
    );
}

const NflIcon = ({size}) => {
    const iconSize = calcIconSize(size);
    return(
        <img src={nfl} alt="nfl" style={{width:iconSize, height: iconSize}} />
    );
}

const LfgIcon = ({size,fill}) => {
    return(
        <LfgSvg style={{width:size,height:size,display:'block'}} />
    )
}

const LfgGreenIcon = ({size}) => {
    return(
        <LfgGreenSvg style={{width:size,height:size,display:'block'}} />
    )
}

const MoneyBagIcon = ({size}) => {
    return(
        <div style={{fontSize:size}}>
            {'\u{1F4B0}'}
        </div>
    );
}

const SlateSwipeIcon = ({size,color}) => {

    const iconSize = size ? `${size}px` : '22px';
    const fontSizeString = size ? `${Math.ceil(size/1.75)}px` : '14px';
    const borderString = `${size > 20 ? 2 : 1}px solid ${color ? color : 'black'}`;
    const fontWeightString = `${size > 20 ? 'bold' : 'normal'}`;

    return(
        <div style={{
                height:iconSize
                ,width:iconSize
                ,fontSize:fontSizeString
                ,borderRadius:'999px'
                ,border:borderString
                ,display:'flex'
                ,alignItems:'center'
                ,justifyContent:'center'
                ,fontWeight:fontWeightString
                ,color:color
                }}>
            +/-
        </div>
    )
}

export {HomeIcon, BellIcon, CrownIcon, TrophyIcon, NcaabIcon,ProGolfIcon,LfgIcon,LfgGreenIcon,MoneyBagIcon,MlbIcon,SlateSwipeIcon,NbaIcon
    ,NhlIcon
    ,NflIcon};