import React from 'react';
import styled from 'styled-components';
import { roundOdds } from '../../utils';
import { borderColor,lightGray } from '../../styles/Styles';
import { SlArrowLeft,SlArrowRight } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import { golfScore } from '../../utils';

const GolfMainWrapper = styled.div`
    width: 100%;
` 

const GolfColumnWrapper = styled.div`
    display: flex;
    // flex-direction: column;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
`

const GolfColumn = styled.div`
    flex-shrink: 0;
    width: 100%;
    scroll-snap-align: start;
    box-sizing: border-box;
`

const PlayerContainer = styled.div`
    display: flex;
    border-bottom: 1px solid ${borderColor};
    height: 50px;
    align-items: center;
    padding-left: 5px;
    box-sizing: border-box;
    cursor: pointer;
`

const ScrollButton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: ${borderColor};
    }
    box-sizing: border-box;
    padding-right: ${props => props.$Left ? '2px' : '0px'};
    padding-left: ${props => props.$Right ? '2px' : '0px'};
`

const GolfMain = ({record}) => {
    
    const parentRef = React.useRef(null);
    const [currentPosition,setCurrentPosition] = React.useState(0);
    let navigate = useNavigate();


    const scrollLeft = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (parentRef.current) {
            parentRef.current.scrollBy({
              left: -parentRef.current.clientWidth,
              behavior: 'smooth'
            });
          }
    }

    const scrollRight = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (parentRef.current) {
            parentRef.current.scrollBy({
              left: parentRef.current.clientWidth,
              behavior: 'smooth'
            });
          }
    }

    const handlePlayerClick = (event,playerId) => {
        event.preventDefault();
        navigate(`/player/${playerId}`);
    }

    return(
        <GolfMainWrapper>
            <h1>{record.event_name}</h1>
            <div style={{display:'flex',backgroundColor:lightGray,paddingLeft:'5px',borderBottom:`1px solid ${borderColor}`}}>
                <div style={{flex:'2'}}>Player</div>
                <div style={{flex:'1',textAlign:'center'}}>To Win</div>
                <div style={{flex:'1',textAlign:'center'}}>Position</div>
                <div style={{flex:'1',textAlign:'center'}}>Score</div>
                <div style={{flex:'1',textAlign:'center'}}>Thru</div>
                <div style={{flex:'1',textAlign:'center'}}>Today</div>
            </div>
            <GolfColumnWrapper ref={parentRef}>
                {
                    record.event_details.reduce((columns,eventDetail,index) => {
                        if (index % 5 === 0) columns.push([]);
                        columns[columns.length - 1].push(
                            <PlayerContainer key={index} onClick={(event) => handlePlayerClick(event,eventDetail.player_id)}>
                                <div style={{flex:'2'}}>{eventDetail.player_name}</div>
                                <div style={{flex:'1',fontSize:'0.8em',textAlign:'center'}}>
                                    <a href={record.dk_url} style={{textDecoration:'none',color:'black'}} target="_blank" rel="noopener noreferrer">{roundOdds(eventDetail.price,0)}</a>
                                </div>
                                <div style={{flex:'1',textAlign:'center'}}>{!eventDetail.current_position || eventDetail.current_position === 'WAITING' ? '-' : eventDetail.current_position}</div>
                                <div style={{flex:'1',textAlign:'center'}}>{eventDetail.score ? golfScore(eventDetail.score) : '-'}</div>
                                <div style={{flex:'1',textAlign:'center'}}>{eventDetail.thru ? eventDetail.thru : '-'}</div>
                                <div style={{flex:'1',textAlign:'center'}}>{eventDetail.round_score ? golfScore(eventDetail.round_score) : '-'}</div>
                            </PlayerContainer>
                        );
                        return columns;
                    },[]).map((column,columnIndex) => (
                        <GolfColumn key={columnIndex} >
                            {column}
                        </GolfColumn>
                    ))
                }
            </GolfColumnWrapper>
            <div style={{display:'flex',justifyContent:'center'}}>
                <ScrollButton style={{marginRight: '10px'}} $Left={true} onClick={scrollLeft} ><SlArrowLeft /></ScrollButton>
                <ScrollButton style={{marginLeft:'10px'}} $Right={true}  onClick={scrollRight} ><SlArrowRight /></ScrollButton>
            </div>
        </GolfMainWrapper>
    )
}

export default GolfMain;

// import React from 'react';
// import styled from 'styled-components';
// // import { useNavigate } from 'react-router-dom';
// import { borderColor } from '../../styles/Styles';
// import { lightGray } from '../../styles/Styles';
// import ErrorDisplay from './ErrorDisplay';
// import { roundOdds } from '../../utils';
// import { SlArrowLeft,SlArrowRight } from "react-icons/sl";
// import OddsByDisplay from './OddsBy';

// const IndividualEventItemWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     padding-top: 10px;
// `

// const IndividualEventItemContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     padding-top: 10px;
//     background-color: ${lightGray};
//     border-radius: 10px;
// `

// const IndividualMainScrollWrapper = styled.div`
//     overflow-x: auto;
//     overflow-y: hidden;
//     white-space: nowrap;
//     min-height: 180px;
//     display: flex;
//     width: 100%;
//     scroll-snap-type: x mandatory;
// `

// const IndividualEventDetailContent = styled.div`
//     flex: 1;
//     background-color: ${borderColor};
//     font-size: 0.8em;
//     border-radius: 10px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
// `

// const IndividualEventDetailContainer = styled.div`
//     width: 50%;
//     box-sizing: border-box;
//     padding-left: ${props => calcPadding(props.$Index,'left')};
//     padding-right: ${props => calcPadding(props.$Index,'right')};
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 50px;
//     margin-bottom: 0px;
// `


// const IndividualEventColumn = styled.div`
//     min-width: 100%;
//     flex: 1;
//     height: 180px;
//     display: flex;
//     flex-wrap: wrap;
//     margin-right: 10px;
//     box-sizing: border-box;
//     scroll-snap-align: start;
// `

// const ScrollButton = styled.div`
//     width: 30px;
//     height: 30px;
//     border-radius: 15px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     &:hover {
//         background-color: ${borderColor};
//     }
//     box-sizing: border-box;
//     padding-right: ${props => props.$Left ? '2px' : '0px'};
//     padding-left: ${props => props.$Right ? '2px' : '0px'};
// `

// function calcPadding(index,side) {

//     if (index % 2 === 0 && side === 'right') {
//         return '5px';   
//     } else if (index % 2 === 0 && side === 'left') {
//         return '10px';
//     } else if (side === 'right') {
//         return '10px';
//     }   else if (side === 'left') {
//         return '5px';
//     }

// }

// const MainScroll = ({record,handleClick,parentRef}) => {

//     if (!record) {
//         return(
//             <ErrorDisplay />
//         );
//     }
    
//     const { 
//             event_details = []
//         } = record || {};

//     if (!event_details || event_details.length === 0) {
//         return(
//             <ErrorDisplay />
//         );
//     }
    
//     return(
//         <IndividualMainScrollWrapper ref={parentRef}>
//             {
//                 record.event_details.reduce((columns,eventDetail,index) => {
//                     if (index % 6 === 0) columns.push([]);
//                     columns[columns.length - 1].push(
//                         <IndividualEventDetailContainer $Index={index}  key={index} onClick={handleClick}>
//                             <IndividualEventDetailContent style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
//                                 <div>{eventDetail.player_name}</div>
//                                 <div style={{fontWeight:'bold'}}>{roundOdds(eventDetail.price,0)}</div>
//                             </IndividualEventDetailContent>
//                         </IndividualEventDetailContainer>
//                     );
//                     return columns;
//                 },[]).map((column,columnIndex) => (
//                     <IndividualEventColumn key={columnIndex} >
//                         {column}
//                     </IndividualEventColumn>
//                 ))
//             }
//         </IndividualMainScrollWrapper>
//     );
// }

// const GolfMain = ({record}) => {
    
//     // let navigate = useNavigate();
//     const [currentPosition,setCurrentPosition] = React.useState(0);
//     const parentRef = React.useRef(null);

//     // const handleClick = () => {
//     //     navigate(`/event/${record.event_id}`);
//     // }

//     const scrollLeft = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         // if (currentPosition <= 0) return;
//         setCurrentPosition(prevPosition => prevPosition - 1);
//         if (parentRef.current) {
//             parentRef.current.scrollBy({
//               left: -parentRef.current.clientWidth,
//               behavior: 'smooth'
//             });
//           }
//     }

//     const scrollRight = (event) => {
//         // event.preventDefault();
//         // event.stopPropagation();
//         if (currentPosition >= Math.ceil(record.event_details.length / 6)) return;
//         setCurrentPosition(prevPosition => prevPosition + 1);
//         if (parentRef.current) {
//             parentRef.current.scrollBy({
//               left: parentRef.current.clientWidth,
//               behavior: 'smooth'
//             });
//           }
//     }

//     return(
//         <IndividualEventItemWrapper>
//             <div style={{boxSizing:'border-box',width:'100%',textAlign:'left'}}><h2>{record.event_name}</h2></div>
//             <IndividualEventItemContainer >
//                 <MainScroll record={record} parentRef={parentRef}/> 
//                 <div style={{display:'flex',marginBottom:'10px',justifyContent:'center'}}>
                    // <ScrollButton style={{marginRight: '10px'}} $Left={true} onClick={scrollLeft} ><SlArrowLeft /></ScrollButton>
                    // <ScrollButton style={{marginLeft:'10px'}} $Right={true}  onClick={scrollRight} ><SlArrowRight /></ScrollButton>
//                 </div>    
//             </IndividualEventItemContainer>
//         {record.event_details[0].book_name && (<OddsByDisplay bookName={record.event_details[0].book_name} />)}
//         </IndividualEventItemWrapper>
//     );
// }

// export default GolfMain;