import React from 'react';

import { Layout, AuthenticationContext } from '../components';

const VipPage = () => {

    return(
        <Layout >
            <div style={{textAlign:'left',paddingBottom:'20px'}}>
                <h1>VIP Community</h1>
                <h2>Coming Soon!</h2>
                <p>Get ready to elevate your sports betting experience with our upcoming VIP Community feature! Connect with your closest friends and fellow enthusiasts to form exclusive groups where you can share insights, strategies, and celebrate your wins together.</p>
                <br />
                <p>But that's not all! Gain access to a curated selection of expert handicappers, whose valuable advice and predictions will help you make informed decisions when placing your bets. Follow their picks, engage in discussions, and stay ahead of the game.</p>
                <br />
                <p>Whether you're a seasoned bettor or just starting out, our VIP Community is your ticket to unlocking a whole new level of excitement and success in the world of sports betting. Stay tuned for its release – greatness awaits! 🚀</p>
            </div>
        </Layout>
    );
}

export default VipPage;