import React from 'react';
import styled from 'styled-components';
import { borderColor, secondaryColor } from '../../styles/Styles';
import { useNavigate } from 'react-router-dom';
import { NcaabIcon, ProGolfIcon, MlbIcon, NbaIcon, NhlIcon, NflIcon } from '../../assets';
import { PiBasketball } from "react-icons/pi";

const LeagueItemContainer = styled.div`
    height: ${props => props.$IsLeaguePage ? '100px' : ''};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;   
    border-bottom: ${props => props.$IsLeaguePage ? `1px solid ${borderColor}` : ''};
    &:hover {
        background-color: ${secondaryColor};
    }
    padding-left: 10px;
`

const IconSelector = (league_abbreviation,isScroll) => {

    const iconSize = isScroll ? 80 : 60;

    switch (league_abbreviation) {
        case 'NCAAB':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NcaabIcon size={iconSize}/>
                </div>
            );
        case 'PGA':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <ProGolfIcon size={iconSize}/>
                </div>
            );
        case 'MLB':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <MlbIcon size={iconSize}/>
                </div>
            );
        case 'NBA':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NbaIcon size={iconSize}/>
                </div>
            );
        case 'NHL':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NhlIcon size={iconSize}/>
                </div>
            );
        case 'NFL':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <NflIcon size={iconSize}/>
                </div>
            );
        case 'LIV':
            return(
                <div style={{paddingRight:'5px',fontSize:'30px',paddingTop:'8px'}}>
                    <ProGolfIcon size={iconSize}/>
                </div>
            );
        default:
            return(
                <span style={{paddingRight:'5px',fontSize:'30px',paddingTop:'5px'}}><PiBasketball /></span>
            );
    }
}

const LeagueItem = ({record,isScroll,setSelectedLeague}) => {
    
    let navigate = useNavigate();
    const {league_abbreviation, league_name} = record;

    const handleClick = () => {
        if (isScroll) {
            setSelectedLeague(league_abbreviation);
        } else {
            navigate(`/league/${league_abbreviation}`,{state: { record }});
        }
    }

    return(
        <LeagueItemContainer onClick={handleClick} $IsLeaguePage={!isScroll}>
            {IconSelector(league_abbreviation,isScroll)}
            {!isScroll && (<div>{league_name}</div>)}
        </LeagueItemContainer>
    );
} 

export default LeagueItem;