import React from "react";
import styled from 'styled-components';
import { formatEventDate } from "../../utils";
import { secondaryColor,primaryColor,lightGray, borderColor,mediumGray } from "../../styles/Styles";
import { roundOdds } from "../../utils";
import Avatar from "../Avatar";
import { MOBILE_WIDTH, S3_BASE_URL } from "../../config";
import ErrorDisplay from "../EventItem/ErrorDisplay";

const SwipeEventBackground = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-image: ${props => props.$EventImage ? `url(${S3_BASE_URL}${props.$EventImage})` : 'none'};
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    border: 1px solid ${borderColor};
    position: relative;
`

const SwipeEventContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
    border-radius: 20px;
    
`



const ChoicesContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`

const SwipeChoices = styled.div`
    width: 42%;
    max-width: 300px;
    aspect-ratio: 1;
    position: relative;
    transform: rotate(-45deg);
    font-size: 16px;
    font-weight: bold;

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 50%;
    }
`

const SwipeChoice = styled.button`
    width: 46%;
    height: 46%;
    position: absolute;
    flex: 1;
    border-radius: 999px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: none;
    box-sizing: border-box;
    background-color: ${props => props.$IsChosen ? secondaryColor : 'transparent'};
    color: white;
    border: 4px solid ${props => props.$IsChosen ? secondaryColor : 'white'};

    @media screen and (min-width: ${MOBILE_WIDTH + 1}px) {
        width: 85px;
        height: 85px;
    }

    cursor: pointer;
`

const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`

const TeamLogoBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
`

const TeamName = styled.div`
    text-align: center;
    padding-top: 10px;
    font-size: 1.3em;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    // color : ${props => props.$EventImage ? 'white' : 'black'};
    color: white;
`

const SwipeEvent = ({eventRecord,currentChoice,handleChoice}) => {

    if (!eventRecord) {
        return(
            <ErrorDisplay />
        );
    }
    
    const { 
        event_datetime
       , event_status
       , event_details
       , event_photo_filename
       , league_photo_filename
       , league_abbreviation
   } = eventRecord ;

   if (!event_details || event_details.length === 0) {
        return(
            <ErrorDisplay />
        );
    }

   const {
       away_team_id
       , home_team_id
       , away_team_name
       , home_team_name
       , away_team_location
       , home_team_location
       , away_team_photo_filename
       , home_team_photo_filename
       , home_team_abbreviation
       , away_team_abbreviation
       , away_moneyline_price
       , home_moneyline_price
       , away_spread_point
       , home_spread_point
       , away_spread_price
       , home_spread_price
       , home_total_point
       , home_total_price
       , away_total_price
   } = event_details[0];

    return(
        <SwipeEventBackground $EventImage={event_photo_filename ? event_photo_filename : league_photo_filename}>
            <SwipeEventContainer>
                <LogoContainer>
                    <TeamLogoBox >
                        <Avatar imageSize={'95px'} isTeamLogo={true} imageSource={`${S3_BASE_URL}${away_team_photo_filename}`}/>
                        <TeamName $EventImage={event_photo_filename}>
                            {away_team_name ? away_team_name : away_team_location}
                        </TeamName>
                    </TeamLogoBox>
                    <TeamLogoBox >
                        <Avatar imageSize={'95px'} isTeamLogo={true} imageSource={`${S3_BASE_URL}${home_team_photo_filename}`}/>
                        <TeamName $EventImage={event_photo_filename}>
                            {home_team_name ? home_team_name : home_team_location}
                        </TeamName>
                    </TeamLogoBox>
                </LogoContainer>
                <div style={{padding:'5px 10px 5px 10px'
                        ,borderRadius:'99px'
                        ,boxSizing:'border-box'
                        ,backgroundColor:secondaryColor
                        ,textAlign:'left'
                        ,color:'white'
                        ,fontSize:'1.1em'
                        ,fontWeight:'bold'
                        ,marginBottom:'40px'}}>
                    {formatEventDate(event_datetime)}
                </div>
                <ChoicesContainer>
                    <SwipeChoices>
                        <SwipeChoice $IsChosen = {currentChoice.over} onClick={event => (handleChoice(event,0))} style={{top:'0px',right:'0px'}}>
                            <div style={{fontSize:'18px'}}>{roundOdds(home_total_point,1,'over')}</div>
                            <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(home_total_price,0)}</div>
                        </SwipeChoice> 
                        <SwipeChoice $IsChosen = {currentChoice.away} onClick={event => (handleChoice(event,1))} style={{top:'0px',left:'0px'}}>
                            {['MLB','NHL'].includes(eventRecord.league_abbreviation) && (
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(away_moneyline_price,0)}</div>
                            )}
                            {!['MLB','NHL'].includes(eventRecord.league_abbreviation) && (
                                <>
                                    <div style={{fontSize:'18px'}}>{roundOdds(away_spread_point,1)}</div>
                                    <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(away_spread_price,0)}</div>
                                </>
                            )}
                        </SwipeChoice>
                        <SwipeChoice $IsChosen = {currentChoice.under} onClick={event => (handleChoice(event,2))} style={{bottom:'0px',left:'0px'}}>
                            <div style={{fontSize:'18px'}}>{roundOdds(home_total_point,1,'under')}</div>
                            <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(home_total_price,0)}</div>
                        </SwipeChoice>
                        <SwipeChoice $IsChosen = {currentChoice.home} onClick={event => (handleChoice(event,3))} style={{bottom:'0px',right:'0px'}}>
                            
                            {['MLB','NHL'].includes(eventRecord.league_abbreviation) && (
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(home_moneyline_price,0)}</div>
                            )}
                            {!['MLB','NHL'].includes(eventRecord.league_abbreviation) && (
                                <>
                                    <div style={{fontSize:'18px'}}>{roundOdds(home_spread_point,1)}</div>
                                    <div style={{fontSize:'18px',fontWeight:'bold'}}>{roundOdds(home_spread_price,0)}</div>
                                </>
                            )}
                        </SwipeChoice>
                    </SwipeChoices>
                </ChoicesContainer>
                {/* <div style={{paddingBottom:'20px',marginTop:'10px'}}>
                </div> */}
                {event_photo_filename && (<div style={{width:'100%',textAlign:'left',paddingLeft:'10px',paddingBottom:'5px',boxSizing:'border-box',fontSize:'0.8em',fontStyle:'italic',color: 'white'}}>Source: Getty Images</div>)}
            </SwipeEventContainer>
        </SwipeEventBackground>
    );
}

export default SwipeEvent;