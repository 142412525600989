import React from 'react'
import { AuthenticationContext } from '../AuthenticationContext'
import { EventService } from '../../api'
import LeagueItem from './LeagueItem';
import { Loader } from '../../styles/Styles';


const LeagueList = ({isScroll,setSelectedLeague}) => {
    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [leagues,setLeagues] = React.useState([]);
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        const LoadLeagues = async () => {
            try {
                setLoading(true);
                const {data,errorMsg} = await EventService.loadLeagues(setAuthenticationState,authState);
                if (!data) {
                    console.log("API error:",errorMsg);
                } else {
                    setLeagues(data.leagues);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        LoadLeagues();
    },[]);

    return(
        <>
            {loading && (
                <div style={{width:'100%',height: '200px',position:'relative'}}>
                    <Loader />
                </div>
            )}
            {leagues.map((record,index) => (
                <LeagueItem 
                    key={index} 
                    record={record} 
                    isScroll={isScroll}
                    setSelectedLeague={setSelectedLeague} />
            ))}
        </>
    )
}

export default LeagueList;