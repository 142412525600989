import React from "react";
import styled from "styled-components";
import { borderColor, primaryColor } from "../styles/Styles";
import { MOBILE_WIDTH } from "../config";
import PostService from "../api/postService";
import { AuthenticationContext } from "./AuthenticationContext";
import { useNavigate, useLocation } from "react-router-dom";

const DeletePostContainer = styled.div`
    position: -webkit-fixed;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    z-index: 4;
    align-items: center;
    justify-content: center;
`

const DeletePostContent = styled.div`
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    
    @media (max-width: ${MOBILE_WIDTH}px){
        width: 100%;
    }

    @media (min-width: ${MOBILE_WIDTH + 1}px){
        width: 300px;
    }
`

const Button = styled.button`
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 999px;
`

const DeleteButton = styled(Button)`
    background-color: ${primaryColor};
    color: white;
    border: none;
    border: 1px solid ${primaryColor};
`

const CancelButton = styled(Button)`
    border: none;
    border: 1px solid ${borderColor};
`

const DeletePost = ({postId,closeModal,setIsDeleted,setShowDelete}) => {

    const {setAuthenticationState,authState} = React.useContext(AuthenticationContext);
    const [errorMessage,setErrorMessage] = React.useState(null);
    let navigate = useNavigate();
    let location = useLocation();

    const deletePost = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        try{
            const {data,errorMsg} = await PostService.deletePost(setAuthenticationState,authState,postId);
            if (!data){
                console.log("API Error:",errorMsg);
                setErrorMessage(errorMsg);
            } else { 
                if (location.pathname === `/status/${postId}`) {
                    navigate('/');
                } else {
                    setIsDeleted(true);
                    setShowDelete(false);
                }
            }
        } catch(err){
            console.log(err);
        }
    }

    return(
        <DeletePostContainer>
            <DeletePostContent>
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    paddingLeft:'10px',
                    paddingRight:'10px',
                    marginBottom:'15px',
                    marginTop:'15px'
                    }}>
                        <div style={{textAlign:'center',fontSize:'1.5em',fontWeight:'bold',marginBottom:'10px'}}>Delete Post?</div>
                        <div style={{textAlign:'center'}}>Are you sure you want to delete your post?</div>
                </div>
                <div style={{display:'flex',justifyContent:'center',paddingTop:'15px',paddingBottom:'15px',marginLeft:'5px',marginRight:'5px',borderTop:`1px solid ${borderColor}`}}>
                    <CancelButton onClick={closeModal}>Cancel</CancelButton>
                    <DeleteButton onClick={deletePost}>Delete</DeleteButton>
                </div>
                {errorMessage && (
                    <div style={{paddingBottom:'15px',display:'flex',alignItems:'center',justifyContent:'center',color:'red'}}>
                        {errorMessage}
                    </div>
                )}
            </DeletePostContent>
        </DeletePostContainer>    
    );
}

export default DeletePost;